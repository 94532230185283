import React from 'react';

const LessonCertificationTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="certification-tab-pane"
      role="tabpanel"
      aria-labelledby="certification-tab"
      tabIndex="0"
    >
      <div className="certification-content bg-white p-4 rounded-10 shadow-sm">
        <h3 className="mb-3">Certification</h3>
        <div className="row mb-3">
          <div className="col-md-4">
            <div className="h-100 d-flex flex-column justify-content-center body-bg font-secondary text-center px-3 py-4 rounded-10">
              <p className="fs-sm">Date of Exam</p>
              <h6 className="text-light mt-2 mb-0">25th August 2021</h6>
            </div>
          </div>
          <div className="col-md-4">
            <div className="h-100 d-flex flex-column justify-content-center body-bg font-secondary text-center px-3 py-4 rounded-10">
              <h6 className="text-light mt-2 mb-0">250 out of 300</h6>
            </div>
          </div>
          <div className="col-md-4">
            <div className="h-100 d-flex flex-column justify-content-center body-bg font-secondary text-center px-3 py-4 rounded-10">
              <p className="fs-sm">Rank</p>
              <h6 className="text-light mt-2 mb-0">25 out of 85</h6>
            </div>
          </div>
        </div>
        <div className="certificate">
          <img
            src="/assets/img/certificate.jpg"
            alt="certificate"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default LessonCertificationTab;
