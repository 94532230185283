import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const HomeBanner = () => {
  const { t } = useTranslation(); // for translations

  return (
    <section className="home-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <h1 className="text-white">
              {t('Connecting Minds, Driving the Future: University Ecosystem')}
            </h1>
            <Link to="#" className="btn btn-primary">
              {t('See more')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
