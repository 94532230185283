import Footer from 'Components/Common/Footer/Footer';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import HomeAlumni from 'Components/HomeComponents/HomeAlumni/HomeAlumni';
import { HomeBanner } from 'Components/HomeComponents/HomeBanner/HomeBanner';
import HomeChallenges from 'Components/HomeComponents/HomeChallenges/HomeChallenges';
import HomeCompanies from 'Components/HomeComponents/HomeCompanies/HomeCompanies';
import HomeCourses from 'Components/HomeComponents/HomeCourses/HomeCourses';
import HomeEcosystem from 'Components/HomeComponents/HomeEcosystem/HomeEcosystem';
import HomeEvents from 'Components/HomeComponents/HomeEvents/HomeEvents';
import HomeOffers from 'Components/HomeComponents/HomeOffers/HomeOffers';
import HomeServices from 'Components/HomeComponents/HomeServices/HomeServices';
import HomeSuccessStories from 'Components/HomeComponents/HomeSuccessStories/HomeSuccessStories';
import HomeTeachers from 'Components/HomeComponents/HomeTeachers/HomeTeachers';
import React from 'react';

const Home = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="home" />

      <div id="main_content" className="position-relative">
        {/* ----- banner section ------- */}
        <HomeBanner />

        {/* --- alumni section -----------*/}
        <HomeAlumni />

        {/* ------------- offer section ----------  */}
        <HomeOffers />

        {/* -------- teacher section ----------- */}
        <HomeTeachers />

        {/* ------------ challenges section ----------- */}
        <HomeChallenges />

        {/* -- courses section --- */}
        <HomeCourses />

        {/* --------- events section ---------- */}
        <HomeEvents />

        {/* -------- success stories section ------- */}
        <HomeSuccessStories />

        {/* --------- companies section ------- */}
        <HomeCompanies />

        {/* ---------- services section ------- */}
        <HomeServices />

        {/* ----------- ecosystem section ------- */}
        <HomeEcosystem />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default Home;
