/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import SuccessStoriesFilterPopup from 'Components/SuccessStoriesComponents/Popup/SuccessStoriesFilterPopup';
import Select from 'react-select';
import sorting from 'Data/Sorting.json';

const SuccessStoriesList = () => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');

  // const [isLoading, setIsLoading] = useState(false);
  const [storyList, setStoryList] = useState([]);

  //function for get all success stories
  const getAllSuccessStories = async () => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_SUCCESS_STORIES + `?token=${token}`;

      console.log('url of all success story ------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('success story list response------>', response);

      if (response.status) {
        // setIsLoading(false);
        setStoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    document.title = 'Success Stories'; // Update the title when Register component mounts
    getAllSuccessStories();
  }, []);
  return (
    <section className="success-stories-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="success-stories-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              {t('Found')} <span>{storyList.length}</span> {t('result')}
            </p>
            <div className="d-flex gap-3">
              <Select
                placeholder={t('Select Position')}
                options={sorting}
                // value={params}
                // onChange={val => {
                //   setParams(val);
                // }}
              />
              <Link
                href="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            {storyList.map((storyData, index) => {
              return (
                <div className="col-md-6 col-lg-4 mb-4" key={index}>
                  <div className="success-story-content bg-white p-3 rounded-10 shadow-sm">
                    <div className="img-container rounded-3 overflow-hidden mb-3">
                      <Link href="success-story.html">
                        <img
                          src={
                            storyData.companylogo === ''
                              ? assetImages.defaultUser
                              : url.SERVER_URL + storyData.companylogo
                          }
                          alt="companylogo"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="desc">
                      <p className="fs-xs fw-medium text-primary">
                        {storyData.companyname}
                      </p>
                      <h3>
                        <Link
                          href="success-story.html"
                          className="text-black"
                          tabIndex="0"
                        >
                          {storyData.projectname}
                        </Link>
                      </h3>
                      <p className="text-primary">
                        {storyData.summary.slice(0, 50)} ...
                      </p>
                      <Link
                        to={`/successstories/details/${storyData._id}`}
                        className="btn btn-secondary w-100 mt-3"
                        tabIndex="0"
                      >
                        {t('View Details')}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <ol className="pagination align-items-center gap-2 fw-semibold justify-content-center mt-4">
            <li className="prev me-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_back
                </span>
              </Link>
            </li>
            <li className="page active">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                1
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                2
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                3
              </Link>
            </li>
            <li className="page">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                ...
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="next ms-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_forward
                </span>
              </Link>
            </li>
          </ol> */}
        </div>
      </div>

      {/* ------- filter popup -------- */}
      <SuccessStoriesFilterPopup />
    </section>
  );
};

export default SuccessStoriesList;
