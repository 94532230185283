// /* eslint-disable */
import React from 'react';

import CommonHeader from 'Components/Common/Headers/CommonHeader';
import Footer from 'Components/Common/Footer/Footer';
import CoursesDetailsBodyComponent from 'Components/CoursesComponents/CoursesDetailsComponents/CoursesDetailsBodyComponent/CoursesDetailsBodyComponent';

const CoursesDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="courses" />

      <div id="main_content" className="position-relative">
        <CoursesDetailsBodyComponent />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default CoursesDetails;
