/* eslint-disable */
import React from 'react';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import AccountLists from 'Components/AccountComponents/AccountsLandingComponents/AccountLists/AccountLists';
import Footer from 'Components/Common/Footer/Footer';

const AccountsLanding = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="accounts" />

      <div id="main_content" className="position-relative">
        {/* ------- account lists section ------- */}
        <AccountLists />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default AccountsLanding;
