/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { assetImages } from 'constants';

const HomeSuccessStories = () => {
  const token = localStorage.getItem('token'); // for get user token
  const { t } = useTranslation(); // for translations

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [storyList, setStoryList] = useState([]); // for success story lists

  //function for get all success stories
  const getAllSuccessStories = async () => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_SUCCESS_STORIES + `?token=${token}`;

      console.log('url of all success story ------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('success story list response------>', response);

      if (response.status) {
        // setIsLoading(false);
        setStoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllSuccessStories();
  }, []);

  return (
    <section className="success-stories py-50 py-md-70 border-bottom border-light border-opacity-2">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Success Stories')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="/successstories" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive} infinite={true}>
          {storyList.map((storyData, index) => (
            <div key={index} className="success-story px-2">
              <div className="success-story-content p-3 bg-white rounded-10 shadow-sm">
                <div className="img-container rounded-3 overflow-hidden mb-3">
                  <Link to={`/successstories/details/${storyData._id}`}>
                    <img
                      src={
                        storyData.companylogo === ''
                          ? assetImages.defaultUser
                          : url.SERVER_URL + storyData.companylogo
                      }
                      alt="companylogo"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc">
                  <p className="fs-xs fw-medium text-primary">
                    {storyData.companyname}
                  </p>
                  <h3>
                    <Link
                      to={`/successstories/details/${storyData._id}`}
                      className="text-black"
                    >
                      {storyData.projectname}
                    </Link>
                  </h3>
                  <p className="text-primary">
                    {storyData.summary.slice(0, 50)} ...
                  </p>
                  <Link
                    to={`/successstories/details/${storyData._id}`}
                    className="btn btn-secondary w-100 mt-3"
                  >
                    {t('View Details')}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeSuccessStories;
