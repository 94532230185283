/* eslint-disable */
import React from 'react';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import EventsList from 'Components/EventsComponents/EventsLandingComponents/EventsList/EventsList';
import Footer from 'Components/Common/Footer/Footer';

const EventsLanding = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="events" />

      <div id="main_content" className="position-relative">
        {/* ----- events section ------- */}
        <EventsList />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default EventsLanding;
