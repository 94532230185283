import React from 'react';
// import { Link } from 'react-router-dom';
import * as url from 'helper/UrlHelper';

const SuccessStoriesDetailsBanner = ({
  storyName,
  // storyDate,
  companyName,
  companyImageUrl,
  successStoryDetails,
}) => {
  return (
    <section className="success-story-banner header-spacing bg-white py-50 shadow-sm">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 mb-4 mb-lg-0">
            <div className="banner-content">
              <img
                // src={companyImageUrl}
                src={url.SERVER_URL + companyImageUrl}
                alt={companyName}
                className="img-fluid mb-3"
              />
              <h1>{storyName}</h1>

              <div className="ods d-flex align-items-center mb-3">
                <p dangerouslySetInnerHTML={{ __html: successStoryDetails }} />
                {/* <p className="fs-xs fw-medium me-3">ODS</p>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src="/assets/img/component-1.png"
                    alt="Component 1"
                    className="img-fluid"
                  />
                  <img
                    src="/assets/img/component-2.png"
                    alt="Component 2"
                    className="img-fluid"
                  />
                  <img
                    src="/assets/img/component-3.png"
                    alt="Component 3"
                    className="img-fluid"
                  />
                  <img
                    src="/assets/img/component-4.png"
                    alt="Component 4"
                    className="img-fluid"
                  />
                </div> */}
              </div>
              {/* <div className="d-flex flex-wrap flex-lg-nowrap gap-3">
                <div className="col-12 col-lg-4 col-xl-3">
                  <div className="body-bg px-3 py-4 h-100 rounded-10">
                    <h3 className="text-primary">100%</h3>
                    <div
                      className="progress mb-3 border border-gray-500"
                      role="progressbar"
                      aria-label="Example 1px high"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '160px', height: '10px' }}
                    >
                      <div
                        className="progress-bar bg-light border-end border-white rounded-10"
                        style={{ width: '100%' }}
                      ></div>
                    </div>
                    <p className="fs-xs fw-medium text-primary">
                      ROAS (contextual targeting)
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-3">
                  <div className="body-bg px-3 py-4 h-100 rounded-10">
                    <h3 className="text-primary">-600M</h3>
                    <div
                      className="progress mb-3 bg-orange border border-gray-500"
                      role="progressbar"
                      aria-label="Example 1px high"
                      aria-valuenow="60"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '160px', height: '10px' }}
                    >
                      <div
                        className="progress-bar bg-light border-end border-white rounded-10"
                        style={{ width: '60%' }}
                      ></div>
                    </div>
                    <p className="fs-xs fw-medium text-primary">Impressions</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-3">
                  <div className="body-bg px-3 py-4 h-100 rounded-10">
                    <h3 className="text-primary">+25%</h3>
                    <div
                      className="progress mb-3 bg-success border border-gray-500"
                      role="progressbar"
                      aria-label="Example 1px high"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '160px', height: '10px' }}
                    >
                      <div
                        className="progress-bar bg-light border-end border-white rounded-10"
                        style={{ width: '75%' }}
                      ></div>
                    </div>
                    <p className="fs-xs fw-medium text-primary">
                      ROAS Increase (Video Campaign Impact on Lower Funnel
                      Campaign)
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="col-lg-3 d-flex align-items-center justify-content-lg-end">
            <ul className="social-links d-flex flex-lg-column gap-2">
              <li>
                <Link
                  to="#"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <svg width="25" height="24">
                    <use xlinkHref="/assets/svg/sprite.svg#facebook"></use>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <svg width="25" height="19">
                    <use xlinkHref="/assets/svg/sprite.svg#gmail"></use>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <svg width="19" height="18">
                    <use xlinkHref="/assets/svg/sprite.svg#linkedin"></use>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center text-black bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-md">
                    link
                  </span>
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesDetailsBanner;
