import React from 'react';

const ProductTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="products-tab-pane"
      role="tabpanel"
      aria-labelledby="products-tab"
      tabIndex="0"
    >
      <div className="products-container">
        <div className="product p-4 bg-white rounded-10 shadow-sm mb-3">
          <div className="row">
            <div className="col-md-3 product-img mb-3 mb-md-0">
              <img
                src="assets/img/product.png"
                alt="Polo Collar T-Shirt for Plus Size Men"
                className="img-fluid"
              />
            </div>
            <div className="col-md-9 desc">
              <h6 className="fw-medium mb-2">
                Polo Collar T-Shirt for Plus Size Men
              </h6>
              <p className="rating d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fw-bold">4.5</span>
                <span className="d-block">(32)</span>
              </p>
              <ul className="product-info d-flex flex-wrap fs-sm mb-3">
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Comentarios</span>
                  <span className="text-black">0</span>
                </li>
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">Automatico</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">258 CV</span>
                </li>
              </ul>
              <p className="price fw-semibold text-success">€135,990</p>
            </div>
          </div>
        </div>
        <div className="product p-4 bg-white rounded-10 shadow-sm mb-3">
          <div className="row">
            <div className="col-md-3 product-img mb-3 mb-md-0">
              <img
                src="assets/img/product.png"
                alt="Polo Collar T-Shirt for Plus Size Men"
                className="img-fluid"
              />
            </div>
            <div className="col-md-9 desc">
              <h6 className="fw-medium mb-2">
                Polo Collar T-Shirt for Plus Size Men
              </h6>
              <p className="rating d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fw-bold">4.5</span>
                <span className="d-block">(32)</span>
              </p>
              <ul className="product-info d-flex flex-wrap fs-sm mb-3">
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Comentarios</span>
                  <span className="text-black">0</span>
                </li>
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">Automatico</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">258 CV</span>
                </li>
              </ul>
              <p className="price fw-semibold text-success">€135,990</p>
            </div>
          </div>
        </div>
        <div className="product p-4 bg-white rounded-10 shadow-sm mb-3">
          <div className="row">
            <div className="col-md-3 product-img mb-3 mb-md-0">
              <img
                src="assets/img/product.png"
                alt="Polo Collar T-Shirt for Plus Size Men"
                className="img-fluid"
              />
            </div>
            <div className="col-md-9 desc">
              <h6 className="fw-medium mb-2">
                Polo Collar T-Shirt for Plus Size Men
              </h6>
              <p className="rating d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fw-bold">4.5</span>
                <span className="d-block">(32)</span>
              </p>
              <ul className="product-info d-flex flex-wrap fs-sm mb-3">
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Comentarios</span>
                  <span className="text-black">0</span>
                </li>
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">Automatico</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">258 CV</span>
                </li>
              </ul>
              <p className="price fw-semibold text-success">€135,990</p>
            </div>
          </div>
        </div>
        <div className="product p-4 bg-white rounded-10 shadow-sm mb-3">
          <div className="row">
            <div className="col-md-3 product-img mb-3 mb-md-0">
              <img
                src="assets/img/product.png"
                alt="Polo Collar T-Shirt for Plus Size Men"
                className="img-fluid"
              />
            </div>
            <div className="col-md-9 desc">
              <h6 className="fw-medium mb-2">
                Polo Collar T-Shirt for Plus Size Men
              </h6>
              <p className="rating d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fw-bold">4.5</span>
                <span className="d-block">(32)</span>
              </p>
              <ul className="product-info d-flex flex-wrap fs-sm mb-3">
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Comentarios</span>
                  <span className="text-black">0</span>
                </li>
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">Automatico</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">258 CV</span>
                </li>
              </ul>
              <p className="price fw-semibold text-success">€135,990</p>
            </div>
          </div>
        </div>
        <div className="product p-4 bg-white rounded-10 shadow-sm">
          <div className="row">
            <div className="col-md-3 product-img mb-3 mb-md-0">
              <img
                src="assets/img/product.png"
                alt="Polo Collar T-Shirt for Plus Size Men"
                className="img-fluid"
              />
            </div>
            <div className="col-md-9 desc">
              <h6 className="fw-medium mb-2">
                Polo Collar T-Shirt for Plus Size Men
              </h6>
              <p className="rating d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fw-bold">4.5</span>
                <span className="d-block">(32)</span>
              </p>
              <ul className="product-info d-flex flex-wrap fs-sm mb-3">
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Comentarios</span>
                  <span className="text-black">0</span>
                </li>
                <li>
                  <span className="text-gray">Rank</span>
                  <span className="text-black">1000</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">Automatico</span>
                </li>
                <li>
                  <span className="text-gray">Diesel</span>
                </li>
                <li>
                  <span className="text-gray">258 CV</span>
                </li>
              </ul>
              <p className="price fw-semibold text-success">€135,990</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTab;
