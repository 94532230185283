/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const HomeChallenges = () => {
  const token = localStorage.getItem('token'); //get token from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo')); // get user info from local storage
  const { t } = useTranslation(); // for translations

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [jobList, setJobList] = useState([]); // for job lists

  //function for get project list
  const getAllChallenges = async () => {
    // setIsLoading(true);
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_CHALLENGE}?token=${token}&userrolestring=${userInfo.role.name}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);
      // setIsLoading(false);

      console.log(`job list response------>`, response);

      if (response.status) {
        setJobList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllChallenges();
  }, []);

  return (
    <section className="best-challenges py-50 py-md-70">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Best Challenges')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="/challenges" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {jobList.map((job, index) => (
            <div
              className="challenge"
              key={index}
              style={{ padding: '0 10px' }}
            >
              <div className="challenge-content p-3 bg-primary text-white rounded-10">
                <div className="challenge-meta d-flex flex-wrap gap-2 align-items-center mb-3">
                  <img
                    src={
                      job.companylogopath === ''
                        ? assetImages.defaultUser
                        : url.SERVER_URL + job.companylogopath
                    }
                    alt={job.companynamestring}
                    className="img-fluid"
                  />
                  <div className="rating d-flex align-items-center gap-2 ms-auto">
                    <p className="d-flex gap-1 align-items-center px-3 py-2 bg-secondary rounded-90">
                      <span className="d-block material-symbols-outlined icon-fill icon-sm">
                        star
                      </span>
                      <span className="d-block fs-sm fw-bold">
                        {job.ratingtotal}
                      </span>
                      <span className="d-block fs-xs">{job.reviewtotal}</span>
                    </p>
                    <Link to="#" className="text-white">
                      <span className="d-block material-symbols-outlined">
                        bookmark
                      </span>
                    </Link>
                  </div>
                </div>
                <h3>
                  <Link
                    to={`/challenge/details/${job._id}`}
                    className="text-white"
                  >
                    {job.name}
                  </Link>
                </h3>
                <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-2">
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        event_note
                      </span>
                      <span className="d-block">{job.createddate}</span>
                    </Link>
                  </li>
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        schedule
                      </span>
                      <span className="d-block">{job.jobtype}</span>
                    </Link>
                  </li>
                </ul>
                <p className="info mb-5">{job.summary.slice(0, 100)}</p>
                <p className="price fs-lg fw-semibold">
                  €{job.minsalary} - €{job.maxsalary}
                </p>
                <Link
                  to={`/challenge/details/${job._id}`}
                  className="btn btn-secondary w-100 mt-2"
                >
                  {t('View Details')}
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeChallenges;
