/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';
import { GlobalProvider } from 'context/GlobalContext';

import { Link } from 'react-router-dom';
import Select from 'react-select';

import sorting from 'Data/Sorting.json';

import { useTranslation } from 'react-i18next';
import AccountsFilterPopup from 'Components/AccountComponents/Popups/AccountsFilterPopup';
import { assetImages } from 'constants';

const AccountLists = () => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token'); //get token from local storage
  const { ecosystemList } = useContext(GlobalProvider); // get ecosystem list from global context

  const [appUserList, setAppUserList] = useState([]); // for user lists
  const [skillList, setSkillList] = useState([]); // for skill lists
  const [serviceList, setServiceList] = useState([]); // for service lists
  const [tagList, setTagList] = useState([]); // for tag lists
  const [params, setParams] = useState(null); // for url params
  const [hideNextPageBtn, setHideNextPageBtn] = useState(false);

  // -------- filter requirements starts -------
  const [filterName, setfilterName] = useState('');
  const [filterCompany, setfilterCompany] = useState('');
  const [filterPosition, setFilterPosition] = useState('');
  const [filterRating, setFilterRating] = useState('');
  const [filterServices, setFilterServices] = useState([]);
  const [filterSkills, setfilterSkills] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterEcosystem, setFilterEcosystem] = useState(null);

  // -------- filter requirements ends -------

  // const [isLoading, setIsLoading] = useState(false);

  // function for get all services
  const getAllServices = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=services`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setServiceList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all skills
  const getAllSkills = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setSkillList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllAppUsers = async (start, limit, sortingBy) => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_APP_USERS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterName !== '') {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterCompany !== '') {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterPosition !== '') {
        requestUrl = requestUrl + `&filterposition=${filterPosition}`;
      }

      if (filterRating !== '') {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }

      if (filterServices.length > 0) {
        requestUrl = requestUrl + `&filterservices=${filterServices}`;
      }

      if (filterSkills.length > 0) {
        requestUrl = requestUrl + `&filterskills=${filterSkills}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterTags}`;
      }

      if (filterEcosystem) {
        requestUrl = requestUrl + `&filterecosystemid=${filterEcosystem}`;
      }

      console.log('url of app user list------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in app user list------>', response);

      if (response.status) {
        if (start == '0') {
          setAppUserList(response.data);
        } else {
          setAppUserList([...appUserList, ...response.data]);
        }

        if (limit > response.data.length) {
          setHideNextPageBtn(false);
        } else {
          setHideNextPageBtn(true);
        }

        resetFilterData();
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  //reset filter
  const resetFilterData = () => {
    setfilterName('');
    setfilterCompany('');
    setFilterPosition('');
    setFilterRating('');
    setFilterServices([]);
    setfilterSkills([]);
    setFilterTags([]);
    setFilterEcosystem(null);
  };
  const resetList = () => {
    getAllAppUsers('0', '8', null);
  };

  //use effect for filter search
  useEffect(() => {
    if (
      filterName !== '' ||
      filterCompany !== '' ||
      filterPosition !== '' ||
      filterRating !== '' ||
      filterSkills.length > 0 ||
      filterServices.length > 0 ||
      filterTags.length > 0 ||
      filterEcosystem
    ) {
      getAllAppUsers('0', '8', null);
    }
  }, [
    filterName,
    filterCompany,
    filterPosition,
    filterRating,
    filterSkills,
    filterServices,
    filterTags,
    filterEcosystem,
  ]);

  // console.log(`params?.value`, params?.value);

  //use effect for params
  useEffect(() => {
    if (params?.value) {
      getAllAppUsers('0', '8', params?.value);
    }
  }, [params]);

  // ---- use effect for get all contacts
  useEffect(() => {
    getAllAppUsers('0', '8', null);
    getAllSkills();
    getAllServices();
    getAllTags();
  }, []);

  // ----- scroll to top -------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="accounts-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="accounts-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              {t('Found')} <span>{appUserList.length}</span> {t('result')}
            </p>
            <div className="d-flex gap-3">
              <Select
                placeholder={t('Select Position')}
                options={sorting}
                value={params}
                onChange={val => {
                  setParams(val);
                }}
              />
              <Link
                to="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            {appUserList?.map((user, index) => {
              return (
                <div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={index}>
                  <div className="account-content bg-white p-4 text-center rounded-10 shadow-sm">
                    <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                      <Link to={`/accounts/details/${user._id}`}>
                        <img
                          src={
                            user.userimage === ''
                              ? assetImages.defaultUser
                              : url.SERVER_URL + user.userimage
                          }
                          alt="Harry Nonalarm"
                          className="w-100 h-100 object-cover object-center"
                        />
                      </Link>
                    </div>
                    <div className="profile-info">
                      <h6>
                        <Link to="/accounts/details" className="text-black">
                          {user.username}
                        </Link>
                      </h6>
                      <p className="designation text-gray mb-2">
                        {user.position}
                      </p>
                      <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                        <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                          star
                        </span>
                        <span className="d-block"> {user.totalratings}</span>
                        <span className="d-block">({user.totalreviews})</span>
                      </p>
                      <div className="action d-flex gap-2">
                        {/* <Link
                          to="#"
                          className="btn btn-outline-light btn-sm btn-icon"
                        >
                          <span className="d-block material-symbols-outlined icon-md">
                            rate_review
                          </span>
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-outline-light btn-sm btn-icon"
                        >
                          <span className="d-block material-symbols-outlined icon-md">
                            event_note
                          </span>
                        </Link> */}
                        <Link
                          to={`/accounts/details/${user._id}`}
                          className="btn btn-outline-secondary btn-sm flex-fill"
                        >
                          {t('View Details')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {hideNextPageBtn && (
            <div
              className="action"
              onClick={() => {
                getAllAppUsers(
                  appUserList.length.toString(),
                  '4',
                  params?.value ? params?.value : null
                );
              }}
            >
              <button type="submit" className="btn btn-primary rounded-5">
                {t('Next Page')}
              </button>
            </div>
          )}
        </div>
      </div>

      {/* --------- filter popup -------- */}
      <AccountsFilterPopup
        skillList={skillList}
        serviceList={serviceList}
        tagList={tagList}
        ecosystemList={ecosystemList}
        setfilterName={setfilterName}
        setfilterCompany={setfilterCompany}
        setFilterPosition={setFilterPosition}
        setFilterRating={setFilterRating}
        setfilterSkills={setfilterSkills}
        setFilterServices={setFilterServices}
        setFilterTags={setFilterTags}
        setFilterEcosystem={setFilterEcosystem}
        resetList={resetList}
      />
    </section>
  );
};

export default AccountLists;
