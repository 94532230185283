import { useState, createContext, useEffect } from 'react';
/*import url and gateway methods */
import { getData } from '../utils/Gateway';
import * as url from '../helper/UrlHelper';

export const GlobalProvider = createContext();

const GlobalContext = props => {
  const token = localStorage.getItem('token'); //get token from local storage

  const [example, setExample] = useState(false);
  /*======= login requirement start =======*/
  // const [createAccountMessage, setcreateAccountMessage] = useState('');
  // const [userEmailforOtp, setuserEmailforOtp] = useState('');
  /*======= login requirement end =======*/

  //filter requirements
  const [ecosystemList, setEcosystemList] = useState([]);

  /*======= login requirement start =======*/
  // const [verifyModuleName, setVerifyModuleName] = useState('');
  const [createAccountMessage, setcreateAccountMessage] = useState('');
  // const [userEmailforOtp, setuserEmailforOtp] = useState('');
  /*======= login requirement end =======*/

  //function for get all modules
  const getAllEcosystems = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ECOSYSTEM + `?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setEcosystemList(
          response.data.map(item => ({
            label: item.name,
            value: item._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllEcosystems();
  });

  return (
    <GlobalProvider.Provider
      value={{
        example,
        setExample,
        // createAccountMessage,
        // setcreateAccountMessage,
        // userEmailforOtp,
        // setuserEmailforOtp,
        ecosystemList,
        setEcosystemList,

        /*======= login requirement start =======*/
        // verifyModuleName,
        // setVerifyModuleName,
        createAccountMessage,
        setcreateAccountMessage,
        // userEmailforOtp,
        // setuserEmailforOtp,
        /*======= login requirement end =======*/
      }}
    >
      {props.children}
    </GlobalProvider.Provider>
  );
};

export default GlobalContext;
