// /* eslint-disable */
import React from 'react';

import CommonHeader from 'Components/Common/Headers/CommonHeader';
import CourseList from 'Components/CoursesComponents/CoursesLandingComponents/CourseList/CourseList';
import Footer from 'Components/Common/Footer/Footer';
import CoursesFilterPopup from 'Components/CoursesComponents/Popups/CoursesFilterPopup';

const Courses = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="courses" />

      <div id="main_content" className="position-relative">
        {/* ----- course lists section ------- */}
        <CourseList />
      </div>

      {/* --------- filter popup ------------- */}
      <CoursesFilterPopup />

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default Courses;
