/* eslint-disable */
import React, { useEffect, useState } from 'react';
/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { deleteData, getData, putData } from 'utils/Gateway';
import { useParams } from 'react-router-dom';

import AccountDetailsLeftComponent from '../AccountDetailsLeftComponent/AccountDetailsLeftComponent';
import AccountDetailsRightComponent from '../AccountDetailsRightComponent/AccountDetailsRightComponent';
import ReviewModal from 'Components/Common/Modals/ReviewModal';

import SuccessModal from 'Components/Common/Modals/SuccessModal';
import TagsModal from 'Components/Common/Modals/TagsModal';
import ConnectModal from 'Components/AccountComponents/Modals/ConnectModal';

const AccountDetailsBodyComponent = () => {
  const params = useParams();

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');

  /* use states for top section */
  // const [isLoading, setIsLoading] = useState(false);
  // const [userAccountID, setUserAccountId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userImage, setUserImage] = useState('');
  const [userPosition, setuserPosition] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [userRate, setExpertRate] = useState('');
  const [userTags, setExpertTags] = useState([]);
  const [isProfileOwner, setIsProfileOwner] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [requestDate, setRequestDate] = useState('');
  const [connectStatus, setConnectStatus] = useState(false);
  const [connectedDate, setConnectedDate] = useState('');
  const [unfriendStatus, setunfriendStatus] = useState('0');
  const [resumeContactOwner, setresumeContactOwner] = useState('0');
  const [resumeContactMember, setresumeContactMember] = useState('0');
  // const [expertNotExist, setExpertNotExist] = useState(false);
  const [expertRating, setExpertRating] = useState('');
  const [totalNoReviews, setTotalNoReviews] = useState('');
  const [expertDetails, setExpertDetails] = useState('');
  const [expertSkills, setExpertSkills] = useState([]);
  const [expertEmail, setExpertEmail] = useState('');
  const [expertLanguage, setExpertLanguage] = useState('');
  const [expertEducations, setexpertEducations] = useState([]);
  const [expertExperiences, setExpertExperiences] = useState([]);

  const [ratingCount, setRatingCount] = useState('0.0');
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  // const [isReviewLoading, setIsReviewLoading] = useState(false);
  // const [similarProfileList, setSimilarProfileList] = useState([]);

  //function for get user details
  const getUserDetails = async () => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // setIsLoading(false);

      console.log('Users details resp======>', response);

      if (response.status && response.data) {
        // setExpertNotExist(false);
        const data = response.data;
        // setUserAccountId(data._id);
        setUserImage(data.userimage); // Ensure default value if undefined
        setUserName(data.name ? data.name + ' ' + data.surname : data.email);
        setuserPosition(data.position || '');
        setExpertRate(data.costperhour || '');
        setStateName(data.state || '');
        setCityName(data.city || '');
        setCountryName(data.country || '');
        setExpertRating(data.totalratings);
        setTotalNoReviews(data.totalreviews);
        setExpertTags(data.tags);
        setexpertEducations(data?.educationids ? data?.educationids : []);
        setExpertExperiences(data?.experienceids ? data?.experienceids : []);

        if (data._id === userInfo._id) {
          setIsProfileOwner(true);
        } else {
          setIsProfileOwner(false);
        }

        if (data.isconnect) {
          setIsConnect(true);
          setRequestDate(data.requestdate);
        } else {
          setIsConnect(false);
          setRequestDate('');
        }

        if (data.connectstatus) {
          setConnectStatus(true);
          setConnectedDate(data.connectdate);
        } else {
          setConnectStatus(false);
          setConnectedDate('');
        }

        if (data.unfriendstatus) {
          setunfriendStatus(data.unfriendstatus);
        }

        if (data.resumecontactowner) {
          setresumeContactOwner(data.resumecontactowner);
        }

        if (data.resumecontactmember) {
          setresumeContactMember(data.resumecontactmember);
        }

        setExpertDetails(data.about);
        setExpertSkills(data.skills || []); // Ensure default value if undefined
        setExpertEmail(data.email || '');
        setExpertLanguage(data.language || '');
      }
      // else {
      //   setExpertNotExist(true);
      // }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    // setIsReviewLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=user&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      // console.log("review list response", response);

      // setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount('');
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for cancel request
  const cancelRequestHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_REJECT_CONTACT_REQUEST +
        `?token=${token}&personid=${params.id}`;

      const response = await deleteData(requestURL);

      console.log(response);

      if (response.status) {
        afterApiCall();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for unfriend contact
  const unfriendHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_UNFRIEND_CONTACT +
        `?token=${token}&personid=${params.id}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        afterApiCall();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for resume contact
  const resumeContactHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_RESUME_CONTACT +
        `?token=${token}&personid=${params.id}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        afterApiCall();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get similar profiles
  // const getSimilarProfiles = async () => {
  //   try {
  //     let requestURL =
  //       url.API_BASE_URL + url.API_GET_APP_USERS + `?token=${token}`;

  //     const response = await getData(requestURL);

  //     if (response.status) {
  //       const filteredResults = response.data.filter(
  //         user => user._id.toString() !== params.id.toString()
  //       );

  //       setSimilarProfileList(filteredResults.slice(0, 5));
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  //fucntion for get api
  const afterApiCall = () => {
    getUserDetails();
    getReviewsLists();
    // getSimilarProfiles();
  };

  useEffect(() => {
    if (params.id) {
      getUserDetails();
      getReviewsLists();
      // getSimilarProfiles();
    }
  }, [params.id]);

  return (
    <>
    <div id="main_content" className="position-relative">
      <section className="account-details-wrapper header-spacing py-5">
        <div className="container">
          <div className="row">
            <AccountDetailsLeftComponent
              userName={userName}
              userPosition={userPosition}
              userImage={userImage}
              userRating={expertRating}
              totalNoReviews={totalNoReviews}
              userCity={cityName}
              userState={stateName}
              userCountry={countryName}
              userRate={userRate}
              userTags={userTags}
              isConnect={isConnect}
              connectStatus={connectStatus}
              connectedDate={connectedDate}
              requestDate={requestDate}
              unfriendStatus={unfriendStatus}
              resumeContactOwner={resumeContactOwner}
              resumeContactMember={resumeContactMember}
              isProfileOwner={isProfileOwner}
              cancelRequestHandler={cancelRequestHandler}
              unfriendHandler={unfriendHandler}
              resumeContactHandler={resumeContactHandler}
            />

            <AccountDetailsRightComponent
              expertDetails={expertDetails}
              expertSkills={expertSkills}
              expertEmail={expertEmail}
              expertLanguage={expertLanguage}
              expertExperiences={expertExperiences}
              expertEducations={expertEducations}
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>
        </div>

        
      </section>
    </div>

    {/* --------------- feedback modal ----------- */}
    <ReviewModal moduleName="appuser" />

        

    {/*  ------------- greetings modal ----------- */}
    <SuccessModal afterModalClose={afterApiCall} />

    {/* --------- tags modal ----------- */}
    {/* <TagsModal moduleName="appuser" selectedId={params.id} /> */}

    {/* ------------- connect modal ----------- */}
    <ConnectModal />
    
    </>
  );
};

export default AccountDetailsBodyComponent;
