import React from 'react';
import { Link } from 'react-router-dom';
import AboutTab from '../Tabs/AboutTab/AboutTab';
import GoalsTab from '../Tabs/GoalsTab/GoalsTab';
import ForumTab from '../Tabs/ForumTab/ForumTab';
import InvestmentsTab from '../Tabs/InvestmentsTab/InvestmentsTab';
import KPIsTab from '../Tabs/KPIsTab/KPIsTab';
import SuccessStoriesTab from '../Tabs/SuccessStoriesTab/SuccessStoriesTab';

const ProjectDetailsComponent = () => {
  return (
    <section className="project-details-wrapper header-spacing py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <aside className="project-sidebar">
              <div className="project-info bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <img
                    src="assets/img/project-1.jpg"
                    alt="The pro grade devices"
                    className="img-fluid"
                  />
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="#" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link to="#" className="btn btn-secondary w-100">
                    View Details
                  </Link>
                </div>
              </div>
            </aside>
          </div>
          <div className="col-lg-8">
            <div className="project-details-container">
              <ul
                className="nav nav-tabs gap-2 border-0 mb-3"
                id="projectTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90 active"
                    id="about-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#about-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="about-tab-pane"
                    aria-selected="true"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="goals-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#goals-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="goals-tab-pane"
                    aria-selected="false"
                  >
                    Goals
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="forum-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#forum-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="forum-tab-pane"
                    aria-selected="false"
                  >
                    Forum
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="investments-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#investments-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="investments-tab-pane"
                    aria-selected="false"
                  >
                    Investments
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="kpis-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#kpis-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="kpis-tab-pane"
                    aria-selected="false"
                  >
                    KPIs
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="success-stories-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#success-stories-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="success-stories-tab-pane"
                    aria-selected="false"
                  >
                    Success Stories
                  </Link>
                </li>
              </ul>

              {/* ------- tab content ------- */}
              <div className="tab-content" id="projectTabContent">
                {/* ------- About tab content ------- */}
                <AboutTab />

                {/* ------- Goals tab content ------- */}
                <GoalsTab />

                {/* ------- Forum tab content ------- */}
                <ForumTab />

                {/* ------- Investments tab content ------- */}
                <InvestmentsTab />

                {/* ------- KPIs tab content ------- */}
                <KPIsTab />

                {/* ------- Success Stories tab content ------- */}
                <SuccessStoriesTab />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetailsComponent;
