import React from 'react';
import * as url from 'helper/UrlHelper';
import { assetImages } from 'constants';

const CourseDetailsTopComponent = ({ courseDetails = {} }) => {
  return (
    <div className="course-details-content bg-white py-5">
      <div className="container">
        <div className="d-flex align-items-center gap-3 fs-sm fw-semibold">
          {/* <p>{courseDetails?.moderatorname}</p> */}
          {/* <div className="share-links d-flex align-items-center ms-auto">
              <p className="me-3">Offered By Amazon</p>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center text-primary body-bg rounded-circle me-2"
              >
                <span className="d-block material-symbols-outlined">more</span>
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center text-primary body-bg rounded-circle"
              >
                <span className="d-block material-symbols-outlined icon-fill">
                  share
                </span>
              </Link>
            </div> */}
        </div>
        <h1 className="h2 mb-3">{courseDetails?.title}</h1>
        <ul className="course-meta d-flex align-items-center font-secondary fs-sm fw-semibold mb-4">
          <li>
            <p className="rating d-flex align-items-center">
              <span className="d-block material-symbols-outlined icon-md icon-fill rating-star-gradient me-1">
                star
              </span>
              <span className="d-block me-2">
                {courseDetails?.totalratings} ({courseDetails?.totalreviews})
              </span>
              <span className="d-block">
                {courseDetails?.lessoncount} lessons
              </span>
            </p>
          </li>
          {/* <li className="d-flex">
              <span className="d-block me-2">Course Duration</span>
              <span className="d-block">3 Months</span>
            </li> */}
        </ul>
        {courseDetails?.tags && (
          <ul className="tags d-flex flex-wrap gap-2 text-primary mb-3">
            {courseDetails?.tags?.map((tag, index) => {
              return (
                <li className="body-bg px-2 py-1 rounded-5" key={index}>
                  {tag.title}
                </li>
              );
            })}
          </ul>
        )}

        <div className="instructor d-flex align-items-center gap-2 mb-4 mb-md-5">
          <span className="fw-medium fs-xs text-primary">Instructor</span>
          <div className="avatar">
            <img
              src={
                courseDetails?.moderatorimage &&
                courseDetails?.moderatorimage !== ''
                  ? url.SERVER_URL + courseDetails?.moderatorimage
                  : assetImages.defaultUser
              }
              alt="Pierre Gagnaire"
              className="d-block w-100 h-100 object-cover object-center"
            />
          </div>
          <span className="text-light">{courseDetails?.moderatorname}</span>
        </div>
        <p className="mb-3">{courseDetails?.categorynames}</p>
        {/* <div className="profile-progress">
            <div
              className="progress mb-2"
              role="progressbar"
              aria-label="Example 1px high"
              aria-valuenow="32"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '130px', height: '8px' }}
            >
              <div className="progress-bar" style={{ width: '32%' }}></div>
            </div>
            <p className="fs-xs fw-medium">32% Completed</p>
          </div> */}
      </div>
    </div>
  );
};

export default CourseDetailsTopComponent;
