import React from 'react';
import { Link } from 'react-router-dom';
import * as url from '../../../../helper/UrlHelper';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const ChallengesDetailsLeftComponent = ({
  title,
  companyName,
  companyLogoPath,
  jobType,
  minSalary,
  maxSalary,
  cityName,
  country,
  tagList,
  isApplied,
  createdDate,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div className="col-lg-4">
      <aside className="challenge-sidebar">
        <div className="challenge-content p-3 bg-primary text-white rounded-10">
          <div className="challenge-meta d-flex flex-wrap gap-2 align-items-center mb-3">
            {/* ------- Company Logo ------- */}
            <img
              src={
                companyLogoPath === ''
                  ? assetImages.defaultUser
                  : url.SERVER_URL + companyLogoPath
              }
              alt={companyName}
              className="img-fluid"
            />
            {/* <div className="rating d-flex align-items-center gap-2 ms-auto">
              <p className="d-flex gap-1 align-items-center px-3 py-2 bg-secondary rounded-90">
                <span className="d-block material-symbols-outlined icon-fill icon-sm">
                  star
                </span>
                <span className="d-block fs-sm fw-bold">4.5</span>
                <span className="d-block fs-xs">(32)</span>
              </p>
              <Link to="#" className="text-white">
                <span className="d-block material-symbols-outlined">
                  bookmark
                </span>
              </Link>
            </div> */}
          </div>

          {/* ------- Title ------- */}
          <h3>
            <Link href="challenge-details.html" className="text-white">
              {title}
            </Link>
          </h3>

          <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-2">
            {/* ----- created Date ----- */}
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                tabIndex="0"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  event_note
                </span>
                <span className="d-block">{createdDate}</span>
              </Link>
            </li>

            {/* ------------ job Type ------------ */}
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                tabIndex="0"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  schedule
                </span>
                <span className="d-block">{jobType}</span>
              </Link>
            </li>
          </ul>
          {/* <p className="info mb-5">
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p> */}

          {/* ------------ tags ------------ */}
          <ul className="tags d-flex flex-wrap gap-2 text-primary mb-3">
            {tagList.map((tag, index) => {
              return (
                <li className="body-bg px-2 py-1 rounded-5" key={index}>
                  {tag}
                </li>
              );
            })}
          </ul>

          {/* ----------- Salary ----------- */}
          <p className="price fs-lg fw-semibold">
            €{minSalary} — €{maxSalary}
          </p>
          <Link to="#" className="btn btn-secondary w-100 mt-2">
            {t('Apply Now')}
          </Link>
        </div>
      </aside>
    </div>
  );
};

export default ChallengesDetailsLeftComponent;
