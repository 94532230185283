/* eslint-disable */
import React, { useState } from 'react';

/*------ import url and method ------*/
import { putData } from '../../../../../../utils/Gateway';
import * as url from '../../../../../../helper/UrlHelper';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { fileDownloadHandler } from 'utils/DownloadFile';

const LessonContentsTab = ({
  lessonDetails = {},
  nextLessonHandler,
  userCourseRole = '',
}) => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');

  const [lessonCompleted, setlessonCompleted] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);

  const changeLessonHandler = async () => {
    if (lessonCompleted) {
      try {
        setisUpdating(true);

        let requestURL =
          url.API_BASE_URL +
          url.API_COMPLETE_COURSE_LESSON +
          `/${lessonDetails?._id}?token=${token}`;

        const response = await putData(requestURL);

        console.log(response);

        setisUpdating(false);
        if (response.status) {
          setlessonCompleted(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    nextLessonHandler();
  };

  return (
    <div
      className="tab-pane fade show active"
      id="content-tab-pane"
      role="tabpanel"
      aria-labelledby="content-tab"
      tabIndex="0"
    >
      <div className="lesson-content bg-white p-4 rounded-10 shadow-sm">
        {/* ---- lesson title ---- */}
        <h3 className="mb-3">{lessonDetails.title}</h3>
        <div className="d-flex align-items-center gap-3 fs-sm fw-semibold mb-3">
          {/* <div className="profile d-flex align-items-center gap-2">
            <div
              className="avatar rounded-circle overflow-hidden"
              style={{ width: '30px', height: '30px' }}
            >
              <img
                src="/assets/img/instructor.png"
                alt="Harry McLaren"
                className="w-100 h-100 object-cover object-center"
              />
            </div>
            <p className="name font-secondary">Harry McLaren</p>
          </div> */}

          {/* ---- lesson duration ---- */}
          <p className="duration text-light">{lessonDetails.duration} min</p>
        </div>

        {/* <!-- ========== lesson uploads/videos Section ========== --> */}

        {lessonDetails?.uploads
          ? lessonDetails?.uploads
              ?.sort((a, b) => a.rank - b.rank)
              .map((upload, index) => {
                if (
                  upload.uploadid &&
                  (upload.uploadid?.name?.split('.').pop() === 'mp4' ||
                    upload.uploadid?.name?.split('.').pop() === 'mov')
                ) {
                  return (
                    <div
                      className="video-container position-relative ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-5 overflow-hidden shadow-sm mb-3"
                      key={index}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src={url.SERVER_URL + upload?.uploadid?.path}
                        title="YouTube video player"
                        border="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  );
                } else {
                  return upload.uploadid ? (
                    <div
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3 mb-3"
                      key={index}
                    >
                      <p className="fw-medium">{upload?.uploadid?.name}</p>
                      <Link
                        to="#"
                        onClick={() => fileDownloadHandler(upload?.uploadid)}
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          download
                        </span>
                      </Link>
                    </div>
                  ) : null;
                }
              })
          : null}

        {/* {lessonDetails?.uploads &&
          lessonDetails?.uploads.map((upload, index) => {
            return (
              <div
                className="video-container position-relative ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-5 overflow-hidden shadow-sm mb-3"
                key={index}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={url.SERVER_URL + upload?.uploadid?.path}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            );
          })} */}

        {/* ---- lesson description ---- */}
        <h3>{t('About')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: lessonDetails?.description,
          }}
        />
        {/* <p>
          I love coding and problem solving. I have 3+ years of experience in
          the web development sector. Love working on both server and client
          side code. I like to get to know my clients closely to facilitate
          better communication. Superb developer, he goes over and above in most
          cases to make sure the job is done and done well. He has the right
          skill set for a developer and more importantly he aligns himself with
          the vision of the product and provides great insights on how to build
          a product the right way. Will look forward to work with him on more
          projects.
        </p>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p>
        <h3>Heading Number 02</h3>
        <p>
          I love coding and problem solving. I have 3+ years of experience in
          the web development sector. Love working on both server and client
          side code. I like to get to know my clients closely to facilitate
          better communication. Superb developer, he goes over and above in most
          cases to make sure the job is done and done well. He has the right
          skill set for a developer and more importantly he aligns himself with
          the vision of the product and provides great insights on how to build
          a product the right way. Will look forward to work with him on more
          projects.
        </p>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p> */}

        {/* <!-- ==========  checkbox complete Section ========== --> */}
        {/* {userCourseRole !== '' ? null : (
          <div className="trms_check lessons_check">
            <label className="radio-check">
              <input
                type="checkbox"
                checked={lessonCompleted}
                onChange={e => {
                  setlessonCompleted(e.target.checked);
                }}
              />
              <span></span>
              <p>I have completed this leasson</p>
            </label>
          </div>
        )} */}

        {/* ---- go to next lesson button ---- */}
        <button
          to="#"
          onClick={changeLessonHandler}
          className="btn btn-secondary mt-3"
          disabled={isUpdating ? true : false}
          style={{
            cursor: isUpdating ? 'not-allowed' : 'pointer',
          }}
        >
          {t('Go to Next Lesson')}
          {isUpdating && (
            <div
              className="mx-2 spinner-border spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default LessonContentsTab;
