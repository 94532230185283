/* eslint-disable */

import React from 'react';

import CommonHeader from 'Components/Common/Headers/CommonHeader';
import Footer from 'Components/Common/Footer/Footer';

import SuccessStoriesDetailsBody from 'Components/SuccessStoriesComponents/SuccessStoriesDetailsComponents/SuccessStoriesDetailsBody/SuccessStoriesDetailsBody';

const SuccessStoriesDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="success" />

      {/* ---------- body ------------- */}
      <SuccessStoriesDetailsBody />

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default SuccessStoriesDetails;
