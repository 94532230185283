import React from 'react';
import { Link } from 'react-router-dom';

const SuccessStoriesTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="success-stories-tab-pane"
      role="tabpanel"
      aria-labelledby="success-stories-tab"
      tabIndex="0"
    >
      <div className="success-stories">
        <div className="success-story bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="row">
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/project-success-story.jpg"
                  alt="4D Air Traffic Management (ATM) System"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc">
                <p className="fs-sm fw-semibold text-primary mb-2">Corporate</p>
                <h3>4D Air Traffic Management (ATM) System</h3>
                <p className="date fs-xs fw-medium text-light">18th Jan 2022</p>
                <p>
                  Indra and Microsoft take iTEC to the cloud and open a new era
                  in world air traffic management. ...
                </p>
                <Link to="#" className="btn btn-info btn-sm rounded-10 mt-3">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="success-story bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="row">
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/project-success-story.jpg"
                  alt="4D Air Traffic Management (ATM) System"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc">
                <p className="fs-sm fw-semibold text-primary mb-2">Corporate</p>
                <h3>4D Air Traffic Management (ATM) System</h3>
                <p className="date fs-xs fw-medium text-light">18th Jan 2022</p>
                <p>
                  Indra and Microsoft take iTEC to the cloud and open a new era
                  in world air traffic management. ...
                </p>
                <Link to="#" className="btn btn-info btn-sm rounded-10 mt-3">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="success-story bg-white p-4 rounded-10 shadow-sm">
          <div className="row">
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/project-success-story.jpg"
                  alt="4D Air Traffic Management (ATM) System"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc">
                <p className="fs-sm fw-semibold text-primary mb-2">Corporate</p>
                <h3>4D Air Traffic Management (ATM) System</h3>
                <p className="date fs-xs fw-medium text-light">18th Jan 2022</p>
                <p>
                  Indra and Microsoft take iTEC to the cloud and open a new era
                  in world air traffic management. ...
                </p>
                <Link to="#" className="btn btn-info btn-sm rounded-10 mt-3">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesTab;
