import React from 'react';
import { useTranslation } from 'react-i18next';

const SuccessModal = ({ afterModalClose, successText }) => {
  const { t } = useTranslation(); // for translations
  return (
    <div className="modal fade" id="gretings_mdl">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-4 text-center">
            <div className="gretings_icon mb-2">
              <span className="material-symbols-outlined text-success fs-xxl">
                task_alt
              </span>
            </div>
            <div className="gretings_hdng mb-4">
              <h3>{successText}</h3>
            </div>
            <div className="post_btn post_bnts_outer">
              <button
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={afterModalClose}
              >
                {t('Close Now')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
