/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';
import { useTranslation } from 'react-i18next';

const ChallengeLandingRightPanel = ({ selectedJobId }) => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');
  // const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  // const [isLoading, setisLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [code, setcode] = useState('');
  const [summary, setsummary] = useState('');
  const [details, setdetails] = useState('');
  const [aboutCompany, setaboutCompany] = useState('');
  const [aboutPosition, setaboutPosition] = useState('');
  const [requiredSkills, setrequiredSkills] = useState([]);
  const [optionalSkills, setoptionalSkills] = useState([]);
  // const [remoteJobValue, setremoteJobValue] = useState(null);
  const [jobTypeValue, setjobTypeValue] = useState(null);
  const [createdDate, setCreatedDate] = useState('');
  // const [experienceAsValue, setexperienceAsValue] = useState(null);
  // const [experienceYear, setexperienceYear] = useState('');
  // const [experienceMonth, setexperienceMonth] = useState('');
  // const [currencyValue, setcurrencyValue] = useState(null);
  // const [paymentDurationValue, setpaymentDurationValue] = useState(null);
  const [minSalary, setminSalary] = useState('');
  const [maxSalary, setmaxSalary] = useState('');
  // const [companyValue, setcompanyValue] = useState(null);
  // const [companyName, setcompanyName] = useState('');
  // const [companyAdress, setcompanyAdress] = useState('');
  const [stateName, setstateName] = useState('');
  const [cityName, setcityName] = useState('');
  const [country, setcountry] = useState('');
  // const [zipcode, setzipcode] = useState('');
  // const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  // const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  // const [isApplied, setIsApplied] = useState(false);

  //function for get project details
  const getJobDetails = async () => {
    // setisLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${selectedJobId}?token=${token}`;

      const response = await getData(requestUrl);
      // setisLoading(false);
      console.log('getJobDetails =======================>>>>', response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
        setcode(data.jobcode);
        setsummary(data.summary);
        setdetails(data.description);
        setaboutCompany(data.aboutcompany);
        setaboutPosition(data.aboutposition);
        setrequiredSkills(data.requiredskillsdata);
        setoptionalSkills(data.optionalskillsdata);
        // setexperienceYear(data.experienceyear);
        // setexperienceMonth(data.experienceMonth);
        setCreatedDate(data.createddate);
        setminSalary(data.minsalary);
        setmaxSalary(data.maxsalary);
        setjobTypeValue(data.jobtype);
        // setcompanyName(data.companystring);
        // setcompanyAdress(data.location);
        // setzipcode(data.zipcode);
        setcityName(data.city);
        setstateName(data.state);
        setcountry(data.country);

        //assign uploaded files
        // setUploadedDocFiles(response?.data?.uploadedfiles);
        // setUploadedDocFileIds(response?.data?.uploadedfileids);
        // setIsApplied(response.data.isappiled);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedJobId) {
      getJobDetails();
    }
  }, [selectedJobId]);
  return (
    <div className="col-lg-8">
      <div className="challenge-info bg-white p-4 rounded-10 shadow-sm">
        <div className="d-flex gap-3 flex-wrap align-items-center justify-content-between mb-3">
          <ul className="tags d-flex flex-wrap gap-2 fs-sm">
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-primary body-bg rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span>
                  {cityName}, {stateName}, {country}
                </span>
              </Link>
            </li>
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-primary body-bg rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  event_note
                </span>
                <span>{createdDate}</span>
              </Link>
            </li>
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-primary body-bg rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  schedule
                </span>
                <span>{jobTypeValue}</span>
              </Link>
            </li>
          </ul>
          <div className="d-flex flex-wrap gap-2 align-items-center">
            <p className="fs-sm text-gray">
              72 applied <span className="text-black">(40%)</span>
            </p>
            <div
              className="progress body-bg"
              role="progressbar"
              aria-label="Example 1px high"
              aria-valuenow="40"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '100px', height: '10px' }}
            >
              <div className="progress-bar" style={{ width: '40%' }}></div>
            </div>
          </div>
        </div>
        <h3>
          <Link to="/challenges-details" className="text-black">
            {title}
          </Link>
        </h3>
        <p className="amount text-success fw-semibold mb-3">{code}</p>

        <div className="description pt-3">
          <h6 className="mb-3">{t('Summary')}</h6>
          <p>{summary}</p>

          <h6 className="mb-3">{t('Details')}</h6>
          <p dangerouslySetInnerHTML={{ __html: details }}></p>

          <h6 className="mb-3">{t('About Position')}</h6>
          <p dangerouslySetInnerHTML={{ __html: aboutPosition }}></p>

          <h6 className="mb-3">{t('About Company')}</h6>
          <p dangerouslySetInnerHTML={{ __html: aboutCompany }}></p>

          <h6 className="mb-3">{t('Tech Stack')}</h6>
          <ul className="tags d-flex flex-wrap gap-2 fs-sm">
            {requiredSkills.map((skill, index) => {
              return (
                <li className="tag" key={index}>
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-primary body-bg rounded-90"
                  >
                    <span>{skill.label}</span>
                  </Link>
                </li>
              );
            })}
            {optionalSkills.map((skill, index) => {
              return (
                <li className="tag" key={index}>
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-primary body-bg rounded-90"
                  >
                    <span>{skill.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>

          <h6 className="mb-3">{t('Salary and compensation')}</h6>
          <p>
            €{minSalary} — €{maxSalary}
          </p>

          <div className="action d-flex flex-wrap gap-2 mt-4">
            <Link to="#" className="btn btn-secondary">
              {t('Apply Now')}
            </Link>
            <Link
              to={`/challenge/details/${selectedJobId}`}
              className="btn btn-outline-light"
            >
              {t('More Details')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeLandingRightPanel;
