/* eslint-disable */
import React from 'react';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import Footer from 'Components/Common/Footer/Footer';
import EventsDetailsBodyComponent from 'Components/EventsComponents/EventsDetailsComponents/EventsDetailsBodyComponent/EventsDetailsBodyComponent';

const EventsDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="events" />

      <div id="main_content" className="position-relative">
        <EventsDetailsBodyComponent />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default EventsDetails;
