/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { useParams } from 'react-router-dom';
import CompaniesDetailsLeftComponent from '../CompaniesDetailsLeftComponent/CompaniesDetailsLeftComponent';
import CompaniesDetailsRightComponent from '../CompaniesDetailsRightComponent/CompaniesDetailsRightComponent';
import ReviewModal from 'Components/Common/Modals/ReviewModal';
import SuccessModal from 'Components/Common/Modals/SuccessModal';
import { useTranslation } from 'react-i18next';

const CompaniesDetailsBodyComponent = () => {
  const { t } = useTranslation(); // for translations
  const params = useParams();

  const token = localStorage.getItem('token');

  // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');

  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  const [companyState, setCompanyState] = useState('');
  const [companyCountry, setCompanyCountry] = useState('');
  const [companyTags, setCompanyTags] = useState([]);
  const [companyDetails, setCompanyDetails] = useState('');
  const [companyServices, setCompanyServices] = useState([]);
  const [companySearchings, setCompanySearchings] = useState([]);
  const [companyInterests, setCompanyInterests] = useState([]);
  const [companyDocs, setCompanyDocs] = useState([]);
  // const [moderatorId, setModeratorId] = useState(null);

  // const [isLoading, setIsLoading] = useState(false);
  const [companyMemberList, setCompanyMemberList] = useState([]);

  const [ratingCount, setRatingCount] = useState('0.0');
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  // const [isReviewLoading, setIsReviewLoading] = useState(false);

  //function for company details
  const getCompanyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COMPANY_DETAILS +
        `/${params.id}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        // setModeratorId(response.data.moderator);
        setCompanyLogo(
          response.data.logo?.path ? response.data.logo?.path : ''
        );
        setCompanyName(response.data.name);
        setCompanyDetails(response.data.about);
        setCompanyServices(response.data.companyservices);
        setCompanySearchings(response.data.companysearching);
        setCompanyInterests(response.data.companyinterest);
        setCompanyState(response.data.state);
        setCompanyCity(response.data.city);
        setCompanyCountry(response.data.country);
        setCompanyDocs(
          response.data?.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        setCompanyTags(response.data?.companytags);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyMemberList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_MEMBERS +
        `?token=${token}&company=${params.id}&isallparticipants=${true}`;

      // console.log("url of company member list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log('response in company member list------>', response);

      if (response.status) {
        setCompanyMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    // setIsReviewLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=company&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log('review list response', response);

      // setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount('');
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterModalClose = () => {
    getCompanyDetails();
    getAllCompanyMemberList();
    getReviewsLists();
  };

  useEffect(() => {
    if (params.id) {
      getCompanyDetails();
      getAllCompanyMemberList();
      getReviewsLists();
    }
  }, [params.id]);

  // useEffect(() => {
  //   const modalTarget = document.querySelector('#esg_modal');
  //   const modal = new bootstrap.Modal(modalTarget);
  //   modal.show();
  // }, []);

  return (
    <section className="company-details-wrapper header-spacing py-5">
      <div className="container">
        <div className="row">
          {/* ------- left componet ------------ */}
          <CompaniesDetailsLeftComponent
            companyLogo={companyLogo}
            companyName={companyName}
            companyCity={companyCity}
            companyState={companyState}
            companyCountry={companyCountry}
            companyTags={companyTags}
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            companySearchings={companySearchings}
            companyInterests={companyInterests}
          />

          {/* -------- right component ---------- */}
          <CompaniesDetailsRightComponent
            companyDetails={companyDetails}
            companyServices={companyServices}
            companySearchings={companySearchings}
            companyInterests={companyInterests}
            companyDocs={companyDocs}
            companyMemberList={companyMemberList}
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
          />
        </div>
      </div>

      {/* --------------- feedback modal ----------- */}
      <ReviewModal moduleName="company" />

      {/*  ------------- greetings modal ----------- */}
      <SuccessModal
        afterModalClose={afterModalClose}
        successText={t('Reviews submitted successfully')}
      />
    </section>
  );
};

export default CompaniesDetailsBodyComponent;
