import React from 'react';

const KPIsTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="kpis-tab-pane"
      role="tabpanel"
      aria-labelledby="kpis-tab"
      tabIndex="0"
    >
      <div className="kpi-container bg-white p-4 rounded-10 shadow-sm">
        <h3>KPIs</h3>
      </div>
    </div>
  );
};

export default KPIsTab;
