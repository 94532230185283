import React from 'react';
import { Link } from 'react-router-dom';

const PortfolioTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="portfolio-tab-pane"
      role="tabpanel"
      aria-labelledby="portfolio-tab"
      tabIndex="0"
    >
      <div className="portfolio container">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-1.jpg"
                  alt="Portfolio 1"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-2.jpg"
                  alt="Portfolio 2"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-3.jpg"
                  alt="Portfolio 3"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-4.jpg"
                  alt="Portfolio 4"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-5.jpg"
                  alt="Portfolio 5"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-6.jpg"
                  alt="Portfolio 6"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-7.jpg"
                  alt="Portfolio 7"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-8.jpg"
                  alt="Portfolio 8"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-9.jpg"
                  alt="Portfolio 9"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-10.jpg"
                  alt="Portfolio 10"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-11.jpg"
                  alt="Portfolio 11"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="img-container rounded-5 overflow-hidden">
              <Link to="#">
                <img
                  src="assets/img/portfolio-12.jpg"
                  alt="Portfolio 12"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTab;
