/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';

/*------ import url and method ------*/
import * as url from '../../helper/UrlHelper';
import { postData } from '../../utils/Gateway';

import { Link } from 'react-router-dom';
// import { GlobalProvider } from '../../context/GlobalContext';
import { useTranslation } from 'react-i18next';
import { getPublicIP } from '../../helper/AuthHelper/AuthHelper';
import { ecosystemSlug } from '../../Config/Config';
// import { useStateManager } from 'react-select';

const Register = () => {
  // const {
  //   // createAccountMessage,
  //   setcreateAccountMessage,
  //   // setuserEmailforOtp,
  //   // setVerifyModuleName,
  // } = useContext(GlobalProvider);

  // const history = useHistory();
  const { t } = useTranslation(); // translation marker

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  /*----- UseState to send username and password for Login ----*/
  const [userName, setuserName] = useState('');
  const [userSurname, setuserSurname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [isAgreePolicy, setIsAgreePolicy] = useState(false);
  const [isSubscribeCommercialMail, setIsSubscribeCommercialMail] =
    useState(false);
  const [showInput, setShowInput] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  // const [createAccountMessage, setcreateAccountMessage] = useState('');
  // const [verifyModuleName, setVerifyModuleName] = useState('');
  //   const [userEmailforOtp, setuserEmailforOtp] = useState('');

  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const refParam = searchParams.get('ref');
  const secretParam = searchParams.get('secret');

  // console.log(refParam); // Outputs: http://localhost:4202/events/details/6538fd3640da4a0c55804250
  // console.log(secretParam); // Outputs: 9ZSW

  // console.log(userEmailforOtp);

  const [firstCode, setFirstCode] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [thirdCode, setThirdCode] = useState('');
  const [fourthCode, setFourthCode] = useState('');
  // const [otpFailed, setotpFailed] = useState(false);
  const [otpSending, setotpSending] = useState(false);
  // const [otpResendMessage, setotpResendMessage] = useState('');

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    firstNameWarning: false,
    lastNameWarning: false,
    emailWarning: false,
    validEmailWarning: false,
    passwordWarning: false,
    agreeWarning: false,
  });
  /* Hook for validation end */

  // validation function
  const formValidation = () => {
    let isValid = true;

    if (userName === '') {
      setValidation(prevState => ({ ...prevState, firstNameWarning: true }));
      isValid = false;
    }

    if (userSurname === '') {
      setValidation(prevState => ({ ...prevState, lastNameWarning: true }));
      isValid = false;
    }

    if (userEmail === '') {
      setValidation(prevState => ({ ...prevState, emailWarning: true }));
      isValid = false;
    } else {
      setValidation(prevState => ({ ...prevState, emailWarning: false }));
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!mailformat.test(userEmail)) {
        setValidation(prevState => ({
          ...prevState,
          validEmailWarning: true,
        }));
        isValid = false;
      } else {
        setValidation(prevState => ({
          ...prevState,
          validEmailWarning: false,
        }));
      }
    }

    if (userPassword === '') {
      setValidation(prevState => ({ ...prevState, passwordWarning: true }));
      isValid = false;
    }

    if (isAgreePolicy === false) {
      setValidation(prevState => ({ ...prevState, agreeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  // function for change text type
  // const toggleText = () => {
  //   setShowPassword(!showPassword);
  // };

  //function for sign up
  const signupHandler = async () => {
    if (formValidation()) {
      setIsLoading(true);
      const userPublicIp = await getPublicIP();

      try {
        let userData = {
          name: userName,
          surname: userSurname,
          email: userEmail,
          password: userPassword,
          subscribecommercialmail: isSubscribeCommercialMail ? '1' : '0',
          userip: userPublicIp,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_REGISTRATION +
          `?ecosystemslug=${ecosystemSlug}`;

        console.log('request url -->', requestURL);

        const response = await postData(requestURL, userData);

        console.log('response -->', response);

        setIsLoading(false);
        if (response.status) {
          // setuserEmailforOtp(userEmail);
          resetRegisterData();

          // setVerifyModuleName('OTP');
          // history.push('/admin/verifyemail');
          setShowInput(true);
        } else {
          setResponseMessage(response.message);
          // setVerifyModuleName('');
          setShowInput(false);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  // function for reset the useState containing the email and password text fields
  const resetRegisterData = () => {
    setuserName('');
    setuserSurname('');
    // setUserEmail('');
    setUserPassword('');
    setIsAgreePolicy(false);
    setIsSubscribeCommercialMail(false);
    setValidation({
      firstNameWarning: false,
      lastNameWarning: false,
      emailWarning: false,
      validEmailWarning: false,
      passwordWarning: false,
      agreeWarning: false,
    });
    // setcreateAccountMessage('');
  };

  // const [userEmailforOtp, setUserEmailforOtp] = useState('');

  // function for backspace reference second digit
  const secondCodeKey = e => {
    if (e.key === 'Backspace') {
      if (secondCode === '' && secondCode.length === 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = e => {
    if (e.key === 'Backspace') {
      if (thirdCode === '' && thirdCode.length === 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = e => {
    if (e.key === 'Backspace') {
      if (fourthCode === '' && fourthCode.length === 0) {
        code3Ref.current?.focus();
      }
    }
  };

  //otp login function
  const validateOtpHandler = async () => {
    try {
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      let userData = {
        email: userEmail,
        otp: finalCode,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_VALIDATE_OTP +
        `?ecosystemslug=${ecosystemSlug}`;

      // console.log('requestURL', requestURL);
      // console.log('userData', userData);

      const response = await postData(requestURL, userData);

      console.log(response);

      if (response.status) {
        // setotpFailed(false);

        // assigning the loggedin user's id,token,all data to the browser's local storage.
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response.data));
        localStorage.setItem('userID', JSON.stringify(response.data._id));
        localStorage.setItem('ecosystem', response.ecosystemid);
        localStorage.setItem('ecosystemlogo', response.ecosystemlogo);
        localStorage.setItem('ecosystemslug', response.ecosystemslug);
        localStorage.setItem('moduleaccess', response.data.moduleaccessdata);

        if (refParam) {
          console.log(`if`);
          window.location.href = `${refParam}?secret=${secretParam}`;
        } else {
          console.log(`else`);
          window.location.reload();
        }
      }
      // else {
      //   setotpFailed(true);
      // }
    } catch (error) {
      console.log(error.message);
    }
  };

  //otp resend function
  const resendOtpHandler = async () => {
    try {
      setotpSending(true);
      let userData = {
        email: userEmail,
      };
      let requestURL = url.API_BASE_URL + url.API_GET_OTP;

      const response = await postData(requestURL, userData);

      console.log(response);

      setotpSending(false);

      // if (response.status) {
      //   // setotpResendMessage(t('We have sent OTP in your email'));
      // } else {
      //   // setotpResendMessage('');
      // }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset function
  //  const resetOtpModal = () => {
  //    setotpFailed(false);
  //    setuserEmailforOtp('');
  //  };

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== '' && firstCode.length === 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== '' && secondCode.length === 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== '' && thirdCode.length === 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  useEffect(() => {
    if (fourthCode !== '') {
      validateOtpHandler();
    }
  }, [fourthCode]);

  return (
    <div className="d-flex justify-content-center align-items-center my-2">
      <div className="card p-4" style={{ width: '25rem' }}>
        {!showInput ? (
          <>
            <h3 className="text-center mb-4">Register</h3>
            <form>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fName"
                  placeholder={t('Enter First Name')}
                  value={userName}
                  onChange={e => {
                    setValidation(prevState => ({
                      ...prevState,
                      firstNameWarning: false,
                    }));
                    setuserName(e.target.value);
                    setResponseMessage('');
                  }}
                />
                {validation.firstNameWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">
                      {t('Please enter first name')}
                    </span>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lName"
                  placeholder={t('Enter Last Name')}
                  value={userSurname}
                  onChange={e => {
                    setValidation(prevState => ({
                      ...prevState,
                      lastNameWarning: false,
                    }));
                    setuserSurname(e.target.value);
                    setResponseMessage('');
                  }}
                />
                {validation.lastNameWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">
                      {' '}
                      {t('Please enter last name')}
                    </span>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="registerEmail" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  value={userEmail}
                  onChange={e => {
                    setValidation(prevState => ({
                      ...prevState,
                      emailWarning: false,
                      validEmailWarning: false,
                    }));
                    setUserEmail(e.target.value);
                    setResponseMessage('');
                  }}
                />
                {/* ------ email warning start ------ */}
                {validation.emailWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block"> {t('Please enter email')}</span>
                  </div>
                )}
                {/* ----- valid email warning ------ */}
                {validation.validEmailWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">
                      {t('Please enter valid email')}
                    </span>
                  </div>
                )}

                {/* ----- create and signin section end ----- */}
                {responseMessage === '' ? null : (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">{responseMessage}</span>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="nPassword"
                  placeholder="Enter Password"
                  value={userPassword}
                  onChange={e => {
                    setValidation(prevState => ({
                      ...prevState,
                      passwordWarning: false,
                    }));
                    setUserPassword(e.target.value);
                    setResponseMessage('');
                  }}
                />
                {validation.passwordWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">
                      {t('Please enter password')}
                    </span>
                  </div>
                )}
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agree1"
                  checked={isAgreePolicy}
                  onChange={e => {
                    setValidation(prevState => ({
                      ...prevState,
                      agreeWarning: false,
                    }));
                    setIsAgreePolicy(e.target.checked);
                    setResponseMessage('');
                  }}
                />
                <label className="form-check-label" htmlFor="legalNotice">
                  Agree with the Legal Notice & Privacy Policy
                </label>
                {validation.agreeWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">
                      {t(
                        'Please Agree with the Legal Notice and Privacy Policy'
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agree2"
                  checked={isSubscribeCommercialMail}
                  onChange={e => {
                    setIsSubscribeCommercialMail(e.target.checked);
                    setResponseMessage('');
                  }}
                />
                <label className="form-check-label" htmlFor="commercialInfo">
                  I agree to receive commercial information by email
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                onClick={signupHandler}
                disabled={isLoading ? true : false}
                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
              >
                Create Account
                {isLoading && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>

              <Link to="/login" className="btn btn-secondary w-100 mt-2">
                Sign In
              </Link>
            </form>
          </>
        ) : (
          <>
            <h3 className="text-center mb-4">Verify Email</h3>
            <p>Email: {userEmail}</p>

            <div className="d-flex justify-content-between">
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code1Ref}
                value={firstCode}
                onChange={e => {
                  // setotpFailed(false);
                  // setotpResendMessage('');
                  setFirstCode(e.target.value.slice(0, e.target.maxLength));
                }}
                placeholder="-"
                maxLength="1"
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code2Ref}
                value={secondCode}
                onChange={e => {
                  // setotpFailed(false);
                  // setotpResendMessage('');
                  setSecondCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={secondCodeKey}
                placeholder="-"
                maxLength="1"
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code3Ref}
                value={thirdCode}
                onChange={e => {
                  // setotpFailed(false);
                  // setotpResendMessage('');
                  setThirdCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={thirdCodeKey}
                placeholder="-"
                maxLength="1"
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code4Ref}
                value={fourthCode}
                onChange={e => {
                  // setotpFailed(false);
                  // setotpResendMessage('');
                  setFourthCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={fourthCodeKey}
                placeholder="-"
                maxLength="1"
              />

              <button
                type="button"
                className="btn btn-link w-100 mt-2"
                onClick={resendOtpHandler}
                disabled={otpSending ? true : false}
                style={{
                  cursor: otpSending ? 'not-allowed' : 'pointer',
                }}
              >
                Resend OTP
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
