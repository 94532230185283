/* eslint-disable */

import AccountsDetails from '../Pages/Accounts/AccountsDetails/AccountsDetails';
import AccountsLanding from '../Pages/Accounts/AccountsLanding/AccountsLanding';
import ForgotPassword from '../Pages/Auth/ForgotPassword';
import Login from '../Pages/Auth/Login';
import Logout from '../Pages/Auth/Logout';
import Register from '../Pages/Auth/Register';
import ChallengesDetails from '../Pages/Challenges/ChallengesDetails/ChallengesDetails';
import ChallengesLanding from '../Pages/Challenges/ChallengesLanding/ChallengesLanding';
import CompaniesDetails from '../Pages/Companies/CompaniesDetails/CompaniesDetails';
import CompaniesLanding from '../Pages/Companies/CompaniesLanding/CompaniesLanding';
import CoursesDetails from '../Pages/Courses/CoursesDetails/CoursesDetails';
import Courses from '../Pages/Courses/CoursesLanding/CoursesLanding';
import EventsDetails from '../Pages/Events/EventsDetails/EventsDetails';
import EventsLanding from '../Pages/Events/EventsLanding/EventsLanding';
import Home from '../Pages/Home/Home';
import ProjectsDetails from '../Pages/Projects/ProjectsDetails/ProjectsDetails';
import ProjectsLanding from '../Pages/Projects/ProjectsLanding/ProjectsLanding';
import SuccessStoriesDetails from '../Pages/SuccessStories/SuccessStoriesDetails/SuccessStoriesDetails';
import SuccessStoriesLanding from '../Pages/SuccessStories/SuccessStoriesLanding/SuccessStoriesLanding';

/*======= public routes =======*/
const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgotpassword', component: ForgotPassword },
];
/*======= protected routes =======*/
const protectedRoutes = [
  { path: '/logout', component: Logout },

  { path: '/', component: Home }, // home page
  { path: '/accounts', component: AccountsLanding }, // accounts page
  { path: '/accounts/details/:id', component: AccountsDetails }, // accounts details page
  { path: '/courses', component: Courses }, // courses page
  { path: '/courses/details/:id', component: CoursesDetails }, // courses details page
  { path: '/events', component: EventsLanding }, // events page
  { path: '/events/details/:id', component: EventsDetails }, // events details page
  { path: '/successstories', component: SuccessStoriesLanding }, // success page
  { path: '/successstories/details/:id', component: SuccessStoriesDetails }, // success page
  { path: '/companies', component: CompaniesLanding }, // Companies page
  { path: '/company/details/:id', component: CompaniesDetails }, // Companies details page
  { path: '/projects', component: ProjectsLanding }, // Projects page
  { path: '/projects-details', component: ProjectsDetails }, // Projects details page
  { path: '/challenges', component: ChallengesLanding }, // challenges page
  { path: '/challenge/details/:id', component: ChallengesDetails }, // challenges details page
];

export { publicRoutes, protectedRoutes };
