import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import languageOptions from 'Data/Language.json';
// import LoginModal from '../Modals/LoginModal';
// import RegisterModal from '../Modals/RegisterModal';
// import ForgotPasswordModal from '../Modals/ForgotPasswordModal';

const CommonHeader = ({ moduleName }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   i18n.changeLanguage('en');
  // }, []);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      id="header"
      className={`${moduleName === 'home' && 'header-transparent'} ${
        isScrolled ? 'scroll' : 'scroll'
      } position-fixed start-0 top-0 end-0`}
    >
      <div className="header-top bg-primary">
        <div className="container">
          <ul className="nav align-items-center justify-content-end fs-xs text-uppercase">
            <li>
              <Link to="#" className="text-white">
                {t('Virtual Classroom')}
              </Link>
            </li>
            <li>
              <Link to="#" className="text-white">
                {t('Professional Guidance')}
              </Link>
            </li>
            <li>
              <Link to="#" className="text-white">
                {t('Simulators')}
              </Link>
            </li>
            <li>
              <Link to="#" className="text-white">
                {t('Contact')}
              </Link>
            </li>
            <li>
              <Link
                to="/logout"
                className="text-white bg-secondary menu-btn"
                // data-bs-toggle="modal"
                // data-bs-target="#loginModal"
              >
                {t('Logout')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <nav
        className={`navbar p-0 navbar-expand-lg ${
          moduleName !== 'home' && 'bg-white shadow-sm'
        }  position-static`}
      >
        <div className="container">
          <Link className="navbar-brand2" to="/">
            <img src="/assets/img/university-logo.svg" alt="IEB" className="img-fluid  " />
          </Link>
          <button
            className="navbar-toggler p-0 border-0 shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav text-uppercase ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  {t('Forum')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    moduleName === 'accounts' && 'active'
                  } nav-link`}
                  to="/accounts"
                >
                  {t('Accounts')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${moduleName === 'courses' && 'active'} nav-link`}
                  to="/courses"
                >
                  {t('Courses')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${moduleName === 'events' && 'active'} nav-link`}
                  to="/events"
                >
                  {t('Events')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${moduleName === 'success' && 'active'} nav-link`}
                  to="/successstories"
                >
                  {t('Success')}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="#">
                  Jobs
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className={`${
                    moduleName === 'challenges' && 'active'
                  } nav-link`}
                  to="/challenges"
                >
                  {t('Challenges')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    moduleName === 'companies' && 'active'
                  } nav-link`}
                  to="/companies"
                >
                  {t('Companies')}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="#">
                  Marketplace
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className={`${
                    moduleName === 'projects' && 'active'
                  } nav-link`}
                  to="/projects"
                >
                  {t('Projects')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <span className="d-block material-symbols-outlined">
                    search
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* ------- language section start ------ */}
        <div className="me-4">
          <ul className="list_stye_none d-flex gap-3 align-items-center">
            <li className="language-selector">
              <div className="dropdown">
                <button
                  className="dropdown-toggle bg-transparent text-white border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* ------ get the value from i18 and display start----- */}
                  {i18n.language}
                  {/* ------ get the value from i18 and display end----- */}
                </button>
                {/* ------ language list section start ----- */}
                <ul className="dropdown-menu mw-unset rounded-1 border-0 shadow-lg">
                  {languageOptions.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          onClick={() => {
                            i18n.changeLanguage(item.value);
                          }}
                          className="dropdown-item"
                          to="#"
                        >
                          {item.value}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {/* ------ language list section end ----- */}
              </div>
            </li>
          </ul>
        </div>
        {/* ------- language section start ------ */}
      </nav>

      {/* --------------- modals ------------- */}

      {/* =========== login modal =========== */}
      {/* <LoginModal /> */}

      {/* =========== register modal =========== */}
      {/* <RegisterModal /> */}

      {/* ============= forgot password modal =========== */}
      {/* <ForgotPasswordModal /> */}
    </header>
  );
};

export default CommonHeader;
