import React from "react";
import GlobalContext from "./context/GlobalContext";
import Authmiddleware from "./routes/AuthMiddleWare";

const App = () => {
  return (
    <GlobalContext>
      <Authmiddleware />
    </GlobalContext>
  );
};

export default App;
