/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

const AccountsFilterPopup = ({
  ecosystemList,
  serviceList,
  skillList,
  tagList,
  setFilterEcosystem,
  resetList,
  setfilterCompany,
  setfilterName,
  setFilterPosition,
  setFilterRating,
  setFilterServices,
  setfilterSkills,
  setFilterTags,
}) => {
  const { t } = useTranslation(); // for translations

  const [ecosystemValue, setEcosystemValue] = useState(null); // for ecosystem dropdown
  const [companyName, setCompanyName] = useState(''); // for company name
  const [name, setname] = useState(''); // for name
  const [position, setPosition] = useState(''); // for position
  const [selectedRating, setSelectedRating] = useState(''); // for rating
  const [services, setServices] = useState([]); // for service
  const [skills, setSkills] = useState([]); // for skill
  const [tags, setTags] = useState([]); // for tags

  // function to select rating
  const handleRatingChange = event => {
    setSelectedRating(event.target.value);
  };

  //function for select service
  const serviceSelectionHandler = data => {
    let prevData = services;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        'userservicefilter-' + data.value
      );
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        'userservicefilter-' + data.value
      );
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setServices(prevData);
  };

  // function for set tags
  const skillSelectionHandler = data => {
    let prevData = skills;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById('skilldata-' + data.value);
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById('skilldata-' + data.value);
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setSkills(prevData);
  };

  //function for select tags
  const tagSelectionHandler = data => {
    let prevData = tags;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById('tagdata-' + data.value);
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById('tagdata-' + data.value);
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setTags(prevData);
  };

  //function for search
  const searchHandler = () => {
    setfilterName(name);
    setfilterCompany(companyName);
    setFilterPosition(position);
    setFilterRating(selectedRating);
    setFilterServices(services);
    setfilterSkills(skills);
    setFilterTags(tags);
    setFilterEcosystem(ecosystemValue ? ecosystemValue.value : null);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector('#offcanvasFilter');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  // Reset the checkboxes in the DOM for services
  const resetSelectServices = () => {
    serviceList.forEach(item => {
      const checkboxElement = document.getElementById(
        'userservicefilter-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  // Reset the checkboxes in the DOM for skills
  const resetSelectSkills = () => {
    skillList.forEach(item => {
      const checkboxElement = document.getElementById(
        'skilldata-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  // Reset the checkboxes in the DOM for tags
  const resetSelectTags = () => {
    tagList.forEach(item => {
      const checkboxElement = document.getElementById('tagdata-' + item.value);
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  const resetHandler = () => {
    setEcosystemValue(null);
    setname('');
    setCompanyName('');
    setPosition('');
    setSelectedRating('');
    setTags([]);
    setServices([]);
    setSkills([]);
    // removeActiveClass();
    resetSelectTags();
    resetSelectServices();
    resetSelectSkills();
  };

  const reloadUser = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector('#offcanvasFilter');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetList();
  };
  return (
    <div
      className="offcanvas offcanvas-start filter-offcanvas bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close body-bg p-0 rounded-5 shadow-none m-0 ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body pt-0">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {/* ----- ecosystem filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                {t('Select Ecosystem')}
              </button>
            </div>

            <Select
              placeholder={t('Select Ecosystem')}
              options={ecosystemList}
              value={ecosystemValue}
              onChange={val => setEcosystemValue(val)}
            />
          </div>

          {/* ----- company name filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Company Name')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder="Enter Company"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ----- name filter -----  */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('User Name')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder={t('Enter Name')}
                    value={name}
                    onChange={e => setname(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ----- position filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Position')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder={t('Enter Position')}
                    value={position}
                    onChange={e => setPosition(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ----- rating filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Rating')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <ul className="list_stye_none filter_rating">
                    {[5, 4, 3, 2, 1].map(rating => (
                      <li key={rating}>
                        <label className="radio-check">
                          <input
                            type="radio"
                            name="rdo3"
                            value={rating}
                            checked={selectedRating === String(rating)}
                            onChange={handleRatingChange}
                          />

                          <p>
                            {rating}{' '}
                            <i className="material-icons-outlined">
                              {t('star')}
                            </i>{' '}
                            {t('& above')}
                          </p>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* ----- service filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Services')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {serviceList.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => serviceSelectionHandler(item)}
                          id={'userservicefilter-' + item.value}
                        />
                        <label
                          htmlFor={'userservicefilter-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                        {/* <Link
                          to="#"
                          onClick={() => serviceSelectionHandler(item)}
                          id={'userservicefilter-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </Link> */}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* ----- skill filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Skills')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {skillList.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => skillSelectionHandler(item)}
                          id={'skilldata-' + item.value}
                        />
                        <label
                          htmlFor={'skilldata-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* ----- tags filter ----- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Tags')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {tagList.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => tagSelectionHandler(item)}
                          id={'tagdata-' + item.value}
                        />
                        <label
                          htmlFor={'tagdata-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* --------- buttons ---------- */}
          <div
            className="action"
            onClick={() => {
              reloadUser(), resetHandler();
            }}
          >
            <button type="submit" className="btn btn-primary rounded-5">
              {t('Reset')}
            </button>
          </div>
          <div className="action" onClick={searchHandler}>
            <button type="submit" className="btn btn-primary rounded-5">
              {t('Show Result')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsFilterPopup;
