/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CompaniesFilterPopup = ({
  tagList,
  companyTypeList,
  setFilterCompanyName,
  setFilterCompanyTypes,
  setFilterCompanyTags,
  setFilterRating,
  resetList,
}) => {
  const { t } = useTranslation(); // for translations

  const [companyName, setCompanyName] = useState('');
  const [typeValues, settypeValues] = useState([]);
  const [tagValues, settagValues] = useState([]);
  const [selectedRating, setSelectedRating] = useState('');

  //function for select event type
  const companyTypeSelectionHandler = data => {
    let prevData = typeValues;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        'companytypefilter-' + data.value
      );
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        'companytypefilter-' + data.value
      );
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }
    settypeValues(prevData);
  };

  const resetSelectedTypes = () => {
    companyTypeList.forEach(item => {
      const checkboxElement = document.getElementById(
        'companytypefilter-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  //function for select event tags
  const companyTagSelectionHandler = data => {
    let prevData = tagValues;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        'companytagfilter-' + data.value
      );
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        'companytagfilter-' + data.value
      );
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }
    settagValues(prevData);
  };

  const resetSelectedTags = () => {
    tagList.forEach(item => {
      const checkboxElement = document.getElementById(
        'companytagfilter-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  const handleRatingChange = event => {
    setSelectedRating(event.target.value);
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterCompanyName(companyName);
    setFilterCompanyTypes(typeValues);
    setFilterCompanyTags(tagValues);
    setFilterRating(selectedRating);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector('#offcanvasFilter');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setCompanyName('');
    settypeValues([]);
    settagValues([]);
    setSelectedRating('');

    resetSelectedTags();
    resetSelectedTypes();
  };

  const reloadCompany = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector('#offcanvasFilter');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
    resetList();
  };

  return (
    <div
      className="offcanvas offcanvas-start filter-offcanvas bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close body-bg p-0 rounded-5 shadow-none m-0 ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body pt-0">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {/* ----------- user name ----------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Company Name')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder={t('Enter Company Name')}
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------- rating -------------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Rating')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <ul className="list_stye_none filter_rating">
                    {[5, 4, 3, 2, 1].map(rating => (
                      <li key={rating}>
                        <label className="radio-check">
                          <input
                            type="radio"
                            name="rdo3"
                            value={rating}
                            checked={selectedRating === String(rating)}
                            onChange={handleRatingChange}
                          />

                          <p>
                            {rating}
                            <i className="material-icons-outlined">
                              {' '}
                              {t('star')}
                            </i>{' '}
                            {t('& above')}
                          </p>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* ------------ type/sector ------------ */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Type/Sector')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {companyTypeList?.map((companyType, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() =>
                            companyTypeSelectionHandler(companyType)
                          }
                          id={'companytypefilter-' + companyType.value}
                        />
                        <label
                          htmlFor={'companytypefilter-' + companyType.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {companyType.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/* ----------------- Tags --------------------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Tags')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {tagList?.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => companyTagSelectionHandler(item)}
                          id={'companytagfilter-' + item.value}
                        />
                        <label
                          htmlFor={'companytagfilter-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* --------- buttons -------------- */}
          <div
            className="action"
            onClick={() => {
              reloadCompany();
            }}
          >
            <button type="submit" className="btn btn-primary rounded-5">
              {t('Reset')}
            </button>
          </div>
          <div className="action" onClick={searchHandler}>
            <button type="submit" className="btn btn-primary rounded-5">
              {t('Show Result')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesFilterPopup;
