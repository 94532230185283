/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData, postData, putData } from 'utils/Gateway';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import EventDetailsLeftComponent from '../EventDetailsLeftComponent/EventDetailsLeftComponent';
import EventDetailsRightComponent from '../EventDetailsRightComponent/EventDetailsRightComponent';
import ReviewModal from 'Components/Common/Modals/ReviewModal';
import SuccessModal from 'Components/Common/Modals/SuccessModal';
import { generateRandomString } from 'utils/GetRandomString';

const EventsDetailsBodyComponent = () => {
  const { t } = useTranslation(); // for translations

  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const secretParam = searchParams.get('secret');

  // const { t } = useTranslation(); // translation marker

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');
  const params = useParams();

  const [secretCode, setSecretCode] = useState('');

  //  const [isLoading, setIsLoading] = useState(false);
  const [eventTitle, seteventTitle] = useState('');
  const [eventDate, seteventDate] = useState('');
  const [eventCity, seteventCity] = useState('');
  const [eventCountry, seteventCountry] = useState('');
  const [eventMembers, seteventMembers] = useState([]);
  const [isModerator, setIsModerator] = useState(false);
  const [isJoined, setisJoined] = useState(false);
  const [isApproved, setisApproved] = useState(false);
  const [requestDate, setrequestDate] = useState('');
  const [approveDate, setApproveDate] = useState('');
  const [eventTags, seteventTags] = useState([]);

  const [eventCategories, seteventCategories] = useState([]);
  const [eventSearchings, seteventSearchings] = useState([]);
  const [eventInterests, seteventInterests] = useState([]);
  const [eventSummary, seteventSummary] = useState('');
  const [eventDetails, seteventDetails] = useState('');
  const [eventDocs, seteventDocs] = useState([]);

  //  const [memberListLoading, setMemberListLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);

  //  const [isReviewLoading, setIsReviewLoading] = useState(false);

  const [ratingCount, setRatingCount] = useState('0.0');
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  const [galleryImages, setGalleryImages] = useState([]);

  const [isJoining, setIsJoining] = useState(false);

  const [similarList, setsimilarList] = useState([]);

  const [copyMessage, setCopyMessage] = useState('');

  //function for get event details
  const getEventDetails = async () => {
    try {
      //  setIsLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);
      //  setIsLoading(false);
      if (response.status) {
        if (
          userInfo?.role?.name === 'admin' ||
          userInfo._id.toString() === response.data?.moderator._id.toString()
        ) {
          setIsModerator(true);
        } else {
          setIsModerator(false);
        }
        seteventTitle(response.data.title);
        seteventDate(response.data.fromdate);
        seteventCity(response.data.city);
        seteventCountry(response.data.country);
        seteventMembers(response.data.eventmembers);
        seteventTags(response.data.taglist);
        setSecretCode(response.data.secret);

        //assign category
        if (response.data?.categories && response.data?.categories.length > 0) {
          seteventCategories(response.data?.categories);
        }

        if (response.data?.interests && response.data?.interests.length > 0) {
          seteventInterests(response.data?.interests);
        }

        if (response.data?.searchings && response.data?.searchings.length > 0) {
          seteventSearchings(response.data?.searchings);
        }

        //assing summary
        seteventSummary(response.data?.summary);

        //assign details
        seteventDetails(response.data?.description);

        //assign uploaded files
        seteventDocs(
          response?.data?.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          )
        );

        //assign is joined
        setisJoined(response.data?.isjoined);

        //assign is approved
        setisApproved(response.data?.isapproved);

        //assign request date
        setrequestDate(response.data?.requestdate);

        //assign approve date
        setApproveDate(response.data?.approvedate);

        //gallery images
        setGalleryImages(
          response.data?.gallery.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for join event
  const joinEventHandler = async () => {
    try {
      setIsJoining(true);
      let eventData = {
        event: params.id,
        useremail: userInfo.email,
        secret: secretParam ? secretParam : secretCode,
      };

      console.log('Join event Data------>', eventData);

      let requestURL =
        url.API_BASE_URL + url.API_JOIN_EVENT + `?token=${token}`;

      const response = await postData(requestURL, eventData);

      //  setIsJoining(false);
      console.log(response);
      if (response.status) {
        // if (secretParam) {
        //   const baseURL = fullURL.split("?")[0];
        //   window.location.href = baseURL;
        // }
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById('gretings_mdl')
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    //  setIsReviewLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=event&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log('review list response', response);

      //  setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount('');
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllEventMembers = async () => {
    try {
      //  setMemberListLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENT_MEMBER +
        `?token=${token}&event=${params.id}&isallparticipants=${true}`;

      const response = await getData(requestUrl);

      // console.log(response);
      //  setMemberListLoading(false);
      if (response.status) {
        setMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get eventlist function
  const getAllEvent = async (start, limit, sortingBy) => {
    try {
      //  setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_EVENTS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      //  setIsLoading(false);
      if (response.status) {
        const filteredResults = response.data.filter(
          event => event._id.toString() !== params.id.toString()
        );

        //  setsimilarList(filteredResults.slice(0, 5));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //share function
  const shareHandler = async () => {
    const baseURL = `${parsedURL.protocol}//${parsedURL.host}`;

    const secretString = generateRandomString(4); // Change 10 to the desired length

    try {
      let eventData = {
        secret: secretString,
      };
      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_EVENT +
        `/${params.id}` +
        `?token=${token}`;

      const response = await putData(requestUrl, eventData);

      if (response.status) {
        const redirectUrl = baseURL + `?ref=${fullURL}&secret=${secretString}`;

        const bootstrapModal = new bootstrap.Modal(
          document.getElementById('share_mdl')
        );
        bootstrapModal.show();

        setCopyMessage(redirectUrl);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterApiCall = () => {
    getEventDetails();
    getReviewsLists();
    getAllEventMembers();
    getAllEvent();
  };

  useEffect(() => {
    if (params.id) {
      getEventDetails();
      getReviewsLists();
      getAllEventMembers();
      getAllEvent();
    }
  }, [params.id]);

  useEffect(() => {
    if (secretParam) {
      // console.log(secretParam);
      joinEventHandler();
    }
  }, [secretParam]);

  return (
    <section className="event-details-wrapper header-spacing py-5">
      <div className="container">
        <div className="row">
          {/* --------- Event Details Left Panel --------- */}
          <EventDetailsLeftComponent
            eventTitle={eventTitle}
            eventDate={eventDate}
            eventCity={eventCity}
            eventCountry={eventCountry}
            eventMembers={eventMembers}
            isModerator={isModerator}
            isJoined={isJoined}
            isApproved={isApproved}
            requestDate={requestDate}
            approveDate={approveDate}
            eventTags={eventTags}
            joinEventHandler={joinEventHandler}
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            isJoining={isJoining}
            shareHandler={shareHandler}
          />

          {/* ----------- Event Details Right Panel --------- */}
          <EventDetailsRightComponent
            eventDetails={eventDetails}
            eventSummary={eventSummary}
            eventCategories={eventCategories}
            eventInterests={eventInterests}
            eventSearchings={eventSearchings}
            eventDocs={eventDocs}
            galleryImages={galleryImages}
            memberList={memberList}
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
          />
        </div>
      </div>

      {/* --------------- feedback modal ----------- */}
      <ReviewModal moduleName="event" />

      {/*  ------------- greetings modal ----------- */}
      <SuccessModal
        afterModalClose={afterApiCall}
        successText={t('Reviews submitted successfully')}
      />
    </section>
  );
};

export default EventsDetailsBodyComponent;
