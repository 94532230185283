/* eslint-disable */
import React, { useEffect, useState } from 'react';
import LessonsTabLeftPanel from './LessonsTabLeftPanel/LessonsTabLeftPanel';
import LessonsTabRightPanel from './LessonsTabRightPanel/LessonsTabRightPanel';
import { useTranslation } from 'react-i18next';

const LessonsTab = ({
  courseDetails = {},
  showFirstLessonInitital = false,
  setshowFirstLessonInitital,
}) => {
  const { t } = useTranslation(); // for translations

  const [selectedLessonId, setSelectedLessonId] = useState(null); // for selected lesson

  // ------------------------ function for next lesson handler ------------------------
  const nextLessonHandler = () => {
    const lessonExist = courseDetails.hasOwnProperty('courselessons');

    if (lessonExist) {
      const getCurrentIndexOfLesson = courseDetails?.courselessons.findIndex(
        item => item._id === selectedLessonId
      );

      if (getCurrentIndexOfLesson + 1 === courseDetails.courselessons.length) {
        setSelectedLessonId(courseDetails?.courselessons[0]?._id);
      } else {
        setSelectedLessonId(
          courseDetails?.courselessons[getCurrentIndexOfLesson + 1]?._id
        );
      }
    }
  };

  // console.log(`courseDetails`, courseDetails);

  useEffect(() => {
    const lessonExist = courseDetails.hasOwnProperty('courselessons');

    if (lessonExist) {
      setSelectedLessonId(courseDetails?.courselessons[0]?._id);

      if (showFirstLessonInitital) {
        setshowFirstLessonInitital(false);
      }
    }
  }, [courseDetails?.courselessons, showFirstLessonInitital]);

  if (
    courseDetails?.usercourserole !== '' ||
    (courseDetails?.issubscribed && courseDetails?.isapproved)
  ) {
    if (courseDetails?.courselessons?.length > 0) {
      return (
        <div
          className="tab-pane fade"
          id="lessons-tab-pane"
          role="tabpanel"
          aria-labelledby="lessons-tab"
          tabIndex="0"
        >
          <div className="row">
            {/* ----------- Lessons Tab Left Panel ----------- */}
            <LessonsTabLeftPanel
              courseDetails={courseDetails}
              selectedLessonId={selectedLessonId}
              setSelectedLessonId={setSelectedLessonId}
            />

            {/* ----------- Lessons Tab Right Panel ----------- */}
            <LessonsTabRightPanel
              selectedLessonId={selectedLessonId}
              nextLessonHandler={nextLessonHandler}
              userCourseRole={courseDetails?.usercourserole}
            />
          </div>
        </div>
      );
    } else {
      <div> Sorry....! Lessons are not available for this course</div>;
    }
  } else {
    <div> Sorry....! You don't have privilege to see this content</div>;
  }

  // return (
  //   <div
  //     className="tab-pane fade"
  //     id="lessons-tab-pane"
  //     role="tabpanel"
  //     aria-labelledby="lessons-tab"
  //     tabIndex="0"
  //   >
  //     <div className="row">
  //       {/* ----------- Lessons Tab Left Panel ----------- */}
  //       <LessonsTabLeftPanel />

  //       {/* ----------- Lessons Tab Right Panel ----------- */}
  //       <LessonsTabRightPanel />
  //     </div>
  //   </div>
  // );
};

export default LessonsTab;
