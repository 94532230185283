/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
/*------ import url and method ------*/
import { getData } from '../../../../../../utils/Gateway';
import * as url from '../../../../../../helper/UrlHelper';

import LessonContentsTab from '../LessonsSubTabs/LessonContentsTab';
import LessonTaskTab from '../LessonsSubTabs/LessonTaskTab';
import LessonQuestionTab from '../LessonsSubTabs/LessonQuestionTab';
import LessonCertificationTab from '../LessonsSubTabs/LessonCertificationTab';
import { useTranslation } from 'react-i18next';

const LessonsTabRightPanel = ({
  selectedLessonId = null,
  nextLessonHandler,
  userCourseRole = '',
}) => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');
  const params = useParams();

  const [lessonDetails, setLessonDetails] = useState({});

  //get lesson details
  const getLessonDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_DETAILS +
        `/${selectedLessonId}?token=${token}&filtercourse=${params.id}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setLessonDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedLessonId) {
      getLessonDetails();
    }
  }, [selectedLessonId]);

  return (
    <div className="col-lg-8">
      <ul
        className="nav nav-tabs text-center font-secondary fw-semibold border-0 rounded-10 overflow-hidden mb-3"
        id="lessonsTab"
        role="tablist"
      >
        <li className="nav-item flex-fill" role="presentation">
          <Link
            className="d-block bg-white px-3 py-3 lh-1 text-black active"
            id="content-tab"
            data-bs-toggle="tab"
            data-bs-target="#content-tab-pane"
            type="button"
            role="tab"
            aria-controls="content-tab-pane"
            aria-selected="true"
          >
            {t('Content')}
          </Link>
        </li>
        <li className="nav-item flex-fill" role="presentation">
          <Link
            className="d-block bg-white px-3 py-3 lh-1 text-black"
            id="task-tab"
            data-bs-toggle="tab"
            data-bs-target="#task-tab-pane"
            type="button"
            role="tab"
            aria-controls="task-tab-pane"
            aria-selected="false"
          >
            {t('Task')}
          </Link>
        </li>
        <li className="nav-item flex-fill" role="presentation">
          <Link
            className="d-block bg-white px-3 py-3 lh-1 text-black"
            id="question-tab"
            data-bs-toggle="tab"
            data-bs-target="#question-tab-pane"
            type="button"
            role="tab"
            aria-controls="question-tab-pane"
            aria-selected="false"
          >
            {t('Question')}
          </Link>
        </li>
        {/* <li className="nav-item flex-fill" role="presentation">
          <Link
            className="d-block bg-white px-3 py-3 lh-1 text-black"
            id="certification-tab"
            data-bs-toggle="tab"
            data-bs-target="#certification-tab-pane"
            type="button"
            role="tab"
            aria-controls="certification-tab-pane"
            aria-selected="false"
          >
            Certification
          </Link>
        </li> */}
      </ul>

      {/* --------- tab contents --------- */}
      <div className="tab-content" id="lessonsTabContent">
        {/* ----- lesson contents tab ----- */}
        <LessonContentsTab
          lessonDetails={lessonDetails}
          nextLessonHandler={nextLessonHandler}
          userCourseRole={userCourseRole}
        />

        {/* ----- lesson task tab ----- */}
        <LessonTaskTab
          userCourseRole={userCourseRole}
          taskList={lessonDetails?.tasklist}
          selectedLessonId={selectedLessonId}
        />

        {/* ----- lesson question tab ----- */}
        <LessonQuestionTab selectedLessonId={selectedLessonId} />

        {/* ----- lesson certification tab ----- */}
        <LessonCertificationTab />
      </div>
    </div>
  );
};

export default LessonsTabRightPanel;
