/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import sorting from 'Data/Sorting.json';

import { useTranslation } from 'react-i18next';
import CompaniesFilterPopup from 'Components/CompaniesComponents/Popups/CompaniesFilterPopup';

const CompaniesList = () => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');
  // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');

  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [params, setParams] = useState(null); // for url params
  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const [filterCompanyName, setFilterCompanyName] = useState('');
  const [filterCompanyTypes, setFilterCompanyTypes] = useState([]);
  const [filterCompanyTags, setFilterCompanyTags] = useState([]);
  const [filterRating, setFilterRating] = useState('');
  const [hideNextPageBtn, setHideNextPageBtn] = useState(false);

  // const glowCount = [1, 2, 3];

  //function for get all roles
  const getAllCompanyType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_COMPANY_TYPE + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyList = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterCompanyName !== '') {
        requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      }

      if (filterCompanyTypes.length > 0) {
        requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      }

      if (filterCompanyTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      }

      if (filterRating !== '') {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }

      // console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in app user list------>", response);

      setIsLoading(false);
      if (response.status) {
        if (start === '0') {
          setCompanyList(response.data);
        } else {
          setCompanyList([...companyList, ...response.data]);
        }

        if (limit > response.data.length) {
          setHideNextPageBtn(false);
        } else {
          setHideNextPageBtn(true);
        }

        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetList = () => {
    getAllCompanyList('0', '8', null);
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCompanyName('');
    setFilterCompanyTypes([]);
    setFilterCompanyTags([]);
    setFilterRating('');
  };

  useEffect(() => {
    if (
      filterCompanyName !== '' ||
      filterCompanyTypes.length > 0 ||
      filterCompanyTags.length > 0 ||
      filterRating !== ''
    ) {
      getAllCompanyList('0', '10', null);
    }
  }, [filterCompanyName, filterCompanyTypes, filterCompanyTags, filterRating]);

  //use effect for params
  useEffect(() => {
    if (params?.value) {
      getAllCompanyList('0', '10', params?.value);
    }
  }, [params]);

  useEffect(() => {
    getAllTags();
    getAllCompanyType();
    getAllCompanyList('0', '10', null);
  }, []);

  return (
    <section className="companies-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="companies-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              {t('Found')} <span>{companyList.length}</span> {t('result')}
            </p>
            <div className="d-flex gap-3">
              <Select
                placeholder={t('Select Position')}
                options={sorting}
                value={params}
                onChange={val => {
                  setParams(val);
                }}
              />
              <Link
                to="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            {companyList.map((company, index) => {
              return (
                <div className="company col-md-6 col-lg-4 mb-4" key={index}>
                  <div className="company-content p-3 bg-white rounded-10 shadow-sm">
                    <img
                      src={url.SERVER_URL + company.companylogo}
                      alt="Santander"
                      className="img-fluid mb-2"
                    />
                    <h3>
                      <Link to="/companies-details" className="text-black">
                        {company.name}
                      </Link>
                    </h3>
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-2">
                      <li className="tag">
                        <Link
                          to="#"
                          className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                        >
                          <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                            star
                          </span>
                          <span className="d-block fs-sm fw-bold">
                            {company.totalratings}
                          </span>
                          <span className="d-block fs-xs">
                            {company.totalreviews}
                          </span>
                        </Link>
                      </li>
                      <li className="tag">
                        <Link
                          to="#"
                          className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                        >
                          <span className="d-block material-symbols-outlined icon-md">
                            location_on
                          </span>
                          <span className="d-block">
                            {company.city} , {company.state} , {company.country}
                          </span>
                        </Link>
                      </li>
                    </ul>
                    <p className="text-dark">{company.servicestring}</p>
                    <div className="tag-container pt-3 mt-3 border-top">
                      <div className="search d-flex gap-3 align-items-center mb-2">
                        <p className="fs-sm fw-medium">{t('Search')}</p>
                        {company.searching.length > 0 && (
                          <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                            {company.searching.map((item, index) => {
                              return (
                                <li className="tag" key={index}>
                                  <Link
                                    to="#"
                                    className="d-block text-gray bg-gray-100 rounded-90"
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                      <div className="interest d-flex gap-3 align-items-center">
                        <p className="fs-sm fw-medium">{t('Interest')}</p>
                        {company.interest.length > 0 && (
                          <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                            {company.interest.map((item, index) => {
                              return (
                                <li className="tag" key={index}>
                                  <Link
                                    to="#"
                                    className="d-block text-gray bg-gray-100 rounded-90"
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                    <Link
                      to={`/company/details/${company._id}`}
                      className="btn btn-secondary w-100 mt-3"
                    >
                      {t('View Details')}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <ol className="pagination align-items-center gap-2 fw-semibold justify-content-center mt-4">
            <li className="prev me-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_back
                </span>
              </Link>
            </li>
            <li className="page active">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                1
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                2
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                3
              </Link>
            </li>
            <li className="page">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                ...
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="next ms-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_forward
                </span>
              </Link>
            </li>
          </ol> */}

          {hideNextPageBtn && (
            <div
              className="action"
              onClick={() => {
                getAllCompanyList(
                  companyList.length.toString(),
                  '4',
                  params?.value ? params?.value : null
                );
              }}
            >
              <button type="submit" className="btn btn-primary rounded-5">
                Next Page
              </button>
            </div>
          )}
        </div>
      </div>

      {/* -------------- filter popup ----------- */}
      <CompaniesFilterPopup
        setFilterCompanyName={setFilterCompanyName}
        setFilterCompanyTypes={setFilterCompanyTypes}
        setFilterCompanyTags={setFilterCompanyTags}
        setFilterRating={setFilterRating}
        tagList={tagList}
        companyTypeList={companyTypeList}
        resetList={resetList}
      />
    </section>
  );
};

export default CompaniesList;
