/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import carousel styles
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const HomeEvents = () => {
  const token = localStorage.getItem('token');
  const { t } = useTranslation(); // for translations

  const memberLimit = 5; //for displaying the limit number of members
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [eventList, seteventList] = useState([]); // for events lists

  //get eventlist function
  const getAllEvent = async (start, limit) => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&start=${start}&limit=${limit}`;

      console.log('request url for event list----->', requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      // setIsLoading(false);
      if (response.status) {
        seteventList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllEvent('0', '5');
  }, []);

  return (
    <section className="latest-events bg-primary py-50 py-md-70">
      <div className="container">
        <div className="sec-title text-white mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2 className="text-white">{t('Latest Events')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="/events" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {eventList?.map((eventData, index) => (
            <div key={index} className="event" style={{ padding: '0 10px' }}>
              <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
                <h3>
                  <Link
                    to={`/events/details/${eventData._id}`}
                    className="text-black"
                  >
                    {eventData.title}
                  </Link>
                </h3>
                <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                  <span className="d-block material-symbols-outlined icon-sm">
                    event_note
                  </span>
                  <span className="d-block">{eventData.eventstartdate}</span>
                </p>
                <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                        star
                      </span>
                      <span className="d-block fs-sm fw-bold">
                        {eventData.ratingtotal}
                      </span>
                      <span className="d-block fs-xs">
                        {eventData.reviewtotal}
                      </span>
                    </Link>
                  </li>
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        location_on
                      </span>
                      <span className="d-block">
                        {' '}
                        {eventData.eventlocation}
                      </span>
                    </Link>
                  </li>
                </ul>
                <p className="info text-dark mb-4">{eventData.summary}</p>
                <ul className="event-members d-flex align-items-center mb-3">
                  {eventData.eventmemberlist
                    .slice(0, memberLimit)
                    .map((member, index) => (
                      <li
                        key={index}
                        className="border-1 border-white rounded-circle overflow-hidden"
                      >
                        <img
                          src={
                            member.eventmemberimage === ''
                              ? assetImages.defaultUser
                              : url.SERVER_URL + member.eventmemberimage
                          }
                          alt={`DP ${index + 1}`}
                          className="rounded-circle"
                          height={40}
                          width={40}
                        />
                      </li>
                    ))}

                  {eventData.eventmembercount ? (
                    <li className="more ms-2">
                      {eventData.eventmembercount > memberLimit ? (
                        <Link to="#" className="fs-sm text-gray fw-semibold">
                          +{eventData.eventmembercount - memberLimit} more
                        </Link>
                      ) : null}
                    </li>
                  ) : null}
                </ul>
                <Link
                  to={`/events/details/${eventData._id}`}
                  className="btn btn-secondary w-100"
                >
                  {t('View Details')}
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeEvents;
