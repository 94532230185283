/* eslint-disable */

/* --------------- importing Images here --------------- */
import defaultUser from 'assets/img/default-user.png';
/* --------------- importing SVGs here --------------- */

export default {
  // ------------------ Exporting Images here ------------------
  defaultUser,
  // ------------------ Exporting SVGs here ------------------
};
