import React from 'react';
import { Link } from 'react-router-dom';

const ProjectLists = () => {
  return (
    <section className="projects-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="projects-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              Found <span>253</span> result
            </p>
            <div className="d-flex gap-3">
              <select
                name="showBest"
                id="showBest"
                className="form-select fw-normal bg-white border-0 shadow-none rounded-5 ms-auto"
              >
                <option value="showTheBest" selected disabled>
                  Show the Best
                </option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
                <option value="option4">Option 4</option>
              </select>
              <Link
                to="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-1.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-2.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-3.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-2.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-3.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-1.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-3.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-1.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="project col-md-6 col-lg-4 mb-4">
              <div className="project-content bg-white rounded-10 overflow-hidden shadow-sm">
                <div className="img-container">
                  <Link to="/projects-details">
                    <img
                      src="assets/img/project-2.jpg"
                      alt="The pro grade devices"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-3">
                  <p className="date fw-semibold mb-1">18th Jan 2022</p>
                  <h3 className="mb-4">
                    <Link to="/projects-details" className="text-black">
                      The pro grade devices
                    </Link>
                  </h3>
                  <Link
                    to="/projects-details"
                    className="btn btn-secondary w-100"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ol className="pagination align-items-center gap-2 fw-semibold justify-content-center mt-4">
            <li className="prev me-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_back
                </span>
              </Link>
            </li>
            <li className="page active">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                1
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                2
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                3
              </Link>
            </li>
            <li className="page">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                ...
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="next ms-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_forward
                </span>
              </Link>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default ProjectLists;
