import React from 'react';
import { Link } from 'react-router-dom';

const EventsTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="events-tab-pane"
      role="tabpanel"
      aria-labelledby="events-tab"
      tabIndex="0"
    >
      <div className="events">
        <div className="row">
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
          <div className="event col-md-6 col-lg-4 mb-4">
            <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
              <h3>
                <Link to="#" className="text-black">
                  DTDC Business Meeting
                </Link>
              </h3>
              <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span className="d-block">Saturday, 12 January 2022</span>
              </p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                      star
                    </span>
                    <span className="d-block fs-sm fw-bold">4.5</span>
                    <span className="d-block fs-xs">(32)</span>
                  </Link>
                </li>
                <li className="tag">
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      location_on
                    </span>
                    <span className="d-block">Madrid, Spain</span>
                  </Link>
                </li>
              </ul>
              <p className="info text-dark mb-4">
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
              <ul className="event-members d-flex align-items-center mb-3">
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-1.png"
                    alt="DP 1"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-2.png"
                    alt="DP 2"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-3.png"
                    alt="DP 3"
                    className="img-fluid"
                  />
                </li>
                <li className="border-1 border-white rounded-circle overflow-hidden">
                  <img
                    src="assets/img/dp-4.png"
                    alt="DP 4"
                    className="img-fluid"
                  />
                </li>
                <li className="more ms-2">
                  <Link to="#" className="fs-sm text-gray fw-semibold">
                    +28
                  </Link>
                </li>
              </ul>
              <Link to="#" className="btn btn-secondary w-100">
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsTab;
