/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EventListFilterPopup = ({
  eventTypeList,
  tagList,
  categoryList,
  searchingList,
  interestList,
  resetList,
  setfilterEventCategories,
  setfilterEventInterests,
  setfilterEventSearchings,
  setfilterEventCity,
  setfilterEventCountry,
  setFilterDateRange,
  setFilterEventTags,
  setFilterEventType,
  setFilterEventTitle,
  setFilterRating,
}) => {
  const { t } = useTranslation(); // for translations

  const [fromDate, setfromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [title, settitle] = useState('');
  const [city, setcity] = useState('');
  const [country, setcountry] = useState('');
  const [selectedRating, setSelectedRating] = useState('');
  const [typeList, settypeList] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [interestData, setinterestData] = useState([]);
  const [searchData, setsearchData] = useState([]);
  const [tagData, settagData] = useState([]);

  const handleRatingChange = event => {
    setSelectedRating(event.target.value);
  };

  //function for select type
  const typeSelectionHandler = data => {
    let prevData = typeList;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        'eventtypefilter-' + data.value
      );
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        'eventtypefilter-' + data.value
      );
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }
    settypeList(prevData);
  };

  //function for reset
  const resetSelectType = () => {
    eventTypeList.forEach(item => {
      const checkboxElement = document.getElementById(
        'eventtypefilter-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  //function for select category
  const categorySelectionHandler = data => {
    let prevData = categoryData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        'eventcategoryfilter-' + data.value
      );
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        'eventcategoryfilter-' + data.value
      );
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setcategoryData(prevData);
  };

  //function for reset
  const resetSelectCategory = () => {
    categoryList.forEach(item => {
      const checkboxElement = document.getElementById(
        'eventcategoryfilter-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  //function for select interest
  const interestSelectionHandler = data => {
    let prevData = interestData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById('interestdata-' + data.value);
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        'interestdata-' + data.value
      );
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setinterestData(prevData);
  };

  //function for reset
  const resetSelectInterest = () => {
    interestList.forEach(item => {
      const checkboxElement = document.getElementById(
        'interestdata-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  //function for select searching
  const searchingSelectionHandler = data => {
    let prevData = searchData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById('searchdata-' + data.value);
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById('searchdata-' + data.value);
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setsearchData(prevData);
  };

  //function for reset
  const resetSelectSearching = () => {
    searchingList.forEach(item => {
      const checkboxElement = document.getElementById(
        'searchdata-' + item.value
      );
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  //function for select tag
  const tagSelectionHandler = data => {
    let prevData = tagData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById('tagdata-' + data.value);
      selectedItem.classList.remove('active');
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById('tagdata-' + data.value);
      selectedTagItem.classList.add('active');
      // not exist in array, thus add
      prevData.push(data.value);
    }

    settagData(prevData);
  };

  //function for reset
  const resetSelectedTags = () => {
    tagList.forEach(item => {
      const checkboxElement = document.getElementById('tagdata-' + item.value);
      if (checkboxElement) {
        checkboxElement.checked = false; // Uncheck the checkbox
        checkboxElement.parentElement.classList.remove('active'); // Remove active class
      }
    });
  };

  //function for search
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate !== '' && toDate !== '' && fromDate < toDate) {
      setFilterDateRange(fromDate + ',' + toDate);
    } else if (fromDate !== '') {
      setFilterDateRange(fromDate);
    }

    setFilterEventTitle(title);
    setfilterEventCity(city);
    setfilterEventCountry(country);
    setFilterEventType(typeList);
    setFilterEventTags(tagData);
    setfilterEventCategories(categoryData);
    setfilterEventInterests(interestData);
    setfilterEventSearchings(searchData);
    setFilterRating(selectedRating);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector('#offcanvasFilter');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset
  const resetHandler = () => {
    settoDate('');
    setfromDate('');
    settitle('');
    setcity('');
    setcountry('');
    setSelectedRating('');
    settagData([]);
    settypeList([]);
    setcategoryData([]);
    setinterestData([]);
    setsearchData([]);

    // removeActiveClass();
    resetSelectType();
    resetSelectCategory();
    resetSelectInterest();
    resetSelectSearching();
    resetSelectedTags();
  };

  const reloadList = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector('#offcanvasFilter');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
    resetList();
  };

  return (
    <div
      className="offcanvas offcanvas-start filter-offcanvas bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close body-bg p-0 rounded-5 shadow-none m-0 ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body pt-0">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {/* ------ dates section ------ */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSix"
              >
                {t('Dates')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseSix"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <div className="form-group mb-2">
                  <div className="field-container position-relative">
                    <input
                      type="date"
                      className="form-control fw-normal text-primary rounded-5"
                      placeholder="From date"
                      value={fromDate}
                      onChange={e => setfromDate(e.target.value)}
                    />
                    <span className="d-inline-block material-symbols-outlined icon-md text-primary position-absolute top-50 end-0 translate-middle-y me-3">
                      calendar_month
                    </span>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <div className="field-container position-relative">
                    <input
                      type="date"
                      className="form-control fw-normal text-primary rounded-5"
                      placeholder="To date"
                      value={toDate}
                      onChange={e => settoDate(e.target.value)}
                    />
                    <span className="d-inline-block material-symbols-outlined icon-md text-primary position-absolute top-50 end-0 translate-middle-y me-3">
                      calendar_month
                    </span>
                  </div>
                </div>
                {/* <div className="action">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 rounded-5"
                  >
                    Go
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          {/* ------ title section ------ */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Title')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder={t('Enter Title')}
                    value={title}
                    onChange={e => settitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ------- city section ---------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('City')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder={t('Enter City')}
                    value={city}
                    onChange={e => setcity(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------- country section ---------- */}

          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                Country
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    className="form-control fw-normal text-primary rounded-5"
                    placeholder={t('Enter Country')}
                    value={country}
                    onChange={e => setcountry(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* -------- rating -------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Rating')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="form-group mb-2">
                <div className="field-container position-relative">
                  <ul className="list_stye_none filter_rating">
                    {[5, 4, 3, 2, 1].map(rating => (
                      <li key={rating}>
                        <label className="radio-check">
                          <input
                            type="radio"
                            name="rdo3"
                            value={rating}
                            checked={selectedRating === String(rating)}
                            onChange={handleRatingChange}
                          />

                          <p>
                            {rating}{' '}
                            <i className="material-icons-outlined">
                              {t('star')}
                            </i>{' '}
                            {t('& above')}
                          </p>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* --------- event type --------------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Event Type')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {eventTypeList?.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => typeSelectionHandler(item)}
                          id={'eventtypefilter-' + item.value}
                        />
                        <label
                          htmlFor={'eventtypefilter-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* --------------- category section ------------ */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Category')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {categoryList?.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => categorySelectionHandler(item)}
                          id={'eventcategoryfilter-' + item.value}
                        />
                        <label
                          htmlFor={'eventcategoryfilter-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* -------------- interest section ------------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Interests')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {interestList?.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => interestSelectionHandler(item)}
                          id={'interestdata-' + item.value}
                        />
                        <label
                          htmlFor={'interestdata-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* ------------ searchings section ------------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Searchings')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {searchingList?.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => searchingSelectionHandler(item)}
                          id={'searchdata-' + item.value}
                        />
                        <label
                          htmlFor={'searchdata-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* ------------ label/tag section ------------- */}
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {t('Tags')}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  {tagList?.map((item, index) => {
                    return (
                      <li className="tag" key={index}>
                        <input
                          type="checkbox"
                          className="d-none"
                          onClick={() => tagSelectionHandler(item)}
                          id={'tagdata-' + item.value}
                        />
                        <label
                          htmlFor={'tagdata-' + item.value}
                          className="px-3 py-2 text-primary border border-bg rounded-5"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* --------- buttons -------------- */}
          <div
            className="action"
            onClick={() => {
              reloadList();
            }}
          >
            <button type="submit" className="btn btn-primary rounded-5">
              {t('Reset')}
            </button>
          </div>
          <div className="action" onClick={searchHandler}>
            <button type="submit" className="btn btn-primary rounded-5">
              {t('Show Result')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventListFilterPopup;
