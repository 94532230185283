import React from 'react';

const ProcessTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="process-tab-pane"
      role="tabpanel"
      aria-labelledby="process-tab"
      tabIndex="0"
    >
      <div className="process-container p-4 bg-white rounded-10 shadow-sm">
        <ul className="process">
          <li className="step position-relative completed">
            <h6 className="text-primary mb-2">HR Interview</h6>
            <ul className="tags d-flex flex-wrap gap-2 fs-sm text-primary mb-3">
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span className="d-block">San Deago,CA USA</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  event_note
                </span>
                <span className="d-block">01/09/2021 - 30/09/2021</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  person
                </span>
                <span className="d-block">15 Participants</span>
              </li>
            </ul>
            <p className="fs-sm text-secondary mb-2">KPIs for evaluation</p>
            <ul className="points">
              <li>Academic Qualification</li>
              <li>Experience</li>
              <li>MEAN / MERN</li>
              <li>MongoDB / MySQL</li>
              <li>PHP</li>
              <li>Bootstrap</li>
              <li>Git</li>
            </ul>
          </li>
          <li className="step position-relative started">
            <h6 className="mb-2">Technical Interview</h6>
            <ul className="tags d-flex flex-wrap gap-2 fs-sm text-primary mb-3">
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span className="d-block">San Deago,CA USA</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  event_note
                </span>
                <span className="d-block">01/09/2021 - 30/09/2021</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  person
                </span>
                <span className="d-block">15 Participants</span>
              </li>
            </ul>
            <p className="fs-sm text-secondary mb-2">KPIs for evaluation</p>
            <ul className="points">
              <li>Academic Qualification</li>
              <li>Experience</li>
              <li>MEAN / MERN</li>
              <li>MongoDB / MySQL</li>
              <li>PHP</li>
              <li>Bootstrap</li>
              <li>Git</li>
            </ul>
          </li>
          <li className="step position-relative">
            <h6 className="mb-2">Document Verification</h6>
            <ul className="tags d-flex flex-wrap gap-2 fs-sm text-primary">
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span className="d-block">San Deago,CA USA</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  event_note
                </span>
                <span className="d-block">01/09/2021 - 30/09/2021</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  person
                </span>
                <span className="d-block">15 Participants</span>
              </li>
            </ul>
          </li>
          <li className="step position-relative">
            <h6 className="mb-2">Joining</h6>
            <ul className="tags d-flex flex-wrap gap-2 fs-sm text-primary">
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span className="d-block">San Deago,CA USA</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  event_note
                </span>
                <span className="d-block">01/09/2021 - 30/09/2021</span>
              </li>
              <li className="tag d-flex gap-1 bg-gray-100 px-2 py-1 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  person
                </span>
                <span className="d-block">15 Participants</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProcessTab;
