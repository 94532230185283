import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const DetailsTab = ({
  requiredSkills,
  optionalSkills,
  summary,
  details,
  aboutPosition,
  aboutCompany,
  minSalary,
  maxSalary,
  isApplied,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div
      className="tab-pane fade show active"
      id="details-tab-pane"
      role="tabpanel"
      aria-labelledby="details-tab"
      tabIndex="0"
    >
      <div className="details bg-white p-4 rounded-10 shadow-sm mb-3">
        <h6>{t('Summary')}</h6>
        <p>{summary}</p>

        <h6>{t('Details')}</h6>
        <p dangerouslySetInnerHTML={{ __html: details }} />

        <h6>{t('About Position')}</h6>
        <p dangerouslySetInnerHTML={{ __html: aboutPosition }} />

        <h6>{t('About Company')}</h6>
        <p dangerouslySetInnerHTML={{ __html: aboutCompany }} />

        <h6>{t('Salary and compensation')}</h6>
        <p>
          €{minSalary} — €{maxSalary}
        </p>

        <Link to="#" className="btn btn-secondary mt-4">
          {t('Apply Now')}
        </Link>
      </div>
    </div>
  );
};

export default DetailsTab;
