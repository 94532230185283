import React from 'react';

const SuccessStoriesFilterPopup = () => {
  return (
    <div
      className="offcanvas offcanvas-start filter-offcanvas bg-white border-0"
      tabindex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close body-bg p-0 rounded-5 shadow-none m-0 ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body pt-0">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                Área
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  <li className="tag">
                    <input type="checkbox" id="derecho" className="d-none" />
                    <label
                      for="derecho"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Derecho
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="finanzas" className="d-none" />
                    <label
                      for="finanzas"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Finanzas
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="tecnología" className="d-none" />
                    <label
                      for="tecnología"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Tecnología
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                Disciplinas
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  <li className="tag">
                    <input type="checkbox" id="innovación" className="d-none" />
                    <label
                      for="innovación"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Innovación
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="arte" className="d-none" />
                    <label
                      for="arte"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Arte
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="diseño" className="d-none" />
                    <label
                      for="diseño"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Diseño
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseThree"
              >
                Target
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  <li className="tag">
                    <input type="checkbox" id="ceo" className="d-none" />
                    <label
                      for="ceo"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      CEO
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="directorGeneral"
                      className="d-none"
                    />
                    <label
                      for="directorGeneral"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Director general
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="directorHr" className="d-none" />
                    <label
                      for="directorHr"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Director HR
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFour"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFour"
              >
                Tipo de formación
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseFour"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <ul className="tags d-flex flex-wrap gap-2">
                  <li className="tag">
                    <input type="checkbox" id="grados" className="d-none" />
                    <label
                      for="grados"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Grados{' '}
                      <span className="fs-sm fw-semibold text-orange">10</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="másteres" className="d-none" />
                    <label
                      for="másteres"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Másteres{' '}
                      <span className="fs-sm fw-semibold text-orange">20</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="programasDirectivos"
                      className="d-none"
                    />
                    <label
                      for="programasDirectivos"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Programas directivos{' '}
                      <span className="fs-sm fw-semibold text-orange">4</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input type="checkbox" id="cursos" className="d-none" />
                    <label
                      for="cursos"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Cursos{' '}
                      <span className="fs-sm fw-semibold text-orange">5</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="programasDeEspecialización"
                      className="d-none"
                    />
                    <label
                      for="programasDeEspecialización"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Programas de especialización{' '}
                      <span className="fs-sm fw-semibold text-orange">21</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="cursosSuperiores"
                      className="d-none"
                    />
                    <label
                      for="cursosSuperiores"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Cursos superiores{' '}
                      <span className="fs-sm fw-semibold text-orange">8</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="summerSchool"
                      className="d-none"
                    />
                    <label
                      for="summerSchool"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Summer School{' '}
                      <span className="fs-sm fw-semibold text-orange">8</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="acreditaciones"
                      className="d-none"
                    />
                    <label
                      for="acreditaciones"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Acreditaciones{' '}
                      <span className="fs-sm fw-semibold text-orange">5</span>
                    </label>
                  </li>
                  <li className="tag">
                    <input
                      type="checkbox"
                      id="certificaciones"
                      className="d-none"
                    />
                    <label
                      for="certificaciones"
                      className="px-3 py-2 text-primary border border-bg rounded-5"
                    >
                      Certificaciones{' '}
                      <span className="fs-sm fw-semibold text-orange">9</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFive"
              >
                Precios
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseFive"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <div className="form-group d-flex gap-2">
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      className="form-control fw-normal text-primary rounded-5"
                      placeholder="Min"
                    />
                    <span className="d-inline-block material-symbols-outlined icon-md text-primary position-absolute top-50 end-0 translate-middle-y me-3">
                      euro
                    </span>
                  </div>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      className="form-control fw-normal text-primary rounded-5"
                      placeholder="Max"
                    />
                    <span className="d-inline-block material-symbols-outlined icon-md text-primary position-absolute top-50 end-0 translate-middle-y me-3">
                      euro
                    </span>
                  </div>
                  <div className="action">
                    <button type="submit" className="btn btn-primary rounded-5">
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSix"
              >
                Dates
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseSix"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <div className="form-group mb-2">
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      className="form-control fw-normal text-primary rounded-5"
                      placeholder="Empieza desde"
                    />
                    <span className="d-inline-block material-symbols-outlined icon-md text-primary position-absolute top-50 end-0 translate-middle-y me-3">
                      calendar_month
                    </span>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      className="form-control fw-normal text-primary rounded-5"
                      placeholder="Hasta"
                    />
                    <span className="d-inline-block material-symbols-outlined icon-md text-primary position-absolute top-50 end-0 translate-middle-y me-3">
                      calendar_month
                    </span>
                  </div>
                </div>
                <div className="action">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 rounded-5"
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSeven"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSeven"
              >
                Formato
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseSeven"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <div className="form-group">
                  <select
                    name="formato"
                    id="formato"
                    className="form-select text-primary rounded-5 shadow-none"
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="option4">Option 4</option>
                    <option value="option5">Option 5</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0 rounded-0">
            <div className="accordion-header">
              <button
                className="accordion-button p-0 pb-3 bg-transparent text-black rounded-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseEight"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseEight"
              >
                Requisitos
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseEight"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0">
                <div className="form-group">
                  <select
                    name="requisitos"
                    id="requisitos"
                    className="form-select text-primary rounded-5 shadow-none"
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="option4">Option 4</option>
                    <option value="option5">Option 5</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesFilterPopup;
