/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { Link } from 'react-router-dom';

import Select from 'react-select';
import sorting from 'Data/Sorting.json';

import { useTranslation } from 'react-i18next';
import ChallengesFilterPopup from 'Components/ChallengesComponents/Popups/ChallengesFilterPopup';
import ChallengeListCard from '../ChallengeListCard/ChallengeListCard';
import ChallengeLandingRightPanel from '../ChallengeLandingRightPanel/ChallengeLandingRightPanel';

const ChallengesLists = () => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');

  // const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  const [selectedJobId, setselectedJobId] = useState(null);

  //function for get project list
  const getAllChallenges = async () => {
    // setIsLoading(true);
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_CHALLENGE}?token=${token}&userrolestring=${userInfo.role.name}`;

      // requestUrl +=
      //   filterDateRange != "" ? `&filterdates=${filterDateRange}` : "";

      // if (filterTitle != "") {
      //   requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      // }
      // if (filterTags.length > 0) {
      //   requestUrl = requestUrl + `&filtertags=${filterTags}`;
      // }
      // if (filterMod) {
      //   requestUrl = requestUrl + `&filtermod=${filterMod}`;
      // }

      console.log(requestUrl);

      const response = await getData(requestUrl);
      // setIsLoading(false);

      console.log(response);

      if (response.status) {
        setJobList(response.data);
        if (response.data.length > 0) {
          setselectedJobId(response.data[0]._id);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // ------ function for select job --------
  const onJobSelect = id => {
    setselectedJobId(id);
  };

  useEffect(() => {
    getAllChallenges();
  }, []);
  return (
    <section className="challenges-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="challenges-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              {t('Found')} <span>{jobList.length}</span> {t('result')}
            </p>
            <div className="d-flex gap-3">
              <Select
                placeholder={t('Select Position')}
                options={sorting}
                // value={params}
                // onChange={val => {
                //   setParams(val);
                // }}
              />
              <Link
                to="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            {/* ----- Challenge List Card -----  */}
            <ChallengeListCard jobList={jobList} onJobSelect={onJobSelect} />
            {/* ----- Challenge landing right panel ----- */}
            {selectedJobId && (
              <ChallengeLandingRightPanel selectedJobId={selectedJobId} />
            )}
          </div>
        </div>
      </div>

      {/* -------- filter popup ---------- */}
      <ChallengesFilterPopup />
    </section>
  );
};

export default ChallengesLists;
