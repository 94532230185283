/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { postData } from 'utils/Gateway';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReviewModal = ({ moduleName }) => {
  const { t } = useTranslation(); // for translations
  const token = localStorage.getItem('token');

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState('');

  // initial useState to save star rating
  const [starRating, setstarRating] = useState('1');

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);
  // const [ratingError, setRatingError] = useState(false);
  // const [commentError, setCommentError] = useState(false);
  const [isReviewPosting, setIsReviewPosting] = useState(false);

  /*Initial useStates for activating the starts end*/

  // function to display the star ratings
  const highlightStars = (e, value) => {
    e.preventDefault();

    setActiveFirstStar(false);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);

    if (value === '1') {
      setActiveFirstStar(true);
    }

    if (value === '2') {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
    }

    if (value === '3') {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
    }

    if (value === '4') {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
    }

    if (value === '5') {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };

  // Function to select the star ratings from the stars
  const selectedStar = (e, star) => {
    e.preventDefault();
    setstarRating(star);
  };

  // Function to submit the review to the API.
  const reviewSubmitHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_SUBMIT_REVIEW +
        `?token=${token}&moduleslug=${moduleName}`;

      let reviewData = {
        comment: comments,
        rating: starRating,
      };

      if (moduleName === 'company') {
        reviewData.companyid = params.id;
      } else if (moduleName === 'appuser') {
        reviewData.expertid = params.id;
      } else if (moduleName === 'event') {
        reviewData.eventid = params.id;
      } else if (moduleName === 'group') {
        reviewData.groupid = params.id;
      } else if (moduleName === 'course') {
        reviewData.courseid = params.id;
      } else {
        console.log('Module not found');
      }

      setIsReviewPosting(true);

      // console.log("ReviewData---->", reviewData);

      const response = await postData(requestURL, reviewData);

      setIsReviewPosting(false);

      // console.log(response);

      if (response.status) {
        resetHandler();

        //hide modal
        let reviewModal = document.querySelector('#feedback_mdl');
        let modal = bootstrap.Modal.getInstance(reviewModal);
        modal.hide();

        //display success modal
        let greetingModal = new bootstrap.Modal(
          document.getElementById('gretings_mdl')
        );
        greetingModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments('');
    setstarRating('1');
  };
  return (
    <div className="modal fade" id="feedback_mdl">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header justify-content-between border-0 p-4 pb-0">
            <h3 className="modal-title fs-xl text-dark fw-semibold">
              {t('Feedback')}
            </h3>
            <button
              type="button"
              className="btn-close bg-gray-200 text-black rounded-circle shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="course_rvw_row form-group mb-3">
              <div className="rating_hdng">
                <h5 className="fs-md fw-semibold mb-2 text-dark">
                  {t('Give Ratings')}
                </h5>
              </div>
              <ul className="list-style-none d-flex align-items-center">
                {/* ----------- 1 Star ----------- */}
                <li>
                  <Link
                    to="#"
                    onClick={e => {
                      selectedStar(e, '1');
                      highlightStars(e, '1');
                    }}
                  >
                    <span
                      className={`material-symbols-outlined icon-fill fs-xl ${
                        activeFirstStar ? 'text-primary' : 'text-light'
                      }`}
                    >
                      star
                    </span>
                  </Link>
                </li>

                {/* ----------- 2 Star ----------- */}
                <li>
                  <Link
                    to="#"
                    onClick={e => {
                      selectedStar(e, '2');
                      highlightStars(e, '2');
                    }}
                  >
                    <span
                      className={`material-symbols-outlined icon-fill fs-xl ${
                        activeSecondStar ? 'text-primary' : 'text-light'
                      }`}
                    >
                      star
                    </span>
                  </Link>
                </li>

                {/* ----------- 3 Star ----------- */}
                <li>
                  <Link
                    to="#"
                    onClick={e => {
                      selectedStar(e, '3');
                      highlightStars(e, '3');
                    }}
                  >
                    <span
                      className={`material-symbols-outlined icon-fill fs-xl ${
                        activeThirdStar ? 'text-primary' : 'text-light'
                      }`}
                    >
                      star
                    </span>
                  </Link>
                </li>

                {/* ----------- 4 Star ----------- */}
                <li>
                  <Link
                    to="#"
                    onClick={e => {
                      selectedStar(e, '4');
                      highlightStars(e, '4');
                    }}
                  >
                    <span
                      className={`material-symbols-outlined icon-fill fs-xl ${
                        activeFourthStar ? 'text-primary' : 'text-light'
                      }`}
                    >
                      star
                    </span>
                  </Link>
                </li>

                {/* ----------- 5 Star ----------- */}
                <li>
                  <Link
                    to="#"
                    onClick={e => {
                      selectedStar(e, '5');
                      highlightStars(e, '5');
                    }}
                  >
                    <span
                      className={`material-symbols-outlined icon-fill fs-xl ${
                        activeFiveStar ? 'text-primary' : 'text-light'
                      }`}
                    >
                      star
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="form-group mb-3">
              <label className="fs-md text-dark fw-semibold mb-2">
                {t('Comments')}
              </label>
              <textarea
                className="form-control"
                rows="6"
                placeholder={t('Enter comments')}
                value={comments}
                onChange={e => setComments(e.target.value)}
              ></textarea>
            </div>
            <div className="post_btn form-group">
              <button
                className="btn btn-primary w-100"
                // data-bs-toggle="modal"
                // data-bs-dismiss="modal"
                // data-bs-target="#gretings_mdl"
                disabled={isReviewPosting ? true : false}
                onClick={reviewSubmitHandler}
              >
                {t('Post Review')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
