/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';

/*------ import url and method ------*/
import * as url from '../../helper/UrlHelper';
import { postData } from '../../utils/Gateway';

import {
  // getUserBrowser,
  getPublicIP,
  // getTimeStamp,
} from '../../helper/AuthHelper/AuthHelper';

//import context
// import { GlobalProvider } from '../../context/GlobalContext';
import { ecosystemSlug } from '../../Config/Config';
import { Link, useHistory } from 'react-router-dom';
// import { GlobalProvider } from '../../context/GlobalContext';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation(); // for translations

  const history = useHistory();
  // const {
  //   setcreateAccountMessage,
  //   // setuserEmailforOtp,
  //   // userEmailforOtp,
  //   // verifyModuleName,
  //   // setVerifyModuleName,
  // } = useContext(GlobalProvider);
  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const refParam = searchParams.get('ref');
  const secretParam = searchParams.get('secret');

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);
  /*----- UseState to send username and password for Login ----*/
  const [otpEmail, setOtpEmail] = useState('');
  const [otpEmailWarning, setOtpEmailWarning] = useState(false);
  const [notValidOtpEmail, setNotValidOtpEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState(false);
  // const [isAgree, setIsAgree] = useState(false);
  // const [agreeWarning, setAgreeWarning] = useState(false);
  const [otpSending, setotpSending] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  // const [loginFailed, setLoginFailed] = useState(false);
  const [activeTab, setActiveTab] = useState('password');
  const [showOtpInput, setShowOtpInput] = useState(false);
  // const [errorMessage, seterrorMessage] = useState('');
  const [verifyModuleName, setVerifyModuleName] = useState('');
  const [userEmailforOtp, setuserEmailforOtp] = useState('');
  // const [createAccountMessage, setcreateAccountMessage] = useState('');

  const [firstCode, setFirstCode] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [thirdCode, setThirdCode] = useState('');
  const [fourthCode, setFourthCode] = useState('');
  const [otpFailedMessage, setotpFailedMessage] = useState('');
  // const [otpSending, setotpSending] = useState(false);
  const [otpResendMessage, setotpResendMessage] = useState('');

  const handleTabChange = tab => {
    setActiveTab(tab);
    setShowOtpInput(false);
  };

  // function for change text type
  // const toggleText = () => {
  //   setShowPassword(!showPassword);
  // };

  //otp function
  const otpHandler = async () => {
    if (otpEmail === '') {
      setOtpEmailWarning(true);
    } else {
      setOtpEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (otpEmail.match(mailformat)) {
        setNotValidOtpEmail(false);
        try {
          setotpSending(true);
          const userPublicIp = await getPublicIP();

          let userData = {
            userip: userPublicIp,
            email: otpEmail,
          };
          let requestURL =
            url.API_BASE_URL +
            url.API_GET_OTP +
            `?ecosystemslug=${ecosystemSlug}`;

          const response = await postData(requestURL, userData);

          console.log(response);

          setotpSending(false);

          if (response.status) {
            // seterrorMessage('');
            // setcreateAccountMessage('');
            setuserEmailforOtp(otpEmail);
            setVerifyModuleName('OTP');
            //  history.push('/admin/verifyemail');
            setShowOtpInput(true);
          } else {
            setuserEmailforOtp('');
            //  setVerifyModuleName('');
            // setcreateAccountMessage(response.message);
            // seterrorMessage(response.message);
            setShowOtpInput(false);
          }
          setUserEmail('');
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setNotValidOtpEmail(true);
      }
    }
  };

  //login function
  const loginHandler = async e => {
    e.preventDefault();
    switch (true) {
      case userEmail === '' || userPassword === '':
        userEmail === '' ? setEmailWarning(true) : setEmailWarning(false);
        userPassword === ''
          ? setPasswordWarning(true)
          : setPasswordWarning(false);
        // isAgree==false?setAgreeWarning(true):setAgreeWarning(false)
        break;
      default:
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (userEmail.match(mailformat)) {
          setNotValidEMail(false);
          // setIsLoading(true);
          try {
            let userData = {
              email: userEmail,
              password: userPassword,
            };
            let requestURL =
              url.API_BASE_URL +
              url.API_LOGIN +
              `?ecosystemslug=${ecosystemSlug}`;

            console.log('request url -->', requestURL);

            const response = await postData(requestURL, userData);

            console.log('response -->', response);

            if (response.status) {
              // setLoginFailed(false);
              // assigning the loggedin user's id,token,all data to the browser's local storage.
              localStorage.setItem('token', response.token);
              localStorage.setItem('userInfo', JSON.stringify(response.data));
              localStorage.setItem('userID', JSON.stringify(response.data._id));
              localStorage.setItem('ecosystem', response.ecosystemid);
              localStorage.setItem('ecosystemlogo', response.ecosystemlogo);
              localStorage.setItem('ecosystemslug', response.ecosystemslug);
              localStorage.setItem(
                'moduleaccess',
                response.data.moduleaccessdata
              );
              resetLoginData();
              window.location.reload();
            } else {
              // setLoginFailed(true);
            }
            // setIsLoading(false);
          } catch (error) {
            console.error(error.message);
          }
        } else {
          setNotValidEMail(true);
        }
    }
  };

  // const loginHandler = async e => {
  //   e.preventDefault();

  //   if (userEmail === '' || userPassword === '') {
  //     setEmailWarning(userEmail === '');
  //     setPasswordWarning(userPassword === '');
  //     return;
  //   }

  //   const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   if (!userEmail.match(mailformat)) {
  //     setNotValidEMail(true);
  //     return;
  //   }

  //   setIsLoading(true);
  //   try {
  //     let userData = {
  //       email: userEmail,
  //       password: userPassword,
  //     };
  //     let requestURL = `${url.API_BASE_URL}${url.API_LOGIN}?ecosystemslug=${ecosystemSlug}`;

  //     const response = await postData(requestURL, userData);

  //     if (response.status) {
  //       setLoginFailed(false);

  //       localStorage.setItem('token', response.token);
  //       localStorage.setItem('userInfo', JSON.stringify(response.data));
  //       localStorage.setItem('userID', JSON.stringify(response.data._id));
  //       localStorage.setItem('ecosystem', response.ecosystemid);
  //       localStorage.setItem('ecosystemlogo', response.ecosystemlogo);
  //       localStorage.setItem('ecosystemslug', response.ecosystemslug);
  //       localStorage.setItem('moduleaccess', response.data.moduleaccessdata);

  //       resetLoginData();

  //       setTimeout(() => {
  //         window.location.href = window.location.href;
  //       }, 100);
  //     } else {
  //       setLoginFailed(true);
  //     }
  //   } catch (error) {
  //     console.error(error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // function for reset the useState containing the email and password text fields
  const resetLoginData = () => {
    setOtpEmail('');
    setOtpEmailWarning(false);
    setNotValidOtpEmail(false);
    setUserEmail('');
    setUserPassword('');
    setEmailWarning(false);
    setNotValidEMail(false);
    setPasswordWarning(false);
    // setLoginFailed(false);
    // setcreateAccountMessage('');
    setuserEmailforOtp('');
  };

  // function for backspace reference second digit
  const secondCodeKey = e => {
    if (e.key === 'Backspace') {
      if (secondCode === '' && secondCode.length === 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = e => {
    if (e.key === 'Backspace') {
      if (thirdCode === '' && thirdCode.length === 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = e => {
    if (e.key === 'Backspace') {
      if (fourthCode === '' && fourthCode.length === 0) {
        code3Ref.current?.focus();
      }
    }
  };

  //otp login function
  const validateOtpHandler = async () => {
    try {
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      let userData = {
        email: userEmailforOtp,
        otp: finalCode,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_VALIDATE_OTP +
        `?ecosystemslug=${ecosystemSlug}`;

      // console.log("requestURL", requestURL);
      // console.log("userData", userData);

      const response = await postData(requestURL, userData);

      console.log(response);

      if (response.status) {
        setotpFailedMessage('');

        if (verifyModuleName === 'OTP') {
          // assigning the loggedin user's id,token,all data to the browser's local storage.
          localStorage.setItem('token', response.token);
          localStorage.setItem('userInfo', JSON.stringify(response.data));
          localStorage.setItem('userID', JSON.stringify(response.data._id));
          localStorage.setItem('ecosystem', response.ecosystemid);
          localStorage.setItem('ecosystemlogo', response.ecosystemlogo);
          localStorage.setItem('ecosystemslug', response.ecosystemslug);
          localStorage.setItem('moduleaccess', response.data.moduleaccessdata);

          if (refParam) {
            window.location.href = `${refParam}?secret=${secretParam}`;
          } else {
            window.location.reload();
          }
        } else if (verifyModuleName === 'FORGOT_PASS') {
          resetOtpData();
          history.push('/forgotpassword');
        } else {
          console.log('No module selected');
        }
      } else {
        setotpFailedMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //otp resend function
  const resendOtpHandler = async () => {
    try {
      setotpSending(true);
      let userData = {
        email: userEmailforOtp,
      };
      let requestURL = url.API_BASE_URL + url.API_GET_OTP;

      const response = await postData(requestURL, userData);

      console.log(response);

      setotpSending(false);

      if (response.status) {
        setotpResendMessage(t('We have sent OTP in your email'));
      } else {
        setotpResendMessage('');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset function
  const resetOtpData = () => {
    setotpFailedMessage(false);
    // setuserEmailforOtp("");
  };

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== '' && firstCode.length === 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== '' && secondCode.length === 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== '' && thirdCode.length === 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  useEffect(() => {
    if (fourthCode !== '') {
      validateOtpHandler();
    }
  }, [fourthCode]);

  return (
    // <div className="d-flex justify-content-center align-items-center vh-100">
    //   <div className="card p-4" style={{ width: '20rem' }}>
    //     <h3 className="text-center mb-4">Login</h3>
    //     <form>
    //       <div className="mb-3">
    //         <label htmlFor="email" className="form-label">
    //           Email address
    //         </label>
    //         <input
    //           type="email"
    //           className="form-control"
    //           id="email"
    //           placeholder="Enter your email"
    //           value={userEmail}
    //           onChange={e => {
    //             setEmailWarning(false);
    //             setNotValidEMail(false);
    //             // setLoginFailed(false);
    //             setUserEmail(e.target.value);
    //           }}
    //         />
    //         {/* ------ email warning start ------ */}
    //         {emailWarning && (
    //           <p className="error">
    //             <span className="material-icons-outlined">info</span>
    //             Please enter email
    //           </p>
    //         )}
    //         {/* ----- valid email warning ------ */}
    //         {notValidEMail && (
    //           <p className="error">
    //             <span className="material-icons-outlined">info</span>
    //             Please enter valid email
    //           </p>
    //         )}
    //         {/* ------ email warning end ------ */}
    //       </div>
    //       <div className="mb-3">
    //         <label htmlFor="password" className="form-label">
    //           Password
    //         </label>
    //         <input
    //           type="password"
    //           className="form-control"
    //           id="password"
    //           placeholder="Enter your password"
    //           value={userPassword}
    //           onChange={e => {
    //             setPasswordWarning(false);
    //             // setLoginFailed(false);
    //             setUserPassword(e.target.value);
    //           }}
    //         />
    //         {passwordWarning && (
    //           <p className="error">
    //             <span className="material-icons-outlined">info</span>
    //             Please enter password
    //           </p>
    //         )}
    //       </div>
    //       <button
    //         onClick={loginHandler}
    //         type="submit"
    //         className="btn btn-primary w-100"
    //       >
    //         Login
    //       </button>
    //     </form>
    //   </div>
    // </div>

    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4" style={{ width: '20rem' }}>
        <h3 className="text-center mb-4">Login</h3>

        <ul className="nav nav-tabs nav-fill mb-3">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'password' ? 'active' : ''}`}
              onClick={() => handleTabChange('password')}
            >
              Password
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'otp' ? 'active' : ''}`}
              onClick={() => handleTabChange('otp')}
            >
              OTP
            </button>
          </li>
        </ul>

        {activeTab === 'password' ? (
          <form>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                value={userEmail}
                onChange={e => {
                  setEmailWarning(false);
                  setNotValidEMail(false);
                  // setLoginFailed(false);
                  setUserEmail(e.target.value);
                }}
              />
              {/* ------ email warning start ------ */}
              {emailWarning && (
                <p className="error">
                  <span className="material-icons-outlined">info</span>
                  Please enter email
                </p>
              )}
              {/* ----- valid email warning ------ */}
              {notValidEMail && (
                <p className="error">
                  <span className="material-icons-outlined">info</span>
                  Please enter valid email
                </p>
              )}
              {/* ------ email warning end ------ */}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter your password"
                value={userPassword}
                onChange={e => {
                  setUserPassword(e.target.value);
                  setPasswordWarning(false);
                }}
              />
              {passwordWarning && (
                <p className="error">
                  <span className="material-icons-outlined">info</span>
                  Please enter password
                </p>
              )}
            </div>

            <button
              type="submit"
              className="btn btn-primary w-100"
              onClick={loginHandler}
            >
              Login
            </button>
            <Link to="/forgotpassword" className="btn btn-primary w-100 mt-2">
              Forgot Password
            </Link>
            <Link to="/register" className="btn btn-secondary w-100 mt-2">
              Create one
            </Link>
          </form>
        ) : (
          <div>
            <div className="mb-3">
              <label htmlFor="otpEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="otpEmail"
                placeholder="Enter your email"
                value={otpEmail}
                onChange={e => {
                  setOtpEmailWarning(false);
                  setNotValidOtpEmail(false);
                  // setLoginFailed(false);
                  setOtpEmail(e.target.value);
                }}
              />
              {/* ------ email warning start ------ */}
              {otpEmailWarning && (
                <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                  <span className="d-block material-symbols-outlined icon-md">
                    info
                  </span>
                  <span className="d-block">{t('Please enter email')}</span>
                </div>
              )}
              {/* ----- valid email warning ------ */}
              {notValidOtpEmail && (
                <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                  <span className="d-block material-symbols-outlined icon-md">
                    info
                  </span>
                  <span className="d-block">
                    {t('Please enter valid email')}
                  </span>
                </div>
              )}
            </div>
            {!showOtpInput && (
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={otpHandler}
                disabled={otpSending ? true : false}
                style={{
                  cursor: otpSending ? 'not-allowed' : 'pointer',
                }}
              >
                Get OTP
                {otpSending && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            )}

            <Link to="/register" className="btn btn-secondary w-100 mt-2">
              Create one
            </Link>
            {showOtpInput && (
              <div className="mt-3">
                <p>Email: {otpEmail}</p>
                <div className="d-flex justify-content-between">
                  <input
                    type="number"
                    ref={code1Ref}
                    value={firstCode}
                    onChange={e => {
                      setotpFailedMessage(false);
                      setotpResendMessage('');
                      setFirstCode(e.target.value.slice(0, e.target.maxLength));
                    }}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code2Ref}
                    value={secondCode}
                    onChange={e => {
                      setotpFailedMessage(false);
                      setotpResendMessage('');
                      setSecondCode(
                        e.target.value.slice(0, e.target.maxLength)
                      );
                    }}
                    onKeyDown={secondCodeKey}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code3Ref}
                    value={thirdCode}
                    onChange={e => {
                      setotpFailedMessage(false);
                      setotpResendMessage('');
                      setThirdCode(e.target.value.slice(0, e.target.maxLength));
                    }}
                    onKeyDown={thirdCodeKey}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code4Ref}
                    value={fourthCode}
                    onChange={e => {
                      setotpFailedMessage(false);
                      setotpResendMessage('');
                      setFourthCode(
                        e.target.value.slice(0, e.target.maxLength)
                      );
                    }}
                    onKeyDown={fourthCodeKey}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                </div>

                {otpFailedMessage === '' ? null : (
                  <div className="signin_hdng">
                    <span className="email_info">{otpFailedMessage}.</span>
                  </div>
                )}

                {otpResendMessage === '' ? null : (
                  <div className="signin_hdng">
                    <span className="email_info">{otpResendMessage}.</span>
                  </div>
                )}
                {/* <button type="button" className="btn btn-primary w-100 mt-2">
                  Login
                </button> */}
                <button
                  type="button"
                  className="btn btn-link w-100 mt-2"
                  onClick={resendOtpHandler}
                  disabled={otpSending ? true : false}
                  style={{
                    cursor: otpSending ? 'not-allowed' : 'pointer',
                  }}
                >
                  Resend OTP
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
