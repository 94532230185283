// /* eslint-disable */
import React from 'react';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import Footer from 'Components/Common/Footer/Footer';
import AccountDetailsBodyComponent from 'Components/AccountComponents/AccountsDetailsComponents/AccountDetailsBodyComponent/AccountDetailsBodyComponent';


const AccountsDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="accounts" />

      
      <AccountDetailsBodyComponent />

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default AccountsDetails;
