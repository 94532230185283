import React from 'react';
import OverviewTab from '../../Tabs/OverviewTab/OverviewTab';
import ServicesTab from '../../Tabs/ServicesTab/ServicesTab';
import ChallengesTab from '../../Tabs/ChallengesTab/ChallengesTab';
import PeojectsTab from '../../Tabs/PeojectsTab/PeojectsTab';
import TeamTab from '../../Tabs/TeamTab/TeamTab';
import SuccessStoriesTab from '../../Tabs/SuccessStoriesTab/SuccessStoriesTab';
import KPIsTab from '../../Tabs/KPIsTab/KPIsTab';
import { Link } from 'react-router-dom';
import ReviewTab from '../../../Common/Tabs/ReviewTab';
import { useTranslation } from 'react-i18next';

const CompaniesDetailsRightComponent = ({
  companyDetails,
  companyServices,
  companySearchings,
  companyInterests,
  companyDocs,
  companyMemberList,
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div className="col-lg-8">
      <div className="company-details-container">
        <ul
          className="nav nav-tabs gap-2 border-0 mb-3"
          id="projectTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90 active"
              id="about-tab"
              data-bs-toggle="tab"
              data-bs-target="#about-tab-pane"
              type="button"
              role="tab"
              aria-controls="about-tab-pane"
              aria-selected="true"
            >
              {t('Overview')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="services-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#services-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="services-tab-pane"
                    aria-selected="false"
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="challenges-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#challenges-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="challenges-tab-pane"
                    aria-selected="false"
                  >
                    Challenges
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="projects-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#projects-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="projects-tab-pane"
                    aria-selected="false"
                  >
                    Projects
                  </Link>
                </li> */}
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="people-tab"
              data-bs-toggle="tab"
              data-bs-target="#people-tab-pane"
              type="button"
              role="tab"
              aria-controls="people-tab-pane"
              aria-selected="false"
            >
              {t('Team')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="success-stories-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#success-stories-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="success-stories-tab-pane"
                    aria-selected="false"
                  >
                    Success Stories
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="kpis-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#kpis-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="kpis-tab-pane"
                    aria-selected="false"
                  >
                    KPIs
                  </Link>
                </li> */}

          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="reviews-tab"
              data-bs-toggle="tab"
              data-bs-target="#reviews-tab-pane"
              type="button"
              role="tab"
              aria-controls="reviews-tab-pane"
              aria-selected="false"
            >
              {t('Reviews')}
            </Link>
          </li>
        </ul>

        {/* ----------- Tab Content ----------- */}
        <div className="tab-content" id="projectTabContent">
          {/* ----------- About Tab Content ----------- */}
          <OverviewTab
            companyDetails={companyDetails}
            companyServices={companyServices}
            companySearchings={companySearchings}
            companyInterests={companyInterests}
            companyDocs={companyDocs}
          />

          {/* ----------- Services Tab Content ----------- */}
          <ServicesTab />

          {/* ----------- Challenges Tab Content ----------- */}
          <ChallengesTab />

          {/* ----------- Projects Tab Content ----------- */}
          <PeojectsTab />

          {/* ----------- Team Tab Content ----------- */}
          <TeamTab companyMemberList={companyMemberList} />

          {/* ----------- Success Stories Tab Content ----------- */}
          <SuccessStoriesTab />

          {/* ----------- KPIs Tab Content ----------- */}
          <KPIsTab />

          {/* -------------- review tab ----------------- */}

          <ReviewTab
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
          />
        </div>
      </div>
    </div>
  );
};

export default CompaniesDetailsRightComponent;
