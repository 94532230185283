/* eslint-disable */
import React from 'react';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import CompaniesList from 'Components/CompaniesComponents/CompaniesLandingComponents/CompaniesList/CompaniesList';
import Footer from 'Components/Common/Footer/Footer';

const CompaniesLanding = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="companies" />

      <div id="main_content" className="position-relative">
        {/* ----- companies section ------- */}
        <CompaniesList />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default CompaniesLanding;
