/* eslint-disable */
import React, { useEffect, useState } from 'react';
/*------ import url and method ------*/
import {
  getData,
  postData,
  putData,
  uploadMultipleFile,
} from '../../../../../../utils/Gateway';
import * as url from '../../../../../../helper/UrlHelper';

import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LessonTaskTab = ({
  userCourseRole = '',
  taskList = [],
  selectedLessonId = null,
}) => {
  const { t } = useTranslation(); // for translations

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: 'Write your answer here',
  };
  /*---- CKEditor classic editor configuration end ----- */

  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [taskClassName, setTaskClassName] = useState('task_panl lesson_pnl');
  const [selectedTaskId, setselectedTaskId] = useState(null);
  const [taskDetails, settaskDetails] = useState({});
  const [existingAssignmentTaskId, setExistingAssignmentTaskId] =
    useState(null);
  const [existingAssignmentTaskStatus, setexistingAssignmentTaskStatus] =
    useState('');
  const [existingAssignmentTaskGrade, setexistingAssignmentTaskGrade] =
    useState('');
  const [existingAssignmentTaskDate, setexistingAssignmentTaskDate] =
    useState('');
  const [existingAssignmentTaskFiles, setExistingAssignmentTaskFiles] =
    useState([]);

  const [isCreating, setisCreating] = useState(false);

  const [taskAnswer, settaskAnswer] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [isCompleted, setisCompleted] = useState(false);

  const [logList, setLogList] = useState([]);

  const [taskCompleteStatus, settaskCompleteStatus] = useState('0');
  const [isSaving, setisSaving] = useState(false);

  const closeTaskDetails = () => {
    setTaskClassName('task_panl lesson_pnl');
    setselectedTaskId(null);
    resetAnswerSection();
  };

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log('task details', response);

      if (response.status) {
        settaskDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get created task assignment details
  const getTaskAssignmentDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EXISTING_LESSON_TASK +
        `?token=${token}&filtercourse=${params.id}&lessonid=${selectedLessonId}&previoustask=${selectedTaskId}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log('task assignment details', response);

      if (response.status) {
        setExistingAssignmentTaskId(response.data._id);
        setexistingAssignmentTaskStatus(response.data.taskstatus);
        setexistingAssignmentTaskGrade(response.data.price);
        setexistingAssignmentTaskDate(response.data.taskdate);
        setExistingAssignmentTaskFiles(response.data.uploadedfiles);
        settaskCompleteStatus(response.data.completedpercent);

        if (response.data.completedpercent === '3') {
          setisCompleted(true);
        }
      } else {
        setExistingAssignmentTaskId(null);
        setexistingAssignmentTaskStatus('');
        setexistingAssignmentTaskGrade('');
        setexistingAssignmentTaskDate('');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getTaskLogs = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COURSE_TASK_ANSWERS +
        `?token=${token}&filtertask=${existingAssignmentTaskId}&filterlesson=${selectedLessonId}&filtercourse=${params.id}`;

      // console.log("log list url----->", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setLogList(response.data);
      }
      // else {
      //   setMessageType("error");
      //   setAlertMessage(response.message);
      //   setShowAlert(true);
      // }
    } catch (error) {
      // setMessageType("error");
      // setAlertMessage(error.message);
      // setShowAlert(true);
    }
  };

  //toggle task answer section
  const resetAnswerSection = () => {
    settaskAnswer('');
    setUploadedFileIds([]);
    setUploadedFiles([]);
    setisCompleted(false);
  };

  //function for file upload
  const uploadHandler = async e => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      console.log('req url', requestUrl);

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      console.log(
        `response in file upload mail template------>`,
        response.data
      );

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById('uploadFile');
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeUploadedFileHandler = index => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);
  };

  //create Task Assignment
  const createTaskAssignmentHandler = async () => {
    try {
      setisCreating(true);

      let taskData = {
        uploads: [],
        previoustask: selectedTaskId,
        lessonid: selectedLessonId,
        course: params.id,
        assigneduser: userInfo._id,
      };

      let requestURL =
        url.API_BASE_URL + url.API_CREATE_LESSON_ASSIGNMENT + `?token=${token}`;

      const response = await postData(requestURL, taskData);

      setisCreating(false);

      console.log(response);

      if (response.status) {
        getTaskAssignmentDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for submit answer of task
  const submitAnswerHandler = async () => {
    try {
      setisSaving(true);

      let taskData = {
        answer: taskAnswer,
        uploads: uploadedFileIds,
        previoustask: selectedTaskId,
        lessonid: selectedLessonId,
        course: params.id,
        assigneduser: userInfo._id,
      };

      if (isCompleted) {
        taskData.completedpercent = '3';
      }

      let requestURL =
        url.API_BASE_URL +
        url.API_SUBMIT_LESSON_TASK +
        `/${existingAssignmentTaskId}?token=${token}`;

      const response = await putData(requestURL, taskData);

      setisSaving(false);

      console.log(response);

      if (response.status) {
        settaskAnswer('');
        setUploadedFileIds([]);
        setUploadedFiles([]);
        getTaskDetails();
        getTaskAssignmentDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
      getTaskAssignmentDetails();
    }
  }, [selectedTaskId]);

  useEffect(() => {
    if (selectedLessonId) {
      closeTaskDetails();
    }
  }, [selectedLessonId]);

  useEffect(() => {
    if (existingAssignmentTaskId && selectedLessonId) {
      getTaskLogs();
    }
  }, [existingAssignmentTaskId, selectedLessonId]);
  if (taskList.length > 0) {
    return (
      <div
        className="tab-pane fade"
        id="task-tab-pane"
        role="tabpanel"
        aria-labelledby="task-tab"
        tabIndex="0"
      >
        <div className="task-content bg-white p-4 rounded-10 shadow-sm">
          <h3 className="mb-3">{t('Task')}</h3>
          <div className="tasks row">
            {taskList.map((task, index) => {
              return (
                <div className="col-md-6 task mb-4" key={index}>
                  <div className="task-content fs-sm p-3 body-bg rounded-10">
                    <p className="duration font-secondary text-light fw-semibold d-flex align-items-center gap-1 mb-3">
                      <span className="d-block material-symbols-outlined icon-md">
                        schedule
                      </span>
                      <span className="d-block">
                        {task.duration}
                        {t('min')}
                      </span>
                    </p>
                    <h6 className="mt-0 mb-2">{task?.title}</h6>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: task?.description,
                      }}
                    />
                    <Link
                      to="#"
                      className="btn btn-outline-black btn-sm mt-3"
                      // onClick={() => {
                      //   setTaskClassName('task_panl lesson_pnl task_open');
                      //   setselectedTaskId(task._id);
                      // }}
                    >
                      {t('View Task')}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    <p className="fs-lg text-gray fw-semibold mb-4">
      Sorry....! Assignments are not available for this lesson
    </p>;
  }
};

export default LessonTaskTab;
