/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';

const HomeCourses = () => {
  const token = localStorage.getItem('token'); // get token from local storage

  const { t } = useTranslation(); // for translations

  const responsive = {
    superLargeDesktop: {
      // This breakpoint is for screens with a width of 2560px or more
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      // This breakpoint is for screens with a width of 1024px or more
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      // This breakpoint is for screens with a width of 768px or more
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      // This breakpoint is for screens with a width of less than 768px
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const [courseList, setCourseList] = useState([]); // for course lists

  //get course list
  const getAllCourses = async () => {
    try {
      //  setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_COURSES + `?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      //  setIsLoading(false);

      if (response.status) {
        setCourseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  return (
    <section className="best-courses bg-light bg-opacity-2 py-50 py-md-70">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Best Courses')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="/courses" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive} infinite={true} autoPlay={true}>
          {courseList.slice(0, 4).map((courseData, index) => {
            return (
              <div className="course" key={index} style={{ padding: '0 10px' }}>
                <div className="course-content bg-white rounded-10 overflow-hidden p-3 shadow-sm">
                  <div className="img-container rounded-3 overflow-hidden mb-3">
                    <Link to="#">
                      <img
                        src={url.SERVER_URL + courseData.courselogo}
                        alt={courseData.title}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="desc">
                    <div className="course-meta d-flex flex-wrap gap-2 align-items-center mb-2">
                      {/* <img
                      src="assets/img/google-logo.png"
                      alt="Google"
                      className="img-fluid"
                    /> */}
                      <div className="rating d-flex align-items-center gap-2 ms-auto">
                        <p className="d-flex gap-1 align-items-center px-3 py-2 text-gray bg-gray-100 rounded-90">
                          <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                            star
                          </span>
                          <span className="d-block fs-sm fw-bold">
                            {courseData.totalratings}
                          </span>
                          <span className="d-block fs-xs">
                            {courseData.totalreviews}
                          </span>
                        </p>
                        <Link to="#" className="text-gray">
                          <span className="d-block material-symbols-outlined">
                            bookmark
                          </span>
                        </Link>
                      </div>
                    </div>
                    <p className="fw-medium mb-2">
                      <Link to="/courses" className="text-black">
                        {courseData.title}
                      </Link>
                    </p>
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                      {courseData?.courseskills?.map((skill, index) => (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            className="d-block text-gray bg-gray-100 rounded-90"
                          >
                            {skill}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeCourses;
