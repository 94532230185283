/* eslint-disable */
import { Switch, Route, Redirect } from 'react-router-dom';
import { protectedRoutes, publicRoutes } from './Routes';

const Authmiddleware = () => {
  let token = null;

  token = localStorage.getItem('token'); //get token from local storage

  return (
    <Switch>
      {/* ====== mapping of non authenticated routes start ====== */}

      {!token &&
        publicRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== mapping of non authenticated routes end ====== */}

      {/* ====== mapping of authenticated route start ====== */}

      {token &&
        protectedRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== mapping of authenticated route end ====== */}

      {/* ====== default redirect to dashboard ======= */}
      {token ? <Redirect to="/" /> : <Redirect to="/login" />}
    </Switch>
  );
};

export default Authmiddleware;
