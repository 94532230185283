import React from 'react';
import { useTranslation } from 'react-i18next';

const SuccessStoriesDetailsDescriptions = ({
  // successStoryDetails,
  successStorySitutation,
  successStoryObjectives,
  successStoryProcess,
  successStoryResults,
}) => {
  const { t } = useTranslation(); // for translatio

  return (
    <section className="success-stories-wrapper py-50">
      <div className="container">
        <div className="success-story bg-white rounded-10 shadow-sm overflow-hidden mb-4">
          <div className="d-flex flex-wrap">
            <div className="col-lg-6">
              <img
                src="/assets/img/previous-situation.jpg"
                alt="Previous Situation"
                className="w-100 h-100 object-cover object-center"
              />
            </div>
            <div className="col-lg-6 p-4 p-lg-5 my-auto">
              <p className="sub-title fs-lg fw-semibold text-light mb-2">
                {t('Previous Situation')}
              </p>
              {/* <h3
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: successStorySitutation }}
              /> */}

              <p dangerouslySetInnerHTML={{ __html: successStorySitutation }} />

              {/* <Link to="#" className="btn btn-secondary mt-3">
                Read More
              </Link> */}
            </div>
          </div>
        </div>
        <div className="success-story bg-white rounded-10 shadow-sm overflow-hidden mb-4">
          <div className="d-flex flex-wrap flex-column-reverse flex-lg-row">
            <div className="col-lg-6 p-4 p-lg-5 my-auto">
              <p className="sub-title fs-lg fw-semibold text-light mb-2">
                {t('Previous Objectives')}
              </p>
              {/* <h3 className="mb-3">
                Hay muchas variaciones de los pasajes deorem sum disponibles,
                pero la mayoría sufrió
              </h3> */}
              {/* <p>
                Al contrario del pensamiento popular, el texto de Lorem Ipsum no
                es simplemente texto aleatorio. Tiene sus raices en una pieza
                cl´sica de la literatura del Latin, que data del año 45 antes de
                Cristo, haciendo que este adquiera mas de 2000 años de
                antiguedad. Richard McClintock, un profesor de Latin de la
                Universidad de Hampden-Sydney en Virginia, encontró una de las
                palabras más oscuras de la lengua del latín, "consecteur", en un
                pasaje de Lorem Ipsum.
              </p> */}
              <p dangerouslySetInnerHTML={{ __html: successStoryObjectives }} />
              {/* <Link to="#" className="btn btn-secondary mt-3">
                Read Less
              </Link> */}
            </div>
            <div className="col-lg-6">
              <img
                src="/assets/img/objectives.jpg"
                alt="Objectives"
                className="w-100 h-100 object-cover object-center"
              />
            </div>
          </div>
        </div>
        <div className="success-story bg-white rounded-10 shadow-sm overflow-hidden mb-4">
          <div className="d-flex flex-wrap">
            <div className="col-lg-6">
              <img
                src="/assets/img/process.jpg"
                alt="Process"
                className="w-100 h-100 object-cover object-center"
              />
            </div>
            <div className="col-lg-6 p-4 p-lg-5 my-auto">
              <p className="sub-title fs-lg fw-semibold text-light mb-2">
                {t('Process')}
              </p>
              {/* <h3 className="mb-3">
                Hay muchas variaciones de los pasajes deorem sum disponibles,
                pero la mayoría sufrió
              </h3>
              <p>
                Al contrario del pensamiento popular, el texto de Lorem Ipsum no
                es simplemente texto aleatorio. Tiene sus raices en una pieza
                cl´sica de la literatura del Latin, que data del año 45 antes de
                Cristo, haciendo que este adquiera mas de 2000 años de
                antiguedad. Richard McClintock, un profesor de Latin de la
                Universidad de Hampden-Sydney en Virginia, encontró una de las
                palabras más oscuras de la lengua del latín, "consecteur", en un
                pasaje de Lorem Ipsum.
              </p> */}

              <p dangerouslySetInnerHTML={{ __html: successStoryProcess }} />
            </div>
          </div>
        </div>
        <div className="success-story bg-white rounded-10 shadow-sm overflow-hidden mb-4">
          <div className="d-flex flex-wrap flex-column-reverse flex-lg-row">
            <div className="col-lg-6 p-4 p-lg-5 my-auto">
              <p className="sub-title fs-lg fw-semibold text-light mb-2">
                {t('Results')}
              </p>
              {/* <h3 className="mb-3">
                Hay muchas variaciones de los pasajes deorem sum disponibles,
                pero la mayoría sufrió
              </h3>
              <p>
                Al contrario del pensamiento popular, el texto de Lorem Ipsum no
                es simplemente texto aleatorio. Tiene sus raices en una pieza
                cl´sica de la literatura del Latin, que data del año 45 antes de
                Cristo, haciendo que este adquiera mas de 2000 años de
                antiguedad. Richard McClintock, un profesor de Latin de la
                Universidad de Hampden-Sydney en Virginia, encontró una de las
                palabras más oscuras de la lengua del latín, "consecteur", en un
                pasaje de Lorem Ipsum.
              </p> */}

              <p dangerouslySetInnerHTML={{ __html: successStoryResults }} />
            </div>
            <div className="col-lg-6">
              <img
                src="/assets/img/result.jpg"
                alt="Results"
                className="w-100 h-100 object-cover object-center"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesDetailsDescriptions;
