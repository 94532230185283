import React from 'react';
import DetailsTab from '../../Tabs/DetailsTab/DetailsTab';
import ForumTab from '../../Tabs/ForumTab/ForumTab';
import TeamsTab from '../../Tabs/TeamsTab/TeamsTab';
import AppliedTab from '../../Tabs/AppliedTab/AppliedTab';
import ProcessTab from '../../Tabs/ProcessTab/ProcessTab';
import WinnerTab from '../../Tabs/WinnerTab/WinnerTab';
import FaqTab from '../../Tabs/FaqTab/FaqTab';
import DocTab from '../../Tabs/DocTab/DocTab';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ChallengesDetailsRightComponent = ({
  requiredSkills,
  optionalSkills,
  summary,
  details,
  aboutPosition,
  aboutCompany,
  minSalary,
  maxSalary,
  uploadedDocFiles,
  memberList,
  processList,
  isApplied,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div className="col-lg-8">
      <div className="challenge-details-container">
        {/* ---------- tab btns section ------------ */}
        <ul
          className="nav nav-tabs gap-2 border-0 mb-3"
          id="accountTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90 active"
              id="details-tab"
              data-bs-toggle="tab"
              data-bs-target="#details-tab-pane"
              type="button"
              role="tab"
              aria-controls="details-tab-pane"
              aria-selected="true"
            >
              {t('Details')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="forum-tab"
              data-bs-toggle="tab"
              data-bs-target="#forum-tab-pane"
              type="button"
              role="tab"
              aria-controls="forum-tab-pane"
              aria-selected="false"
            >
              {t('Forum')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="teams-tab"
              data-bs-toggle="tab"
              data-bs-target="#teams-tab-pane"
              type="button"
              role="tab"
              aria-controls="teams-tab-pane"
              aria-selected="false"
            >
              {t('Teams')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="applied-tab"
              data-bs-toggle="tab"
              data-bs-target="#applied-tab-pane"
              type="button"
              role="tab"
              aria-controls="applied-tab-pane"
              aria-selected="false"
            >
              {t('Applied')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="process-tab"
              data-bs-toggle="tab"
              data-bs-target="#process-tab-pane"
              type="button"
              role="tab"
              aria-controls="process-tab-pane"
              aria-selected="false"
            >
              {t('Process')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="winner-tab"
              data-bs-toggle="tab"
              data-bs-target="#winner-tab-pane"
              type="button"
              role="tab"
              aria-controls="winner-tab-pane"
              aria-selected="false"
            >
              {t('Winner')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="faq-tab"
              data-bs-toggle="tab"
              data-bs-target="#faq-tab-pane"
              type="button"
              role="tab"
              aria-controls="faq-tab-pane"
              aria-selected="false"
            >
              {t('FAQ')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="doc-tab"
              data-bs-toggle="tab"
              data-bs-target="#doc-tab-pane"
              type="button"
              role="tab"
              aria-controls="doc-tab-pane"
              aria-selected="false"
            >
              {t('Doc')}
            </Link>
          </li>
        </ul>

        {/* ---------- tab contents ---------- */}
        <div className="tab-content" id="accountTabContent">
          {/* ---------- details tab ---------- */}
          <DetailsTab
            summary={summary}
            details={details}
            aboutPosition={aboutPosition}
            aboutCompany={aboutCompany}
            minSalary={minSalary}
            maxSalary={maxSalary}
            isApplied={isApplied}
          />

          {/* ---------- forum tab ---------- */}
          <ForumTab />

          {/* ---------- teams tab ---------- */}
          <TeamsTab memberList={memberList} />

          {/* ---------- applied tab ---------- */}
          <AppliedTab />

          {/* ---------- process tab ---------- */}
          <ProcessTab />

          {/* ---------- winner tab ---------- */}
          <WinnerTab />

          {/* ---------- faq tab ---------- */}
          <FaqTab />

          {/* ---------- doc tab ---------- */}
          <DocTab uploadedDocFiles={uploadedDocFiles} />
        </div>
      </div>
    </div>
  );
};

export default ChallengesDetailsRightComponent;
