import React from 'react';
import { Link } from 'react-router-dom';

const AppliedTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="applied-tab-pane"
      role="tabpanel"
      aria-labelledby="applied-tab"
      tabIndex="0"
    >
      <div className="members">
        <div className="row">
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-1.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-2.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-3.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-4.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-1.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-2.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-3.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-4.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="member col-md-6 col-lg-4 mb-4">
            <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
              <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                <Link to="#">
                  <img
                    src="assets/img/profile-avatar-1.png"
                    alt="Harry Nonalarm"
                    className="w-100 h-100 object-cover object-center"
                  />
                </Link>
              </div>
              <div className="profile-info">
                <h6>
                  <Link to="#" className="text-black">
                    Harry Nonalarm
                  </Link>
                </h6>
                <p className="designation text-gray mb-2">MBDs at Univercity</p>
                <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                  <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                    star
                  </span>
                  <span className="d-block">4.5</span>
                  <span className="d-block">(32)</span>
                </p>
                <div className="action d-flex gap-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      rate_review
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-light btn-sm btn-icon"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm flex-fill"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedTab;
