import React from 'react';

import * as url from 'helper/UrlHelper';

import { Link } from 'react-router-dom';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';

const EventDetailsLeftComponent = ({
  eventTitle,
  eventDate,
  eventCity,
  eventCountry,
  eventMembers,
  isModerator,
  isJoined,
  isApproved,
  requestDate,
  approveDate,
  eventTags,
  joinEventHandler,
  ratingCount,
  reviewsList,
  isJoining,
  shareHandler,
}) => {
  const { t } = useTranslation(); // for translations
  const memberLimit = 5; //for displaying the limit number of members

  return (
    <div className="col-lg-4">
      <aside className="event-sidebar">
        <div className="event-info p-4 bg-white rounded-10 shadow-sm">
          {/* ------------------ Event Title ------------------ */}
          <h3>{eventTitle}</h3>

          {/* ------------------------ Event Date ------------------------ */}
          <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
            <span className="d-block material-symbols-outlined icon-sm">
              event_note
            </span>

            <span className="d-block">{eventDate}</span>
          </p>
          <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
            {/* ------ ratings and reviews ------ */}
            <li className="tag">
              <Link
                to="#"
                className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fs-sm fw-bold">{ratingCount}</span>
                <span className="d-block fs-xs">({reviewsList.length})</span>
              </Link>
            </li>

            {/* ----- location ----- */}
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span className="d-block">
                  {eventCity}, {eventCountry}
                </span>
              </Link>
            </li>
          </ul>
          {/* <p className="info text-dark mb-5">
            We are honu.ai, a venture backed startup building the next
            generation.
          </p> */}

          {/* ----- event members ----- */}
          <ul className="event-members d-flex align-items-center mb-3">
            {eventMembers.slice(0, memberLimit).map((member, index) => {
              return (
                <li
                  className="border-1 border-white rounded-circle overflow-hidden"
                  key={index}
                >
                  <img
                    src={
                      member.userimage === ''
                        ? assetImages.defaultUser
                        : url.SERVER_URL + member.userimage
                    }
                    alt={`DP ${index + 1}`}
                    className="rounded-circle"
                    height={40}
                    width={40}
                  />
                </li>
              );
            })}

            {eventMembers.length > memberLimit ? (
              <li className="more ms-2">
                +{eventMembers.length - memberLimit} more
              </li>
            ) : null}
          </ul>

          {/* ------ tags ------- */}
          <ul className="tags d-flex flex-wrap gap-2 text-primary mb-3">
            {eventTags.map((tag, index) => {
              return (
                <li className="body-bg px-2 py-1 rounded-5" key={index}>
                  {tag.title}
                </li>
              );
            })}
          </ul>

          {/* ----- action buttons -----  */}
          <ul className="list-style-none d-flex align-items-center gap-2 flex-wrap justify-content-center">
            {isModerator ? null : isApproved ? (
              <li>
                <Link
                  to="#"
                  className="btn btn-outline-secondary btn-sm flex-fill"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_modal"
                >
                  {t('Joined')}
                </Link>
              </li>
            ) : isJoined ? null : (
              <li>
                <Link
                  to="#"
                  className="btn btn-outline-light btn-sm flex-fill"
                  onClick={joinEventHandler}
                >
                  {t('Join Now')}
                </Link>
              </li>
            )}

            {/* ---- give review Button ---- */}
            {isModerator || isApproved ? (
              <li>
                <Link
                  to="#"
                  className="btn btn-outline-light btn-sm flex-fill"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback_mdl"
                >
                  {t('Give Review')}
                  {isJoining && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </Link>
              </li>
            ) : null}

            {!isModerator &&
              (isApproved ? (
                <li>
                  <p className="d-flex align-items-center justify-content-center">
                    <span>
                      {t('Joined on')} {approveDate}
                    </span>
                  </p>
                </li>
              ) : isJoined ? (
                <li>
                  <p className="d-flex align-items-center justify-content-center">
                    <span>
                      {t('Request on')} {requestDate}
                    </span>
                  </p>
                </li>
              ) : null)}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default EventDetailsLeftComponent;
