import React from 'react';
import * as url from '../../../../../helper/UrlHelper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const MembersTab = ({ memberList }) => {
  const { t } = useTranslation(); // for translations

  return (
    <div
      className="tab-pane fade"
      id="members-tab-pane"
      role="tabpanel"
      aria-labelledby="members-tab"
      tabIndex="0"
    >
      <div className="members">
        <div className="row">
          {memberList?.map((memberData, index) => {
            return (
              <div className="member col-md-6 col-lg-4 mb-4">
                <div className="member-content bg-white p-3 text-center rounded-10 shadow-sm">
                  <div className="profile-avatar bg-light rounded-circle overflow-hidden mx-auto mb-3">
                    <Link to="#">
                      <img
                        src={
                          memberData.image === ''
                            ? assetImages.defaultUser
                            : url.SERVER_URL + memberData.image
                        }
                        alt={memberData.name}
                        className="w-100 h-100 object-cover object-center"
                      />
                    </Link>
                  </div>
                  <div className="profile-info">
                    <h6>
                      <Link to="#" className="text-black">
                        {memberData.name}
                      </Link>
                    </h6>
                    <p className="designation text-gray mb-2">
                      {memberData.role}
                    </p>
                    <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
                      <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                        star
                      </span>
                      <span className="d-block">{memberData.totalratings}</span>
                      <span className="d-block">
                        ({memberData.totalreviews})
                      </span>
                    </p>
                    <div className="action d-flex gap-2">
                      {/* <Link
                        to="#"
                        className="btn btn-outline-light btn-sm btn-icon"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          rate_review
                        </span>
                      </Link>
                      <Link
                        to="#"
                        className="btn btn-outline-light btn-sm btn-icon"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          event_note
                        </span>
                      </Link> */}
                      <Link
                        to={`/accounts/details/${memberData.userid}`}
                        className="btn btn-outline-secondary btn-sm flex-fill"
                      >
                        {t('View Details')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MembersTab;
