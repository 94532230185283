/* eslint-disable */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { postData } from 'utils/Gateway';

import contactOptions from 'Data/ContactOptions.json';
import relationOptions from 'Data/RelationOptions.json';

const ConnectModal = ({ afterModalClose }) => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const params = useParams();

  /* ---- state to select option values ---- */
  const [selectContactOption, setSelectContactOption] = useState(null);
  const [selectKnowingOption, setSelectKnowingOption] = useState(null);
  const [connectMessage, setConnectMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  /* ---- state to handle validation flags ---- */
  const [contactOptionError, setContactOptionError] = useState(false);
  const [knowingOptionError, setKnowingOptionError] = useState(false);
  const [connectMessageError, setConnectMessageError] = useState(false);

  /* ---- validation function ---- */
  const validateFields = () => {
    const contactError = !selectContactOption;
    const knowingError = !selectKnowingOption;
    const messageError = !connectMessage.trim();

    setContactOptionError(contactError);
    setKnowingOptionError(knowingError);
    setConnectMessageError(messageError);

    return !(contactError || knowingError || messageError);
  };

  //function for send request
  const sendConnectHandler = async () => {
    // Validate fields
    const isValid = validateFields();

    if (isValid) {
      setIsSending(true);
      try {
        let connectData = {
          member: params.id,
          reason: selectContactOption?.value ? selectContactOption?.value : '',
          source: selectKnowingOption?.value ? selectKnowingOption?.value : '',
          message: connectMessage,
        };

        let requestURL =
          url.API_BASE_URL + url.API_SEND_REQUEST_USER + `?token=${token}`;

        // console.log(requestURL);
        const response = await postData(requestURL, connectData);

        setIsSending(false);
        // console.log(response);

        if (response.status) {
          resetHandler();
          // close the modal of addLabel

          let myModal = document.querySelector('#connect_modal');
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();

          //show register modal
          let greetingModal = new bootstrap.Modal(
            document.getElementById('gretings_mdl')
          );
          greetingModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset function
  const resetHandler = () => {
    setSelectContactOption(null);
    setSelectKnowingOption(null);
    setConnectMessage('');
    setContactOptionError(false);
    setKnowingOptionError(false);
    setConnectMessageError(false);
  };

  return (
    <div className="modal fade" id="connect_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header justify-content-between border-0 p-4 pb-0">
            <h3 className="modal-title fs-xl text-dark fw-semibold">
              Send Request{' '}
            </h3>
            <button
              onClick={resetHandler}
              type="button"
              className="btn-close bg-gray-200 text-black rounded-circle shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="form-group mx-3 ">
            <Select
              placeholder="Select Contact Type"
              options={contactOptions}
              value={selectContactOption}
              onChange={val => {
                setSelectContactOption(val), setContactOptionError(false);
              }}
            />
            {contactOptionError && (
              <p className="text-danger mb-0 mt-1">
                * Please select a contact type.
              </p>
            )}
          </div>
          <div className="form-group mx-3 mt-2">
            <Select
              placeholder="Select Relation"
              options={relationOptions}
              value={selectKnowingOption}
              onChange={val => {
                setSelectKnowingOption(val), setKnowingOptionError(false);
              }}
            />
            {knowingOptionError && (
              <p className="text-danger mb-0 mt-1">* Please select a reason.</p>
            )}
          </div>
          <div className="modal-body p-4">
            <div className="form-group mb-3">
              <textarea
                className="form-control"
                rows="8"
                placeholder="Message of contact"
                value={connectMessage}
                onChange={e => {
                  setConnectMessage(e.target.value),
                    setConnectMessageError(false);
                }}
              ></textarea>
              {connectMessageError && (
                <p className="text-danger mb-0 mt-1">
                  * Please enter a message.
                </p>
              )}
            </div>
            <div className="post_btn form-group">
              <button
                className="btn btn-primary w-100"
                onClick={sendConnectHandler}
              >
                Send Request
                {isSending && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConnectModal;
