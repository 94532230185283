/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/*------ import url and method ------*/
import { getData, postData } from '../../../../../../utils/Gateway';
import * as url from '../../../../../../helper/UrlHelper';
import SuccessModal from '../../../../../Common/Modals/SuccessModal';
import { useTranslation } from 'react-i18next';

const LessonQuestionTab = ({ selectedLessonId = null }) => {
  const { t } = useTranslation(); // for translations

  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [faqList, setFaqList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [noteTitle, setNoteTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  //function for get notes
  const getAllFaqs = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_LESSON_FAQS +
        `?token=${token}&filtercourse=${params.id}&filterlesson=${selectedLessonId}`;

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        const responsedData = response.data
          .filter(item => item.details !== '')
          .sort((a, b) => a.rank - b.rank);

        setFaqList(responsedData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save faq
  const saveFaqHandler = async () => {
    try {
      setIsSaving(true);

      let noteData = {
        title: noteTitle,
        details: '',
        rank: 0,
        isprivate: false,
        course: params.id,
        lessonid: selectedLessonId,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_ADD_NEW_NOTE + `?token=${token}`;

      const response = await postData(requestUrl, noteData);

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        //show otpconfirm modal
        let myModal = new bootstrap.Modal(
          document.getElementById('gretings_mdl')
        );
        myModal.show();

        // resetHandler();
        // getAllFaqs();
      }
    } catch (error) {}
  };

  const afterModalClose = () => {
    resetHandler();
    getAllFaqs();
  };

  const resetHandler = () => {
    setNoteTitle('');
  };

  useEffect(() => {
    if (selectedLessonId && params.id) {
      getAllFaqs();
    }
  }, [params.id, selectedLessonId]);
  return (
    <div
      className="tab-pane fade"
      id="question-tab-pane"
      role="tabpanel"
      aria-labelledby="question-tab"
      tabIndex="0"
    >
      <div className="question-content bg-white p-5 rounded-10 shadow-sm">
        <h3 className="mb-3">{t('Frequently Asked Question')}</h3>
        <div className="accordion fs-sm mb-4" id="accordionExample">
          {faqList.map((faq, index) => {
            return (
              <div
                className="accordion-item bg-transparent border-0 mb-2"
                key={index}
              >
                <div className="accordion-header">
                  <button
                    className="accordion-button fs-sm text-black body-bg shadow-none rounded-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    {faq.title}
                  </button>
                </div>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>{faq.details}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="submit-your-question">
          <form action="">
            <h6 className="mb-3">{t('Your Questions')}</h6>
            <div className="form-group mb-3">
              <textarea
                name=""
                id=""
                cols="30"
                rows="8"
                className="form-control font-secondary fw-normal"
                placeholder={t('Enter message')}
                value={noteTitle}
                onChange={e => setNoteTitle(e.target.value)}
              ></textarea>
            </div>
            <div className="action">
              <button
                type="submit"
                className="btn btn-secondary btn-sm"
                onClick={saveFaqHandler}
                disabled={isSaving}
                style={{ cursor: isSaving ? 'not-allowed' : 'pointer' }}
              >
                {t('Submit Question')}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <SuccessModal afterModalClose={afterModalClose} />
    </div>
  );
};

export default LessonQuestionTab;
