import React from 'react';
import { useTranslation } from 'react-i18next';

const DocTab = ({ uploadedDocFiles }) => {
  const { t } = useTranslation(); // for translations

  return (
    <div
      className="tab-pane fade"
      id="doc-tab-pane"
      role="tabpanel"
      aria-labelledby="doc-tab"
      tabIndex="0"
    >
      <div className="document-container p-4 bg-white rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-3">
          {t('Docs')}
        </p>
        <ul className="documents d-flex flex-wrap gap-3">
          {/* -------------- document section ------------ */}
          {uploadedDocFiles.map((file, index) => {
            return (
              <li
                className="document d-flex flex-column justify-content-center fs-sm text-center p-3 border border-gray rounded-8"
                key={index}
              >
                <div className="file-icon mx-auto mb-3">
                  <img
                    src="/assets/img/pdf-icon.png"
                    alt={file.name}
                    className="img-fluid"
                  />
                </div>
                <p className="mb-1">{file.name}</p>
                {/* <p className="size fs-xs text-gray">1.5mb</p> */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DocTab;
