import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ForumTab = () => {
  const { t } = useTranslation(); // for translations

  return (
    <div
      className="tab-pane fade"
      id="forum-tab-pane"
      role="tabpanel"
      aria-labelledby="forum-tab"
      tabIndex="0"
    >
      <div className="forum-container p-4 bg-white rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-primary mb-3">{t('Forum')}</p>
        <div className="query-form mb-4">
          <form action="">
            <div className="form-group position-relative">
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className="form-control bg-transparent fw-normal text-primary border-1"
                placeholder={t('Write your query here')}
              ></textarea>
              <div className="action d-flex align-items-center gap-3 pe-3 pb-3 position-absolute end-0 bottom-0">
                <button
                  type="button"
                  className="bg-transparent p-0 text-primary border-0"
                  data-target="uploadFile"
                >
                  <span className="d-block material-symbols-outlined">
                    image
                  </span>
                </button>
                <input type="file" id="uploadFile" className="d-none" />
                <button
                  type="submit"
                  className="bg-transparent p-0 text-primary border-0"
                >
                  <span className="d-block material-symbols-outlined">
                    send
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <ul className="forums">
          <li className="forum selected">
            <div className="forum-content d-flex align-items-start gap-3">
              <div className="profile-avatar rounded-circle overflow-hidden">
                <img
                  src="assets/img/dp-4.png"
                  alt="Roma Keller"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>
              <div className="desc flex-fill">
                <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                  <div className="name">
                    <p className="fw-semibold mb-0">Roma Keller</p>
                    <p className="date fs-sm text-primary">20/07/2021 17:31</p>
                  </div>
                  <Link
                    to="#"
                    className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                  >
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      share
                    </span>
                  </Link>
                </div>
                <p>
                  My bad, I don't actually have those Processor's to test, but,
                  the i5 9600k is about ( a little worse) on par with the Ryzen
                  5 2600, so I am just going to go with it. I read on some
                  website that the 1600 and 2600 was better, but, they are both
                  worse than the 9600k. And, anyways I already have a B365 LGA
                  1151 Gen 2 motherboard, so, it's the most logical upgrade.
                </p>
                <ul className="attachments d-flex flex-wrap gap-2 mt-3">
                  <li className="attachment rounded-3 overflow-hidden">
                    <img
                      src="assets/img/attachment-1.jpg"
                      alt="attachment 1"
                      className="img-fluid"
                    />
                  </li>
                  <li className="attachment rounded-3 overflow-hidden">
                    <img
                      src="assets/img/attachment-2.jpg"
                      alt="attachment 2"
                      className="img-fluid"
                    />
                  </li>
                </ul>
                <div className="action d-flex flex-wrap gap-1 mt-3">
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      thumb_up
                    </span>
                    <span className="d-block">{t('Likes')}</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      2
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      chat
                    </span>
                    <span className="d-block">{t('Comment')}</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      3
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <ul className="forums sub">
              <li className="forum">
                <div className="forum-content d-flex align-items-start gap-3">
                  <div className="profile-avatar rounded-circle overflow-hidden">
                    <img
                      src="assets/img/dp-5.png"
                      alt="Roma Keller"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </div>
                  <div className="desc flex-fill">
                    <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                      <div className="name">
                        <p className="fw-semibold mb-0">Roma Keller</p>
                        <p className="date fs-sm text-primary">
                          20/07/2021 17:31
                        </p>
                      </div>
                      <Link
                        to="#"
                        className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                      >
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          share
                        </span>
                      </Link>
                    </div>
                    <p>
                      There are 2 types of Ryzen 1600(x), There's 1600AE which
                      is from first gen Ryzen and 1600AF which is 2nd gen, same
                      gen as 2600(x).
                    </p>
                    <div className="action d-flex flex-wrap gap-1 mt-3">
                      <Link
                        to="#"
                        className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center justify-content-center gap-1 rounded-circle"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          thumb_up
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="forum">
                <div className="forum-content d-flex align-items-start gap-3">
                  <div className="profile-avatar rounded-circle overflow-hidden">
                    <img
                      src="assets/img/dp-6.png"
                      alt="Yannicka Alleno"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </div>
                  <div className="desc flex-fill">
                    <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                      <div className="name">
                        <p className="fw-semibold mb-0">Yannicka Alleno</p>
                        <p className="date fs-sm text-primary">
                          20/07/2021 17:31
                        </p>
                      </div>
                      <Link
                        to="#"
                        className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                      >
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          share
                        </span>
                      </Link>
                    </div>
                    <p>
                      ryzen first gen has peformance issues with windows 11 and
                      microsoft doesnt plan to fix it...
                    </p>
                    <div className="action d-flex flex-wrap gap-1 mt-3">
                      <Link
                        to="#"
                        className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center justify-content-center gap-1 rounded-circle"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          thumb_up
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="forum">
                <div className="forum-content d-flex align-items-start gap-3">
                  <div className="profile-avatar rounded-circle overflow-hidden">
                    <img
                      src="assets/img/dp-7.png"
                      alt="Martina Berasategui"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </div>
                  <div className="desc flex-fill">
                    <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                      <div className="name">
                        <p className="fw-semibold mb-0">Martina Berasategui</p>
                        <p className="date fs-sm text-primary">
                          20/07/2021 17:31
                        </p>
                      </div>
                      <Link
                        to="#"
                        className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                      >
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          share
                        </span>
                      </Link>
                    </div>
                    <p>
                      I was going to go with a core i5 9600k, but the Ryzen 5
                      1600 is cheaper, and from what I have read, is more
                      powerful.
                    </p>
                    <div className="action d-flex flex-wrap gap-1 mt-3">
                      <Link
                        to="#"
                        className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center justify-content-center gap-1 rounded-circle"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          thumb_up
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          <li className="forum">
            <div className="forum-content d-flex align-items-start gap-3">
              <div className="profile-avatar rounded-circle overflow-hidden">
                <img
                  src="assets/img/dp-8.png"
                  alt="Gordon Ramsay"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>
              <div className="desc flex-fill">
                <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                  <div className="name">
                    <p className="fw-semibold mb-0">Gordon Ramsay</p>
                    <p className="date fs-sm text-primary">20/07/2021 17:31</p>
                  </div>
                  <Link
                    to="#"
                    className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                  >
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      share
                    </span>
                  </Link>
                </div>
                <p>
                  I read on some website that the 1600 and 2600 was better, but,
                  they are both worse than the 9600k. And, anyways I already
                  have a B365 LGA 1151 Gen 2 motherboard, so, it's the most
                  logical upgrade.
                </p>
                <div className="action d-flex flex-wrap gap-1 mt-3">
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      thumb_up
                    </span>
                    <span className="d-block">Likes</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      2
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      chat
                    </span>
                    <span className="d-block">Comment</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      3
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </li>
          <li className="forum">
            <div className="forum-content d-flex align-items-start gap-3">
              <div className="profile-avatar rounded-circle overflow-hidden">
                <img
                  src="assets/img/dp-9.png"
                  alt="Alian Ducasse"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>
              <div className="desc flex-fill">
                <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                  <div className="name">
                    <p className="fw-semibold mb-0">Alian Ducasse</p>
                    <p className="date fs-sm text-primary">20/07/2021 17:31</p>
                  </div>
                  <Link
                    to="#"
                    className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                  >
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      share
                    </span>
                  </Link>
                </div>
                <p>
                  They are both worse than the 9600k. And, anyways I already
                  have a B365 LGA 1151 Gen 2 motherboard, so, it's the most
                  logical upgrade.
                </p>
                <div className="action d-flex flex-wrap gap-1 mt-3">
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      thumb_up
                    </span>
                    <span className="d-block">Likes</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      2
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      chat
                    </span>
                    <span className="d-block">Comment</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      3
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </li>
          <li className="forum">
            <div className="forum-content d-flex align-items-start gap-3">
              <div className="profile-avatar rounded-circle overflow-hidden">
                <img
                  src="assets/img/dp-2.png"
                  alt="Rohit Walia"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>
              <div className="desc flex-fill">
                <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                  <div className="name">
                    <p className="fw-semibold mb-0">Rohit Walia</p>
                    <p className="date fs-sm text-primary">20/07/2021 17:31</p>
                  </div>
                  <Link
                    to="#"
                    className="share d-flex align-items-center justify-content-center text-primary body-bg rounded-circle ms-auto"
                  >
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      share
                    </span>
                  </Link>
                </div>
                <p>
                  I read on some website that the 1600 and 2600 was better, but,
                  they are both worse than the 9600k. And, anyways I already
                  have a B365 LGA 1151 Gen 2 motherboard, so, it's the most
                  logical upgrade.
                </p>
                <div className="action d-flex flex-wrap gap-1 mt-3">
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      thumb_up
                    </span>
                    <span className="d-block">Likes</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      2
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className="px-3 py-2 fs-sm text-primary bg-white body-bg d-flex align-items-center gap-1 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      chat
                    </span>
                    <span className="d-block">Comment</span>
                    <span className="count d-block bg-secondary text-center rounded-circle text-white">
                      3
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ForumTab;
