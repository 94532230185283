import React from 'react';

const ServicesTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="services-tab-pane"
      role="tabpanel"
      aria-labelledby="services-tab"
      tabIndex="0"
    >
      <div className="services-container">
        <div className="service bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium d-flex gap-1 align-items-center mb-0">
              <span>Application Developer</span>
              <span className="d-block material-symbols-outlined icon-sm icon-fill text-success">
                check_circle
              </span>
            </p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                star
              </span>
              <span className="d-block fw-bold">4.5</span>
              <span className="d-block">(32)</span>
            </p>
          </div>
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="service bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium d-flex gap-1 align-items-center mb-0">
              <span>CMS Developer</span>
              <span className="d-block material-symbols-outlined icon-sm icon-fill text-success">
                check_circle
              </span>
            </p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                star
              </span>
              <span className="d-block fw-bold">4.5</span>
              <span className="d-block">(32)</span>
            </p>
          </div>
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="service bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium d-flex gap-1 align-items-center mb-0">
              <span>Mobile Application Design</span>
              <span className="d-block material-symbols-outlined icon-sm icon-fill text-success">
                check_circle
              </span>
            </p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                star
              </span>
              <span className="d-block fw-bold">4.5</span>
              <span className="d-block">(32)</span>
            </p>
          </div>
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="service bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium d-flex gap-1 align-items-center mb-0">
              <span>Application Developer</span>
              <span className="d-block material-symbols-outlined icon-sm icon-fill text-success">
                check_circle
              </span>
            </p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                star
              </span>
              <span className="d-block fw-bold">4.5</span>
              <span className="d-block">(32)</span>
            </p>
          </div>
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="service bg-white p-4 rounded-10 shadow-sm">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium d-flex gap-1 align-items-center mb-0">
              <span>Application Developer</span>
              <span className="d-block material-symbols-outlined icon-sm icon-fill text-success">
                check_circle
              </span>
            </p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-xs text-black bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                star
              </span>
              <span className="d-block fw-bold">4.5</span>
              <span className="d-block">(32)</span>
            </p>
          </div>
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesTab;
