import React from 'react';

const ScheduleTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="schedule-tab-pane"
      role="tabpanel"
      aria-labelledby="schedule-tab"
      tabIndex="0"
    >
      <div className="schedule container bg-white p-4 rounded-10 shadow-sm"></div>
    </div>
  );
};

export default ScheduleTab;
