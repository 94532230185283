import React from 'react';

const MeetingTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="meeting-tab-pane"
      role="tabpanel"
      aria-labelledby="meeting-tab"
      tabIndex="0"
    >
      <div className="meeting-container bg-white p-4 rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-gray mb-4">Schedule</p>
      </div>
    </div>
  );
};

export default MeetingTab;
