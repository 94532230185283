import React from 'react';
import { Link } from 'react-router-dom';

const GoalsTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="goals-tab-pane"
      role="tabpanel"
      aria-labelledby="goals-tab"
      tabIndex="0"
    >
      <div className="goal-container">
        <div className="row gx-2">
          <div className="col-lg-2 d-flex flex-column">
            <div className="bg-white p-2 rounded-5 shadow-sm fs-xs fw-medium d-flex gap-2 align-items-center mb-3">
              <span className="d-block material-symbols-outlined icon-md">
                folder
              </span>
              <p>Empatizar</p>
            </div>
            <div className="task-list flex-fill p-2 fs-xs fw-medium bg-white rounded-10 shadow-sm">
              <ul className="tasks">
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <Link
                to="#"
                className="w-100 d-flex align-items-center justify-content-center gap-1 px-3 py-2 bg-gray-200 fs-xs fw-medium text-light rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  add_circle
                </span>
                <span className="d-block">Add Task</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 d-flex flex-column">
            <div className="bg-white p-2 rounded-5 shadow-sm fs-xs fw-medium d-flex gap-2 align-items-center mb-3">
              <span className="d-block material-symbols-outlined icon-md">
                folder
              </span>
              <p>Definir</p>
            </div>
            <div className="task-list flex-fill p-2 fs-xs fw-medium bg-white rounded-10 shadow-sm">
              <ul className="tasks">
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <Link
                to="#"
                className="w-100 d-flex align-items-center justify-content-center gap-1 px-3 py-2 bg-gray-200 fs-xs fw-medium text-light rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  add_circle
                </span>
                <span className="d-block">Add Task</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 d-flex flex-column">
            <div className="bg-white p-2 rounded-5 shadow-sm fs-xs fw-medium d-flex gap-2 align-items-center mb-3">
              <span className="d-block material-symbols-outlined icon-md">
                folder
              </span>
              <p>Idear</p>
            </div>
            <div className="task-list flex-fill p-2 fs-xs fw-medium bg-white rounded-10 shadow-sm">
              <ul className="tasks">
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <Link
                to="#"
                className="w-100 d-flex align-items-center justify-content-center gap-1 px-3 py-2 bg-gray-200 fs-xs fw-medium text-light rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  add_circle
                </span>
                <span className="d-block">Add Task</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 d-flex flex-column">
            <div className="bg-white p-2 rounded-5 shadow-sm fs-xs fw-medium d-flex gap-2 align-items-center mb-3">
              <span className="d-block material-symbols-outlined icon-md">
                folder
              </span>
              <p>Prototipar</p>
            </div>
            <div className="task-list flex-fill p-2 fs-xs fw-medium bg-white rounded-10 shadow-sm">
              <ul className="tasks">
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <Link
                to="#"
                className="w-100 d-flex align-items-center justify-content-center gap-1 px-3 py-2 bg-gray-200 fs-xs fw-medium text-light rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  add_circle
                </span>
                <span className="d-block">Add Task</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 d-flex flex-column">
            <div className="bg-white p-2 rounded-5 shadow-sm fs-xs fw-medium d-flex gap-2 align-items-center mb-3">
              <span className="d-block material-symbols-outlined icon-md">
                folder
              </span>
              <p>Testear</p>
            </div>
            <div className="task-list flex-fill p-2 fs-xs fw-medium bg-white rounded-10 shadow-sm">
              <ul className="tasks">
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <Link
                to="#"
                className="w-100 d-flex align-items-center justify-content-center gap-1 px-3 py-2 bg-gray-200 fs-xs fw-medium text-light rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  add_circle
                </span>
                <span className="d-block">Add Task</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 d-flex flex-column">
            <div className="bg-white p-2 rounded-5 shadow-sm fs-xs fw-medium d-flex gap-2 align-items-center mb-3">
              <span className="d-block material-symbols-outlined icon-md">
                folder
              </span>
              <p>Implementar</p>
            </div>
            <div className="task-list flex-fill p-2 fs-xs fw-medium bg-white rounded-10 shadow-sm">
              <ul className="tasks">
                <li className="task p-2 border border-1 border-gray-500 rounded-10 mb-2">
                  <p className="mb-1">
                    This offer is structured into two primary segments
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      group
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      stars
                    </span>
                    <span className="d-block">100</span>
                  </p>
                  <p className="d-flex align-items-center gap-1 mb-1">
                    <span className="d-block material-symbols-outlined icon-sm">
                      pie_chart
                    </span>
                    <span className="d-block">10 Avg</span>
                  </p>
                  <div className="action d-flex gap-1">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        forum
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        ballot
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_up
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center text-black bg-gray-600 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined fs-sm">
                        thumb_down
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <Link
                to="#"
                className="w-100 d-flex align-items-center justify-content-center gap-1 px-3 py-2 bg-gray-200 fs-xs fw-medium text-light rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  add_circle
                </span>
                <span className="d-block">Add Task</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsTab;
