import React from 'react';
import { Link } from 'react-router-dom';
import * as url from 'helper/UrlHelper';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const AccountDetailsLeftComponent = ({
  userImage,
  userName,
  userPosition,
  userRating,
  totalNoReviews,
  userCity,
  userState,
  userCountry,
  userRate,
  userTags,
  isConnect,
  connectStatus,
  connectedDate,
  requestDate,
  unfriendStatus,
  resumeContactOwner,
  resumeContactMember,
  isProfileOwner,
  cancelRequestHandler,
  unfriendHandler,
  resumeContactHandler,
}) => {
  const { t } = useTranslation(); // for translations
  return (
    <div className="col-lg-3 mb-4">
      <aside
        id="account_sidebar"
        className="bg-white text-center p-4 rounded-10 shadow-sm"
      >
        <div className="profile-avatar bg-light rounded-circle overflow-hidden mb-3 mx-auto">
          <img
            src={
              userImage === ''
                ? assetImages.defaultUser
                : url.SERVER_URL + userImage
            }
            alt={userName}
            className="w-100 h-100 object-cover object-center"
          />
        </div>
        <h3 className="mb-1">{userName}</h3>
        <p className="designation fs-sm text-gray mb-2">{userPosition}</p>
        <p className="d-inline-flex gap-1 align-items-center px-3 py-2 fs-sm fw-medium text-black bg-gray-100 rounded-5 mb-4">
          <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
            star
          </span>
          <span className="d-block">{userRating}</span>
          <span className="d-block">({totalNoReviews})</span>
        </p>
        {userCity ||
          userState ||
          (userCountry && (
            <p className="locatino d-flex gap-1 justify-content-center align-items-center fs-sm text-gray mb-1">
              <span className="d-block material-symbols-outlined icon-sm">
                location_on
              </span>
              <span>
                {userCity} ,{userState} ,{userCountry}
              </span>
            </p>
          ))}
        {userRate && (
          <p className="wallet d-flex gap-1 justify-content-center align-items-center fs-sm text-gray mb-4">
            <span className="d-block material-symbols-outlined icon-sm">
              account_balance_wallet
            </span>
            <span>${userRate}/Hour</span>
          </p>
        )}

        {/* --- social links ---- */}
        <ul className="social-links d-flex flex-wrap justify-content-center gap-2 mb-4">
          <li>
            <Link
              to="#"
              // target="_blank"
              className="d-flex align-items-center justify-content-center border border-gray-300 rounded-8"
              rel="noreferrer"
            >
              <svg width="25" height="24">
                <use
                  xlinkHref="/assets/svg/sprite.svg#facebook"
                  href="/assets/svg/sprite.svg#facebook"
                ></use>
              </svg>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              // target="_blank"
              className="d-flex align-items-center justify-content-center border border-gray-300 rounded-8"
              rel="noreferrer"
            >
              <svg width="25" height="19">
                <use
                  xlinkHref="/assets/svg/sprite.svg#gmail"
                  href="/assets/svg/sprite.svg#gmail"
                ></use>
              </svg>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              // target="_blank"
              className="d-flex align-items-center justify-content-center border border-gray-300 rounded-8"
              rel="noreferrer"
            >
              <svg width="19" height="18">
                <use
                  xlinkHref="/assets/svg/sprite.svg#linkedin"
                  href="/assets/svg/sprite.svg#linkedin"
                ></use>
              </svg>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              // target="_blank"
              className="d-flex align-items-center justify-content-center border border-gray-300 rounded-8"
              rel="noreferrer"
            >
              <svg width="19" height="20">
                <use
                  xlinkHref="/assets/svg/sprite.svg#pinterest"
                  href="/assets/svg/sprite.svg#pinterest"
                ></use>
              </svg>
            </Link>
          </li>
        </ul>

        {/* ----- tags ------- */}
        <ul className="tags d-flex flex-wrap gap-2 text-primary mb-3">
          {userTags.map((tag, index) => {
            return (
              <li className="body-bg px-2 py-1 rounded-5" key={index}>
                {tag.title}
              </li>
            );
          })}
        </ul>

        {/* ------ action buttons ---------- */}
        <div className="action">
          <ul className="list-style-none d-flex align-items-center gap-2 flex-wrap justify-content-center">
            {/* ------ recommended ---------- */}
            {connectStatus || isProfileOwner ? (
              <li>
                <Link
                  to="#"
                  className="btn btn-outline-secondary btn-sm flex-fill"
                  // data-bs-toggle="modal"
                  // data-bs-target="#schedule_meet_step_1"
                >
                  {t('Recommended')}
                </Link>
              </li>
            ) : null}

            {/* ------ give review ---------- */}
            {connectStatus && (
              <li>
                <Link
                  to="#"
                  className="btn btn-outline-light btn-sm flex-fill"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback_mdl"
                >
                  {t('Give Review')}
                </Link>
              </li>
            )}

            {/* ------ friends ---------- */}

            {isProfileOwner ? null : isConnect ? (
              <li className="dropdown">
                <Link
                  to="#"
                  className="btn btn-outline-light btn-sm flex-fill"
                  data-bs-toggle={
                    connectStatus
                      ? 'dropdown'
                      : unfriendStatus === '0'
                      ? 'dropdown'
                      : resumeContactOwner === '1' ||
                        resumeContactMember === '1'
                      ? 'dropdown'
                      : '#'
                  }
                >
                  {t('Friends')}
                </Link>
                <ul className="dropdown-menu list-style-none p-2 bg-gray-100 text-capitalize border-0 rounded-10 overflow-hidden end-0 friends_dropdown shadow-sm ">
                  {connectStatus && (
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 py-1 text-black"
                        onClick={unfriendHandler}
                      >
                        <i className="d-inline-block material-symbols-outlined icon-md">
                          person_remove
                        </i>
                        <span>{t('Unfriend')}</span>
                      </Link>
                    </li>
                  )}

                  {connectStatus ? null : unfriendStatus === '0' ? (
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 py-1 text-black"
                        onClick={cancelRequestHandler}
                      >
                        <i className="d-inline-block material-symbols-outlined icon-md text-danger">
                          close
                        </i>
                        <span>{t('Cancel Request')}</span>
                      </Link>
                    </li>
                  ) : resumeContactOwner === '1' ||
                    resumeContactMember === '1' ? (
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 py-1 text-black"
                        onClick={resumeContactHandler}
                      >
                        <i className="d-inline-block material-symbols-outlined icon-md text-danger">
                          block
                        </i>
                        <span>{t('Resume Contact')}</span>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </li>
            ) : (
              <li>
                <Link
                  to="#"
                  className="btn btn-outline-secondary btn-sm flex-fill"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_modal"
                >
                  {t('Connect')}
                </Link>
              </li>
            )}

            {/* ------ tags ---------- */}
            {/* <li>
              <Link
                to="#"
                className="btn btn-outline-secondary btn-sm flex-fill"
                data-bs-target="#tags_modal"
                data-bs-toggle="modal"
              >
                {t('Tags')}
              </Link>
            </li> */}

            {/* ------ connected on ---------- */}
            {connectedDate !== '' ? (
              <li>
                <p className="d-flex align-items-center justify-content-center">
                  <span>
                    {t('Connected on')} {connectedDate}
                  </span>
                </p>
              </li>
            ) : (
              requestDate !== '' && (
                <li>
                  <p className="d-flex align-items-center justify-content-center">
                    <span>
                      {t('Request on')} {requestDate}
                    </span>
                  </p>
                </li>
              )
            )}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default AccountDetailsLeftComponent;
