import React from 'react';
import { useTranslation } from 'react-i18next';
import * as url from '../../../../helper/UrlHelper';
// import { Link } from 'react-router-dom';

const TeamTab = ({ companyMemberList }) => {
  const { t } = useTranslation(); // for translations
  return (
    <div
      className="tab-pane fade"
      id="people-tab-pane"
      role="tabpanel"
      aria-labelledby="people-tab"
      tabIndex="0"
    >
      {/* <div className="founder">
        <h6 className="d-flex align-items-center gap-2 mb-3">
          <span>Founders</span>
          <span className="bg-light fs-xs fw-medium text-white px-2 py-1 rounded-90">
            2
          </span>
        </h6>
        <div className="row gx-3 mb-4">
          <div className="col-lg-6 mb-3 mb-lg-0">
            <div className="founder-content bg-white rounded-5 shadow-sm">
              <div className="p-3 border-bottom border-1">
                <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                  <div className="title">
                    <h6>Jack Conte</h6>
                    <p className="text-primary">Founder</p>
                  </div>
                  <div className="img-container rounded-circle overflow-hidden">
                    <img
                      src="/assets/img/dp-6.png"
                      alt="Jack Conte"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </div>
                </div>
                <p>
                  Founder <Link to="#">@Indra Air</Link>
                </p>
              </div>
              <div className="p-3">
                <p className="fs-xs fw-medium mb-2">Background</p>
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <div>
                    <p className="mb-0">Indra Air</p>
                    <p className="font-secondary fs-xs">Founder</p>
                  </div>
                  <Link to="#" className="icon">
                    <img
                      src="/assets/img/camera-icon.png"
                      alt="Indra Air"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3 mb-lg-0">
            <div className="founder-content bg-white rounded-5 shadow-sm">
              <div className="p-3 border-bottom border-1">
                <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                  <div className="title">
                    <h6>Jack Conte</h6>
                    <p className="text-primary">Founder</p>
                  </div>
                  <div className="img-container rounded-circle overflow-hidden">
                    <img
                      src="/assets/img/dp-6.png"
                      alt="Jack Conte"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </div>
                </div>
                <p>
                  Founder <Link to="#">@Indra Air</Link>
                </p>
              </div>
              <div className="p-3">
                <p className="fs-xs fw-medium mb-2">Background</p>
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <div>
                    <p className="mb-0">Indra Air</p>
                    <p className="font-secondary fs-xs">Founder</p>
                  </div>
                  <Link to="#" className="icon">
                    <img
                      src="/assets/img/camera-icon.png"
                      alt="Indra Air"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="team-container">
        <h6 className="d-flex align-items-center gap-2 mb-3">
          <span>{t('Team')}</span>
          <span className="bg-light fs-xs fw-medium text-white px-2 py-1 rounded-90">
            {companyMemberList.length}
          </span>
        </h6>
        <div className="row gx-3">
          {companyMemberList.map((member, index) => {
            return (
              <div className="col-md-6 col-lg-4 mb-3" key={index}>
                <div className="team-content bg-white p-4 rounded-10 shadow-sm">
                  <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                    <div className="title font-secondary">
                      <h6>{member.memebername}</h6>
                      <p className="fs-sm">{member.rolestring}</p>
                    </div>
                    {/* <Link
                      to="#"
                      className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined icon-fill icon-md">
                        person
                      </span>
                    </Link> */}
                    <img
                      src={url.SERVER_URL + member?.memberimage}
                      alt={member.memebername}
                      className="rounded-circle"
                      height={40}
                      width={40}
                    />
                  </div>
                  <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                    {member.company.name}
                  </p>
                </div>
              </div>
            );
          })}

          {/* <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Gab Saper</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Designer
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Investor
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Harry Potter</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Marketing
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">New York</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Sales
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Taryn Arnold</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Sales
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">New York</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Engineering
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Gab Saper</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Designer
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Investor
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Harry Potter</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Marketing
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">New York</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Sales
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Taryn Arnold</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Sales
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">New York</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Engineering
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Gab Saper</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Designer
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="team-content bg-white p-4 rounded-10 shadow-sm">
              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <div className="title font-secondary">
                  <h6>Jack Conte</h6>
                  <p className="fs-sm">Madrid</p>
                </div>
                <Link
                  to='#'
                  className="person d-flex align-items-center justify-content-center bg-gray-200 rounded-circle"
                >
                  <span className="d-block material-symbols-outlined icon-fill icon-md">
                    person
                  </span>
                </Link>
              </div>
              <p className="tag d-inline-block font-secondary fs-sm fw-semibold text-light px-2 py-1 border border-1 rounded-90">
                Investor
              </p>
            </div>
          </div> */}
        </div>
        {/* <ol className="pagination align-items-center gap-2 fw-semibold mt-4">
          <li className="prev me-3">
            <Link to="#" className="d-block text-black">
              <span className="d-block material-symbols-outlined">
                arrow_back
              </span>
            </Link>
          </li>
          <li className="page active">
            <span className="d-flex align-items-center justify-content-center rounded-circle">
              1
            </span>
          </li>
          <li className="page">
            <Link
              to="#"
              className="d-flex align-items-center justify-content-center rounded-circle"
            >
              2
            </Link>
          </li>
          <li className="page">
            <Link
              to="#"
              className="d-flex align-items-center justify-content-center rounded-circle"
            >
              3
            </Link>
          </li>
          <li className="page">
            <span className="d-flex align-items-center justify-content-center rounded-circle">
              ...
            </span>
          </li>
          <li className="page">
            <Link
              to="#"
              className="d-flex align-items-center justify-content-center rounded-circle"
            >
              26
            </Link>
          </li>
          <li className="page">
            <Link
              to="#"
              className="d-flex align-items-center justify-content-center rounded-circle"
            >
              26
            </Link>
          </li>
          <li className="next ms-3">
            <Link to="#" className="d-block text-black">
              <span className="d-block material-symbols-outlined">
                arrow_forward
              </span>
            </Link>
          </li>
        </ol> */}
      </div>
    </div>
  );
};

export default TeamTab;
