import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import AboutTab from '../Tabs/AboutTab/AboutTab';
import ProductTab from '../Tabs/ProductTab/ProductTab';
import GalleryTab from '../Tabs/GalleryTab/GalleryTab';
import MembersTab from '../Tabs/MembersTab/MembersTab';
import ForumTab from '../Tabs/ForumTab/ForumTab';
import MeetingTab from '../Tabs/MeetingTab/MeetingTab';
import ReviewTab from 'Components/Common/Tabs/ReviewTab';

const EventDetailsRightComponent = ({
  eventDetails,
  eventSummary,
  eventCategories,
  eventInterests,
  eventSearchings,
  eventDocs,
  galleryImages,
  memberList,
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div className="col-lg-8">
      <div className="event-details-container">
        <ul
          className="nav nav-tabs gap-2 border-0 mb-3"
          id="eventTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90 active"
              id="about-tab"
              data-bs-toggle="tab"
              data-bs-target="#about-tab-pane"
              type="button"
              role="tab"
              aria-controls="about-tab-pane"
              aria-selected="true"
            >
              {t('About')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="products-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#products-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="products-tab-pane"
                    aria-selected="false"
                  >
                    Products
                  </Link>
                </li> */}

          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="members-tab"
              data-bs-toggle="tab"
              data-bs-target="#members-tab-pane"
              type="button"
              role="tab"
              aria-controls="members-tab-pane"
              aria-selected="false"
            >
              {t('Members')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="gallery-tab"
              data-bs-toggle="tab"
              data-bs-target="#gallery-tab-pane"
              type="button"
              role="tab"
              aria-controls="gallery-tab-pane"
              aria-selected="false"
            >
              {t('Gallery')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="forum-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#forum-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="forum-tab-pane"
                    aria-selected="false"
                  >
                    Forum
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                    id="meeting-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#meeting-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="meeting-tab-pane"
                    aria-selected="false"
                  >
                    Meeting
                  </Link>
                </li> */}

          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="reviews-tab"
              data-bs-toggle="tab"
              data-bs-target="#reviews-tab-pane"
              type="button"
              role="tab"
              aria-controls="reviews-tab-pane"
              aria-selected="false"
            >
              {t('Reviews')}
            </Link>
          </li>
        </ul>
        {/* --------- Tab Contents --------- */}
        <div className="tab-content" id="eventTabContent">
          {/* ------------------ About Tab ------------------ */}
          <AboutTab
            eventDetails={eventDetails}
            eventSummary={eventSummary}
            eventCategories={eventCategories}
            eventInterests={eventInterests}
            eventSearchings={eventSearchings}
            eventDocs={eventDocs}
          />

          {/* ------------------ Products Tab ------------------ */}
          <ProductTab />

          {/* ------------------ Gallery Tab ------------------ */}
          <GalleryTab galleryImages={galleryImages} />

          {/* ------------------ Meeting Tab ------------------ */}
          <MembersTab memberList={memberList} />

          {/* ------------------ Forum Tab ------------------ */}
          <ForumTab />

          {/* ------------------ Members Tab ------------------ */}
          <MeetingTab />

          {/* -------------- review tab ----------------- */}
          <ReviewTab
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetailsRightComponent;
