/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import ReviewModal from 'Components/Common/Modals/ReviewModal';
import ConnectModal from 'Components/AccountComponents/Modals/ConnectModal';
import SuccessModal from 'Components/Common/Modals/SuccessModal';
import CourseDetailsTopComponent from '../CourseDetailsTopComponent/CourseDetailsTopComponent';
import CourseDetailsBottomComponent from '../CourseDetailsBottomComponent/CourseDetailsBottomComponent';

const CoursesDetailsBodyComponent = () => {
  const token = localStorage.getItem('token');
  //  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const params = useParams();

  const [courseDetails, setCourseDetails] = useState({});

  //  const [isReviewLoading, setIsReviewLoading] = useState(false);

  const [ratingCount, setRatingCount] = useState('0.0');
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  //  const [isJoining, setIsJoining] = useState(false);

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setCourseDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    //  setIsReviewLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=course&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log('review list response', response);

      //  setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount('');
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for join course
  //  const joinCourseHandler = async () => {
  //    const todayValue = new Date().toISOString().split('T')[0];

  //    try {
  //     //  setIsJoining(true);

  //      let courseJoinData = {
  //        user: userInfo._id,
  //        course: params.id,
  //        company: null,
  //        role: null,
  //        useremail: userInfo.email,
  //        memberstatus: '1',
  //        requestdate: todayValue,
  //      };

  //      console.log('Join course Data------>', courseJoinData);

  //      let requestURL =
  //        url.API_BASE_URL + url.API_JOIN_COURSE + `?token=${token}`;

  //      const response = await postData(requestURL, courseJoinData);

  //     //  setIsJoining(false);

  //      console.log(response);

  //      if (response.status) {
  //        // if (secretParam) {
  //        //   const baseURL = fullURL.split("?")[0];
  //        //   window.location.href = baseURL;
  //        // }
  //        const bootstrapModal = new bootstrap.Modal(
  //          document.getElementById('course_join_success_mdl')
  //        );
  //        bootstrapModal.show();
  //      }
  //    } catch (error) {
  //      console.log(error.message);
  //    }
  //  };

  const afterApiCall = () => {
    getCourseDetails();
    getReviewsLists();
  };

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getReviewsLists();
    }
  }, [params.id]);

  return (
    <section className="course-details-wrapper header-spacing">
      {/* ---------- top component ----------- */}
      <CourseDetailsTopComponent courseDetails={courseDetails} />

      {/* --------- bottom component --------- */}
      <CourseDetailsBottomComponent
        courseDetails={courseDetails}
        ratingCount={ratingCount}
        reviewsList={reviewsList}
        ratingPercentArr={ratingPercentArr}
      />

      {/* --------------- feedback modal ----------- */}
      <ReviewModal moduleName="appuser" />

      {/* ------------- connect modal ----------- */}
      <ConnectModal />

      {/*  ------------- greetings modal ----------- */}
      <SuccessModal afterModalClose={afterApiCall} />
    </section>
  );
};

export default CoursesDetailsBodyComponent;
