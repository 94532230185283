import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HomeOffers = () => {
  const { t } = useTranslation(); // for translations
  return (
    <section className="best-offers py-50 py-md-70 bg-light bg-opacity-2">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Best Offers')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="#" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <div className="best-offers-container">
          <div className="row">
            <div className="col-lg-3 mb-4">
              <div className="offer-content h-100 bg-primary d-flex flex-column text-center text-white rounded-10 overflow-hidden">
                <div className="img-container">
                  <Link to="#">
                    <img
                      src="assets/img/best-offer-1.jpg"
                      alt="A course for the future developers"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-4 flex-fill d-flex flex-column justify-content-center">
                  <h3 className="mb-3">
                    <Link to="#" className="text-white">
                      A course for the future developers
                    </Link>
                  </h3>
                  <ul className="info d-flex flex-wrap align-items-center justify-content-center fs-xs fw-medium">
                    <li>15th March 2023</li>
                    <li>50% OFF</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="offer-content h-100 bg-white d-flex flex-column text-center text-primary rounded-10 overflow-hidden">
                <div className="img-container">
                  <Link to="#">
                    <img
                      src="assets/img/best-offer-2.jpg"
                      alt="A course for the future developers"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-4 flex-fill d-flex flex-column justify-content-center fs-xs fw-medium">
                  <h3 className="mb-3">
                    <Link to="#" className="text-primary">
                      A course for the future developers
                    </Link>
                  </h3>
                  <ul className="info d-flex flex-wrap align-items-center justify-content-center">
                    <li>15th March 2023</li>
                    <li>50% OFF</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="offer-content h-100 d-flex align-items-center bg-primary text-center text-white rounded-10 overflow-hidden">
                <div className="desc p-4">
                  <h3 className="mb-3">
                    <Link to="#" className="text-white">
                      A course for the future developers
                    </Link>
                  </h3>
                  <ul className="info d-flex flex-wrap align-items-center justify-content-center fs-xs fw-medium">
                    <li>15th March 2023</li>
                    <li>50% OFF</li>
                  </ul>
                </div>
                <div className="img-container">
                  <Link to="#" className="d-block h-100">
                    <img
                      src="assets/img/best-offer-3.jpg"
                      alt="A course for the future developers"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="offer-content h-100 d-flex align-items-center bg-white text-center text-primary rounded-10 overflow-hidden">
                <div className="desc p-4">
                  <h3 className="mb-3">
                    <Link to="#" className="text-primary">
                      A course for the future developers
                    </Link>
                  </h3>
                  <ul className="info d-flex flex-wrap align-items-center justify-content-center fs-xs fw-medium">
                    <li>15th March 2023</li>
                    <li>50% OFF</li>
                  </ul>
                </div>
                <div className="img-container">
                  <Link to="#" className="d-block h-100">
                    <img
                      src="assets/img/best-offer-4.jpg"
                      alt="A course for the future developers"
                      className="w-100 h-100 object-cover object-center"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4 mb-lg-0">
              <div className="offer-content h-100 bg-primary d-flex flex-column text-center text-white rounded-10 overflow-hidden">
                <div className="img-container">
                  <Link to="#">
                    <img
                      src="assets/img/best-offer-5.jpg"
                      alt="A course for the future developers"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-4 flex-fill d-flex flex-column justify-content-center">
                  <h3 className="mb-3">
                    <Link to="#" className="text-white">
                      A course for the future developers
                    </Link>
                  </h3>
                  <ul className="info d-flex flex-wrap align-items-center justify-content-center fs-xs fw-medium">
                    <li>15th March 2023</li>
                    <li>50% OFF</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="offer-content h-100 bg-white d-flex flex-column text-center text-primary rounded-10 overflow-hidden">
                <div className="img-container">
                  <Link to="#">
                    <img
                      src="assets/img/best-offer-6.jpg"
                      alt="A course for the future developers"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc p-4 flex-fill d-flex flex-column justify-content-center">
                  <h3 className="mb-3">
                    <Link to="#" className="text-primary">
                      A course for the future developers
                    </Link>
                  </h3>
                  <ul className="info d-flex flex-wrap align-items-center justify-content-center fs-xs fw-medium">
                    <li>15th March 2023</li>
                    <li>50% OFF</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeOffers;
