/* eslint-disable */
import React from 'react';

import CommonHeader from 'Components/Common/Headers/CommonHeader';
import ChallengesLists from 'Components/ChallengesComponents/ChallengesLandingComponents/ChallengesLists/ChallengesLists';
import Footer from 'Components/Common/Footer/Footer';

const ChallengesLanding = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="challenges" />

      <div id="main_content" className="position-relative">
        {/* ----- challenges section ------- */}
        <ChallengesLists />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default ChallengesLanding;
