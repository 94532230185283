/* eslint-disable */
import React from 'react';
import CommonHeader from 'Components/Common/Headers/CommonHeader';
import Footer from 'Components/Common/Footer/Footer';
import CompaniesDetailsBodyComponent from 'Components/CompaniesComponents/CompaniesDetailsComponents/CompaniesDetailsBodyComponent/CompaniesDetailsBodyComponent';

const CompaniesDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="companies" />

      <div id="main_content" className="position-relative">
        <CompaniesDetailsBodyComponent />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default CompaniesDetails;
