import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { useTranslation } from 'react-i18next';
import OverviewTab from '../Tabs/OverviewTab/OverviewTab';
import PortfolioTab from '../Tabs/PortfolioTab/PortfolioTab';
import ServicesTab from '../Tabs/ServicesTab/ServicesTab';
import SurveyTab from '../Tabs/SurveyTab/SurveyTab';
import ScheduleTab from '../Tabs/ScheduleTab/ScheduleTab';
import ReviewTab from 'Components/Common/Tabs/ReviewTab';

const AccountDetailsRightComponent = ({
  // -- overview tab props --
  expertDetails,
  expertSkills,
  expertEmail,
  expertLanguage,
  expertExperiences,
  expertEducations,

  // -- review tab props --
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div className="col-lg-9">
      <div className="account-details-container">
        <ul
          className="nav nav-tabs gap-2 border-0 mb-3"
          id="accountTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90 active"
              id="overview-tab"
              data-bs-toggle="tab"
              data-bs-target="#overview-tab-pane"
              type="button"
              role="tab"
              aria-controls="overview-tab-pane"
              aria-selected="true"
            >
              {t('Overview')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="portfolio-tab"
              data-bs-toggle="tab"
              data-bs-target="#portfolio-tab-pane"
              type="button"
              role="tab"
              aria-controls="portfolio-tab-pane"
              aria-selected="false"
            >
          {t('Portfolio)}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="services-tab"
              data-bs-toggle="tab"
              data-bs-target="#services-tab-pane"
              type="button"
              role="tab"
              aria-controls="services-tab-pane"
              aria-selected="false"
            >
              Services
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="survey-tab"
              data-bs-toggle="tab"
              data-bs-target="#survey-tab-pane"
              type="button"
              role="tab"
              aria-controls="survey-tab-pane"
              aria-selected="false"
            >
              Survey
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="schedule-tab"
              data-bs-toggle="tab"
              data-bs-target="#schedule-tab-pane"
              type="button"
              role="tab"
              aria-controls="schedule-tab-pane"
              aria-selected="false"
            >
              Schedule
            </Link>
          </li> */}
          <li className="nav-item" role="presentation">
            <Link
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="reviews-tab"
              data-bs-toggle="tab"
              data-bs-target="#reviews-tab-pane"
              type="button"
              role="tab"
              aria-controls="reviews-tab-pane"
              aria-selected="false"
            >
              {t('Reviews')}
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="accountTabContent">
          {/* ----------- Overview Tab ----------- */}
          <OverviewTab
            expertDetails={expertDetails}
            expertSkills={expertSkills}
            expertEmail={expertEmail}
            expertLanguage={expertLanguage}
            expertEducations={expertEducations}
            expertExperiences={expertExperiences}
          />

          {/* ----------- Portfolio Tab ----------- */}
          <PortfolioTab />

          {/* ----------- Services Tab ----------- */}
          <ServicesTab />

          {/* ----------- Survey Tab ----------- */}
          <SurveyTab />

          {/* ----------- Schedule Tab ----------- */}
          <ScheduleTab />

          {/* ----------- Reviews Tab ----------- */}
          <ReviewTab
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
            moduleName="accounts"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsRightComponent;
