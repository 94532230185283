import React from 'react';
import { Link } from 'react-router-dom';
import * as url from '../../../../helper/UrlHelper';
import { useTranslation } from 'react-i18next';

const CompaniesDetailsLeftComponent = ({
  companyLogo,
  companyName,
  companyCity,
  companyState,
  companyCountry,
  companyTags,
  ratingCount,
  reviewsList,
  companySearchings,
  companyInterests,
}) => {
  const { t } = useTranslation(); // for translations

  return (
    <div className="col-lg-4">
      <aside className="company-sidebar">
        <div className="company-content p-3 bg-white rounded-10 shadow-sm">
          <img
            src={url.SERVER_URL + companyLogo}
            alt="Santander"
            className="img-fluid mb-2"
          />
          <h3>{companyName}</h3>
          <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-2">
            <li className="tag">
              <Link
                to="#"
                className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                  star
                </span>
                <span className="d-block fs-sm fw-bold">{ratingCount}</span>
                <span className="d-block fs-xs">({reviewsList.length})</span>
              </Link>
            </li>
            <li className="tag">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  location_on
                </span>
                <span className="d-block">
                  {companyCity}, {companyState}, {companyCountry}
                </span>
              </Link>
            </li>
          </ul>

          <ul className="tags d-flex flex-wrap gap-2 text-primary mb-3">
            {companyTags.map((tag, index) => {
              return (
                <li className="body-bg px-2 py-1 rounded-5" key={index}>
                  {tag.title}
                </li>
              );
            })}
          </ul>

          {/* <p className="text-dark">Business Consulting, Technology System</p> */}
          <div className="tag-container pt-5 mt-3 border-top">
            <div className="search d-flex gap-3 align-items-center mb-2">
              <p className="fs-sm fw-medium">{t('Search')}</p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                {companySearchings.map((searching, index) => {
                  return (
                    <li className="tag" key={index}>
                      <Link
                        to="#"
                        className="d-block text-gray bg-gray-100 rounded-90"
                      >
                        {searching.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="interest d-flex gap-3 align-items-center">
              <p className="fs-sm fw-medium">{t('Interest')}</p>
              <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                {companyInterests.map((interest, index) => {
                  return (
                    <li className="tag" key={index}>
                      <Link
                        to="#"
                        className="d-block text-gray bg-gray-100 rounded-90"
                      >
                        {interest.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default CompaniesDetailsLeftComponent;
