/* eslint-disable */
import React from 'react';
import CommonHeader from '../../../Components/Common/Headers/CommonHeader';
import SuccessStoriesList from '../../../Components/SuccessStoriesComponents/SuccessStoriesLandingComponents/SuccessStoriesList/SuccessStoriesList';
import Footer from '../../../Components/Common/Footer/Footer';

const SuccessStoriesLanding = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="success" />

      <div id="main_content" className="position-relative">
        {/*  ----- success stories section ------- */}
        <SuccessStoriesList />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default SuccessStoriesLanding;
