/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import Select from 'react-select';
import sorting from 'Data/Sorting.json';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CourseList = () => {
  const { t } = useTranslation(); // for translations

  const token = localStorage.getItem('token');

  //  const placeHolderCount = [1, 2];

  //  const [isLoading, setIsLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);

  //get course list
  const getAllCourses = async () => {
    try {
      //  setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_COURSES + `?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      //  setIsLoading(false);

      if (response.status) {
        setCourseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);
  return (
    <section className="courses-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="courses-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              {t('Found')} <span>253</span> {t('result')}
            </p>
            <div className="d-flex gap-3">
              <Select
                placeholder={t('Select Position')}
                options={sorting}
                // value={params}
                // onChange={val => {
                //   setParams(val);
                // }}
              />
              <Link
                to="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            {courseList.map((courseData, index) => {
              console.log(`courseData`, courseData);
              return (
                <div className="col-md-6 col-lg-4 mb-4" key={index}>
                  <div className="course-content bg-white p-4 rounded-10 shadow-sm">
                    <h3>
                      <Link
                        to="/courses-details"
                        className="text-black"
                        tabIndex="0"
                      >
                        {courseData.title}
                      </Link>
                    </h3>
                    <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                      <span className="d-block material-symbols-outlined icon-sm">
                        event_note
                      </span>
                      <span className="d-block">{courseData.coursedate}</span>
                    </p>
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                      <li className="tag">
                        <Link
                          to="#"
                          className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                          tabIndex="0"
                        >
                          <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                            star
                          </span>
                          <span className="d-block fs-sm fw-bold">
                            {courseData.totalratings}
                          </span>
                          <span className="d-block fs-xs">
                            ({courseData.totalreviews})
                          </span>
                        </Link>
                      </li>
                      {/* <li className="tag">
                        <Link
                          to="#"
                          className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                          tabIndex="0"
                        >
                          <span className="d-block material-symbols-outlined icon-md">
                            location_on
                          </span>
                          <span className="d-block">Madrid, Spain</span>
                        </Link>
                      </li> */}
                    </ul>
                    <p className="info text-dark mb-4">
                      {courseData.coursecategories}
                    </p>
                    {/* <ul className="members d-flex align-items-center mb-3">
                      <li className="border-1 border-white rounded-circle overflow-hidden">
                        <img
                          src="assets/img/dp-1.png"
                          alt="DP 1"
                          className="img-fluid"
                        />
                      </li>
                      <li className="border-1 border-white rounded-circle overflow-hidden">
                        <img
                          src="assets/img/dp-2.png"
                          alt="DP 2"
                          className="img-fluid"
                        />
                      </li>
                      <li className="border-1 border-white rounded-circle overflow-hidden">
                        <img
                          src="assets/img/dp-3.png"
                          alt="DP 3"
                          className="img-fluid"
                        />
                      </li>
                      <li className="border-1 border-white rounded-circle overflow-hidden">
                        <img
                          src="assets/img/dp-4.png"
                          alt="DP 4"
                          className="img-fluid"
                        />
                      </li>
                      <li className="more ms-2">
                        <Link
                          to="#"
                          className="fs-sm text-gray fw-semibold"
                          tabIndex="0"
                        >
                          +28
                        </Link>
                      </li>
                    </ul> */}
                    <ul className="tags d-flex flex-wrap gap-2 text-primary mb-3">
                      {courseData.courseskills.map((skill, index) => {
                        return (
                          <li
                            className="body-bg px-2 py-1 rounded-5"
                            key={index}
                          >
                            {skill}
                          </li>
                        );
                      })}
                    </ul>
                    <Link
                      to={`/courses/details/${courseData._id}`}
                      className="btn btn-secondary w-100"
                      tabIndex="0"
                    >
                      {t('View Details')}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <ol className="pagination align-items-center gap-2 fw-semibold justify-content-center mt-4">
            <li className="prev me-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_back
                </span>
              </Link>
            </li>
            <li className="page active">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                1
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                2
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                3
              </Link>
            </li>
            <li className="page">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                ...
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="next ms-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_forward
                </span>
              </Link>
            </li>
          </ol> */}
        </div>
      </div>
    </section>
  );
};

export default CourseList;
