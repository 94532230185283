import React from 'react';
import * as url from 'helper/UrlHelper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const ChallengeListCard = ({ jobList, onJobSelect }) => {
  const { t } = useTranslation(); // for translations
  return (
    <div className="col-lg-4">
      <div className="challenges">
        {jobList.map((job, index) => {
          return (
            <div className="challenge mb-4 selected">
              <div className="challenge-content p-3 bg-primary text-white rounded-10">
                <div className="challenge-meta d-flex flex-wrap gap-2 align-items-center mb-3">
                  <img
                    src={
                      job.companylogopath === ''
                        ? assetImages.defaultUser
                        : url.SERVER_URL + job.companylogopath
                    }
                    alt={job.companynamestring}
                    className="img-fluid"
                  />
                  <div className="rating d-flex align-items-center gap-2 ms-auto">
                    <p className="d-flex gap-1 align-items-center px-3 py-2 bg-secondary rounded-90">
                      <span className="d-block material-symbols-outlined icon-fill icon-sm">
                        star
                      </span>
                      <span className="d-block fs-sm fw-bold">
                        {job.ratingtotal}
                      </span>
                      <span className="d-block fs-xs">({job.reviewtotal})</span>
                    </p>
                    <Link to="#" className="text-white">
                      <span className="d-block material-symbols-outlined">
                        bookmark
                      </span>
                    </Link>
                  </div>
                </div>
                <h3>
                  <Link to="/challenges-details" className="text-white">
                    {job.name}
                  </Link>
                </h3>
                <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-2">
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                      tabIndex="0"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        event_note
                      </span>
                      <span className="d-block">{job.createddate}</span>
                    </Link>
                  </li>
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                      tabIndex="0"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        schedule
                      </span>
                      <span className="d-block">{job.jobtype}</span>
                    </Link>
                  </li>
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-white bg-light rounded-90"
                      tabIndex="0"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        location_on
                      </span>
                      <span>{job.city}</span>
                    </Link>
                  </li>
                </ul>
                <p className="info mb-5">{job.summary.slice(0, 100)}</p>
                <p className="price fs-lg fw-semibold">
                  €{job.minsalary} - €{job.maxsalary}
                </p>
                <Link
                  to="#"
                  className="btn btn-secondary w-100 mt-2"
                  onClick={() => {
                    onJobSelect(job._id);
                  }}
                >
                  {t('View Details')}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      {/* <ol className="pagination align-items-center gap-2 fw-semibold justify-content-center pt-5">
        <li className="prev me-3">
          <Link to="#" className="d-block text-black">
            <span className="d-block material-symbols-outlined">
              arrow_back
            </span>
          </Link>
        </li>
        <li className="page active">
          <span className="d-flex align-items-center justify-content-center rounded-circle">
            1
          </span>
        </li>
        <li className="page">
          <Link
            to="#"
            className="d-flex align-items-center justify-content-center rounded-circle"
          >
            2
          </Link>
        </li>
        <li className="page">
          <Link
            to="#"
            className="d-flex align-items-center justify-content-center rounded-circle"
          >
            3
          </Link>
        </li>
        <li className="page">
          <span className="d-flex align-items-center justify-content-center rounded-circle">
            ...
          </span>
        </li>
        <li className="page">
          <Link
            to="#"
            className="d-flex align-items-center justify-content-center rounded-circle"
          >
            26
          </Link>
        </li>
        <li className="page">
          <Link
            to="#"
            className="d-flex align-items-center justify-content-center rounded-circle"
          >
            26
          </Link>
        </li>
        <li className="next ms-3">
          <Link to="#" className="d-block text-black">
            <span className="d-block material-symbols-outlined">
              arrow_forward
            </span>
          </Link>
        </li>
      </ol> */}
    </div>
  );
};

export default ChallengeListCard;
