/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
// import { GlobalProvider } from '../../context/GlobalContext';
import { useTranslation } from 'react-i18next';

/*------ import url and method ------*/
import * as url from '../../helper/UrlHelper';
import { postData, putData } from '../../utils/Gateway';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
  // const { setcreateAccountMessage } = useContext(GlobalProvider);

  const { t } = useTranslation(); // translation marker

  const history = useHistory();

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  const [userEmail, setUserEmail] = useState('');
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [otpSending, setotpSending] = useState(false);

  const [showInput, setShowInput] = useState('email');

  // const [createAccountMessage, setcreateAccountMessage] = useState('');

  const [firstCode, setFirstCode] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [thirdCode, setThirdCode] = useState('');
  const [fourthCode, setFourthCode] = useState('');
  const [otpFailed, setotpFailed] = useState(false);
  const [userEmailforOtp, setuserEmailforOtp] = useState('');
  // const [otpResendMessage, setotpResendMessage] = useState('');

  //otp function
  const otpHandler = async () => {
    if (userEmail === '') {
      setEmailWarning(true);
    } else {
      setEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (userEmail.match(mailformat)) {
        setNotValidEMail(false);
        try {
          setotpSending(true);
          let userData = {
            email: userEmail,
          };
          let requestURL = url.API_BASE_URL + url.API_GET_OTP;

          const response = await postData(requestURL, userData);

          console.log(response);

          setotpSending(false);

          if (response.status) {
            // setcreateAccountMessage('');
            setuserEmailforOtp(userEmail);
            setShowInput('otp');
            //hide login modal
            //  let loginModal = document.querySelector('#forgot_modal');
            //  let modal = bootstrap.Modal.getInstance(loginModal);
            //  modal.hide();

            //  //show otpconfirm modal
            //  let myModal = new bootstrap.Modal(
            //    document.getElementById('otp_validate_mdl')
            //  );
            //  myModal.show();
          } else {
            setuserEmailforOtp('');
            // setcreateAccountMessage(
            //   'Email does not exist.Please create a new account.'
            // );
            setShowInput('email');
            //hide login modal
            //  let loginModal = document.querySelector('#forgot_modal');
            //  let modal = bootstrap.Modal.getInstance(loginModal);
            //  modal.hide();

            //  //show register modal
            //  let myModal = new bootstrap.Modal(
            //    document.getElementById('register_mdl')
            //  );
            //  myModal.show();
          }
          setUserEmail('');
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setNotValidEMail(true);
      }
    }
  };

  //reset function
  //  const reset = () => {
  //    setUserEmail('');
  //    setuserEmailforOtp('');
  //    setcreateAccountMessage('');
  //  };

  // function for backspace reference second digit
  const secondCodeKey = e => {
    if (e.key === 'Backspace') {
      if (secondCode === '' && secondCode.length === 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = e => {
    if (e.key === 'Backspace') {
      if (thirdCode === '' && thirdCode.length === 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = e => {
    if (e.key === 'Backspace') {
      if (fourthCode === '' && fourthCode.length === 0) {
        code3Ref.current?.focus();
      }
    }
  };

  //otp login function
  const validateOtpHandler = async () => {
    try {
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      let userData = {
        email: userEmailforOtp,
        otp: finalCode,
      };

      let requestURL = url.API_BASE_URL + url.API_VALIDATE_OTP;

      const response = await postData(requestURL, userData);

      console.log(response);

      if (response.status) {
        setotpFailed(false);
        resetOtpInputs();
        setShowInput('password');
      } else {
        setotpFailed(true);
        setShowInput('email');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //otp resend function
  const resendOtpHandler = async () => {
    try {
      setotpSending(true);
      let userData = {
        email: userEmailforOtp,
      };
      let requestURL = url.API_BASE_URL + url.API_GET_OTP;

      const response = await postData(requestURL, userData);

      console.log(response);

      setotpSending(false);

      if (response.status) {
        // setotpResendMessage('We have send otp in your email');
      } else {
        // setotpResendMessage('');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset input function
  const resetOtpInputs = () => {
    setFirstCode('');
    setSecondCode('');
    setThirdCode('');
    setFourthCode('');
  };

  //reset function
  // const resetOtpModal = () => {
  //   setotpFailed(false);
  //   setuserEmailforOtp('');
  // };

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== '' && firstCode.length === 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== '' && secondCode.length === 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== '' && thirdCode.length === 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  useEffect(() => {
    if (fourthCode !== '') {
      validateOtpHandler();
    }
  }, [fourthCode]);

  // ------------ create new password section ---------------
  const [userPassword, setUserPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(false);
  //  const [showPassword, setShowPassword] = useState(false);
  //  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordNotMatched, setPasswordNotMatched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // function for toggle passowrd to text
  //  const togglePassowrd = () => {
  //    setShowPassword(!showPassword);
  //  };

  // function for change text type
  //  const toggleConfirmPassowrd = () => {
  //    setShowConfirmPassword(!showConfirmPassword);
  //  };

  //validation function
  const validateForm = () => {
    let isValid = true;

    if (userPassword === '') {
      setPasswordWarning(true);
      isValid = false;
    }

    if (userConfirmPassword === '') {
      setConfirmPasswordWarning(true);
      isValid = false;
    }

    return isValid;
  };

  //chnage passowrd function
  const changePasswordHandler = async () => {
    if (validateForm()) {
      if (userPassword === userConfirmPassword) {
        setPasswordNotMatched(false);
        try {
          setIsLoading(true);
          let userData = {
            email: userEmailforOtp,
            password: userPassword,
          };

          let requestURL = url.API_BASE_URL + url.API_UPDATE_PASSSWORD;

          const response = await putData(requestURL, userData);

          setIsLoading(false);
          if (response.status) {
            history.push('/login');
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setPasswordNotMatched(true);
      }
    }
  };

  //function for reset modal
  //  const resetModal = () => {
  //    setuserEmailforOtp('');
  //    setUserPassword('');
  //    setUserConfirmPassword('');
  //  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4" style={{ width: '20rem' }}>
        <h3 className="text-center mb-4">Forgot Password</h3>

        {showInput === 'email' ? (
          <>
            <div className="mb-3">
              <label htmlFor="otpEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="otpEmail"
                placeholder="Enter your email"
                value={userEmail}
                onChange={e => {
                  setEmailWarning(false);
                  setUserEmail(e.target.value);
                }}
              />
              {/* ------ email warning start ------ */}
              {emailWarning && (
                <p className="error mt-1">
                  <span className="material-icons-outlined me-1">info</span>
                  {t('Please enter email')}
                </p>
              )}
              {/* ----- valid email warning ------ */}
              {notValidEMail && (
                <p className="error mt-1">
                  <span className="material-icons-outlined me-1">info</span>
                  {t('Please enter valid email')}
                </p>
              )}
            </div>

            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={otpHandler}
              disabled={otpSending ? true : false}
              style={{
                cursor: otpSending ? 'not-allowed' : 'pointer',
              }}
            >
              {t('Send OTP')}
              {otpSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </>
        ) : showInput === 'otp' ? (
          <div className="mt-3">
            <p>Email: {userEmail}</p>
            <div className="d-flex justify-content-between">
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code1Ref}
                value={firstCode}
                onChange={e => {
                  setotpFailed(false);
                  // setotpResendMessage('');
                  setFirstCode(e.target.value.slice(0, e.target.maxLength));
                }}
                placeholder="-"
                maxLength="1"
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code2Ref}
                value={secondCode}
                onChange={e => {
                  setotpFailed(false);
                  // setotpResendMessage('');
                  setSecondCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={secondCodeKey}
                placeholder="-"
                maxLength="1"
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code3Ref}
                value={thirdCode}
                onChange={e => {
                  setotpFailed(false);
                  // setotpResendMessage('');
                  setThirdCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={thirdCodeKey}
                placeholder="-"
                maxLength="1"
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none text-center"
                ref={code4Ref}
                value={fourthCode}
                onChange={e => {
                  setotpFailed(false);
                  // setotpResendMessage('');
                  setFourthCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={fourthCodeKey}
                placeholder="-"
                maxLength="1"
              />
            </div>
            {/* ------ otp error message section start ------ */}
            {otpFailed && (
              <div className="validate_error">
                <p className="error">
                  {t('Oops')}!{' '}
                  {t('the OTP verification failed. Please try again')}.
                </p>
              </div>
            )}
            {/* ------ otp error message section end ------ */}
            <button
              type="button"
              className="btn btn-link w-100 mt-2"
              onClick={resendOtpHandler}
            >
              Resend OTP
              {otpSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        ) : (
          <>
            <div className="mb-3">
              <label htmlFor="otpEmail" className="form-label">
                New Password
              </label>
              <input
                type="email"
                className="form-control"
                id="otpEmail"
                placeholder={t('New Password')}
                value={userPassword}
                onChange={e => {
                  setPasswordWarning(false);
                  setUserPassword(e.target.value);
                }}
              />
              {/* ------ passowrd section end ----- */}
              {passwordWarning && (
                <p className="error">
                  <span className="material-icons-outlined">info</span>
                  {t('Please enter password')}
                </p>
              )}
              <label htmlFor="otpEmail" className="form-label">
                Confirm Password
              </label>
              <input
                type="email"
                className="form-control"
                id="otpEmail"
                value={userConfirmPassword}
                placeholder={t('Re-enter Password')}
                onChange={e => {
                  setConfirmPasswordWarning(false);
                  setUserConfirmPassword(e.target.value);
                }}
              />

              {/* ------ confirm passowrd section end ----- */}

              {confirmPasswordWarning && (
                <p className="error">
                  <span className="material-icons-outlined">info</span>
                  {t('Please enter confirm password')}
                </p>
              )}

              {passwordNotMatched && (
                <p className="error">
                  <span className="material-icons-outlined">info</span>
                  {t('Password and confirm password not matched')}
                </p>
              )}
            </div>

            <button
              type="button"
              className="btn btn-primary w-100"
              disabled={isLoading ? true : false}
              style={{
                cursor: isLoading ? 'not-allowed' : 'pointer',
              }}
              onClick={changePasswordHandler}
            >
              {t('Update Password')}
              {isLoading && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
