import React from 'react';
import { Link } from 'react-router-dom';

const PeojectsTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="projects-tab-pane"
      role="tabpanel"
      aria-labelledby="projects-tab"
      tabIndex="0"
    >
      <div className="projects-container">
        <div className="project p-3 bg-white rounded-10 shadow-sm mb-3">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/companies-project-1.jpg"
                  alt="The Spanish Line"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc d-flex align-items-center gap-3">
                <div className="info">
                  <h6>The Spanish Line</h6>
                  <p className="date fs-sm fw-semibold text-light mb-2">
                    18th Jan 2022
                  </p>
                  <p>
                    Indra and Microsoft take iTEC to the cloud and open a new
                    era in world air traffic management. ...
                  </p>
                </div>
                <Link to="#" className="btn btn-secondary btn-sm text-nowrap">
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="project p-3 bg-white rounded-10 shadow-sm mb-3">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/companies-project-2.jpg"
                  alt="Indra and Microsoft"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc d-flex align-items-center gap-3">
                <div className="info">
                  <h6>Indra and Microsoft</h6>
                  <p className="date fs-sm fw-semibold text-light mb-2">
                    18th Jan 2022
                  </p>
                  <p>
                    Indra and Microsoft take iTEC to the cloud and open a new
                    era in world air traffic management. ...
                  </p>
                </div>
                <Link to="#" className="btn btn-secondary btn-sm text-nowrap">
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="project p-3 bg-white rounded-10 shadow-sm mb-3">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/companies-project-3.jpg"
                  alt="Future of Navigation"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc d-flex align-items-center gap-3">
                <div className="info">
                  <h6>Future of Navigation</h6>
                  <p className="date fs-sm fw-semibold text-light mb-2">
                    18th Jan 2022
                  </p>
                  <p>
                    Indra and Microsoft take iTEC to the cloud and open a new
                    era in world air traffic management. ...
                  </p>
                </div>
                <Link to="#" className="btn btn-secondary btn-sm text-nowrap">
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="project p-3 bg-white rounded-10 shadow-sm mb-3">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="img-container rounded-3 overflow-hidden">
                <img
                  src="assets/img/companies-project-1.jpg"
                  alt="The Spanish Line"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="desc d-flex align-items-center gap-3">
                <div className="info">
                  <h6>The Spanish Line</h6>
                  <p className="date fs-sm fw-semibold text-light mb-2">
                    18th Jan 2022
                  </p>
                  <p>
                    Indra and Microsoft take iTEC to the cloud and open a new
                    era in world air traffic management. ...
                  </p>
                </div>
                <Link to="#" className="btn btn-secondary btn-sm text-nowrap">
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeojectsTab;
