import React from 'react';

const ChallengesTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="challenges-tab-pane"
      role="tabpanel"
      aria-labelledby="challenges-tab"
      tabIndex="0"
    >
      challenges
    </div>
  );
};

export default ChallengesTab;
