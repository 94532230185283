import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const LessonsTabLeftPanel = ({
  courseDetails = {},
  selectedLessonId,
  setSelectedLessonId,
}) => {
  const lessonList = courseDetails.courselessons || [];

  return (
    <div className="col-lg-4">
      <aside className="lessons-list">
        <ul className="lessons">
          {lessonList.length > 0 &&
            lessonList.map((lessonData, index) => {
              return (
                <li
                  className={`lesson bg-white p-3 rounded-10 shadow-sm mb-2  ${
                    selectedLessonId &&
                    selectedLessonId.toString() === lessonData._id.toString()
                      ? 'selected'
                      : ''
                  }`}
                  key={index}
                >
                  <Link
                    to="#"
                    onClick={() => {
                      document.body.classList.add('course_open');
                      setSelectedLessonId(lessonData._id);
                    }}
                  >
                    <p className="duration text-primary fw-semibold mb-2">
                      {lessonData?.duration} min
                    </p>
                    <h6 className="title font-secondary mt-0 mb-3">
                      {lessonData?.title}
                    </h6>

                    <div className="meta fs-sm text-primary d-flex gap-2 align-items-center">
                      <span className="font-secondary fw-semibold">
                        {lessonData?.membernames}
                      </span>

                      {/* <span className="d-block material-symbols-outlined icon-md icon-fill rating-star-gradient">
                        star
                      </span>
                      <span className="rating-count">4.8 (3,940)</span> */}
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </aside>
    </div>
  );
};

export default LessonsTabLeftPanel;
