/* eslint-disable */
import React, { useEffect, useState } from 'react';
/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const HomeCompanies = () => {
  const token = localStorage.getItem('token'); // for token

  const { t } = useTranslation(); // for translations

  const [companyList, setCompanyList] = useState([]); // for company lists

  //function for get all contacts
  const getAllCompanyList = async (start, limit) => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&start=${start}&limit=${limit}`;

      // console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log('response in app user list------>', response);

      // setIsLoading(false);
      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCompanyList('0', '5');
  }, []);

  return (
    <section className="best-companies py-50 py-md-70">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Best Companies')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="/companies" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {companyList?.map((company, index) => (
            <div
              key={index}
              className="best-company"
              style={{ padding: '0 10px' }}
            >
              <div className="best-company-content p-3 bg-white rounded-10 shadow-sm">
                <img
                  src={url.SERVER_URL + company?.companylogo}
                  alt={company?.name}
                  className="img-fluid mb-2"
                />
                <h3>
                  <Link
                    to={`/company/details/${company?._id}`}
                    className="text-black"
                  >
                    {company?.name}
                  </Link>
                </h3>
                <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-2">
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                        star
                      </span>
                      <span className="d-block fs-sm fw-bold">
                        {company?.totalratings}
                      </span>
                      <span className="d-block fs-xs">
                        {company?.totalreviews}
                      </span>
                    </Link>
                  </li>
                  <li className="tag">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        location_on
                      </span>
                      <span className="d-block">
                        {company?.city} , {company?.state} , {company?.country}
                      </span>
                    </Link>
                  </li>
                </ul>
                <p className="text-dark">{company?.servicestring}</p>
                <div className="tag-container pt-3 mt-3 border-top">
                  <div className="search d-flex gap-3 align-items-center mb-2">
                    <p className="fs-sm fw-medium">Search</p>
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                      {company?.searching?.length > 0 &&
                        company?.searching?.map((item, index) => (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              className="d-block text-gray bg-gray-100 rounded-90"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="interest d-flex gap-3 align-items-center">
                    <p className="fs-sm fw-medium">Interest</p>
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                      {company?.interest?.length > 0 &&
                        company?.interest?.map((item, index) => (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              className="d-block text-gray bg-gray-100 rounded-90"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <Link
                  to={`/company/details/${company?._id}`}
                  className="btn btn-secondary w-100 mt-3"
                >
                  {t('View Details')}
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeCompanies;
