/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';
import SuccessStoriesDetailsBanner from 'Components/SuccessStoriesComponents/SuccessStoriesDetailsComponents/SuccessStoriesDetailsBanner/SuccessStoriesDetailsBanner';
import SuccessStoriesDetailsDescriptions from 'Components/SuccessStoriesComponents/SuccessStoriesDetailsComponents/SuccessStoriesDetailsDescriptions/SuccessStoriesDetailsDescriptions';
import SuccessStoriesDetailsReviews from 'Components/SuccessStoriesComponents/SuccessStoriesDetailsComponents/SuccessStoriesDetailsReviews/SuccessStoriesDetailsReviews';

const SuccessStoriesDetailsBody = () => {
  const token = localStorage.getItem('token');
  // const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const params = useParams();

  const [storyName, setStoryName] = useState('');
  // const [storyDate, setStoryDate] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyImageUrl, setCompanyImageUrl] = useState('');
  // const [successStorySummary, setSuccessStorySummary] = useState('');
  const [successStoryDetails, setSuccessStoryDetails] = useState('');
  const [successStorySitutation, setSuccessStorySitutation] = useState('');
  const [successStoryObjectives, setSuccessStoryObjectives] = useState('');
  const [successStoryProcess, setSuccessStoryProcess] = useState('');
  const [successStoryResults, setsuccessStoryResults] = useState('');
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  // const [ratingCount, setRatingCount] = useState('0.0');
  const [reviewsList, setReviewsList] = useState([]);
  // const [ratingPercentArr, setRatingPercentArr] = useState([]);

  // const [isReviewLoading, setIsReviewLoading] = useState(false);

  //function for get blog details
  const getSuccessStoryDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SUCCESS_STORY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log('response story details---->', response);

      if (response.status) {
        setStoryName(response.data?.name);
        setCompanyName(response.data.companyname);
        setCompanyImageUrl(response.data?.companylogo);
        // setStoryDate(response.data.createddate);
        // setSuccessStorySummary(response.data?.summary);
        setSuccessStoryDetails(response.data?.description);
        setSuccessStorySitutation(response.data?.previoussituation);
        setSuccessStoryObjectives(response.data?.previousobjectives);
        setSuccessStoryProcess(response.data?.processdata);
        setsuccessStoryResults(response.data?.resultsdata);
        // setUploadedFiles(response.data?.uploads);
        setCompanyId(response.data?.companyid?._id);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    // setIsReviewLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=company&moduleid=${companyId}`;

    try {
      const response = await getData(requestURL);

      console.log('review list response', response);

      // setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          // setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          // setRatingPercentArr(response.data.ratingbar);
        } else {
          // setRatingCount('');
          setReviewsList([]);
          // setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getSuccessStoryDetails();
    }
  }, [params.id]);

  useEffect(() => {
    if (companyId) {
      getReviewsLists();
    }
  }, [companyId]);

  useEffect(() => {
    document.title = 'Success Stories'; // Update the title when Register component mounts
  }, []);

  return (
    <div id="main_content" className="position-relative">
      <SuccessStoriesDetailsBanner
        storyName={storyName}
        // storyDate={storyDate}
        companyName={companyName}
        companyImageUrl={companyImageUrl}
        successStoryDetails={successStoryDetails}
      />

      <SuccessStoriesDetailsDescriptions
        // successStorySummary={successStorySummary}
        // successStoryDetails={successStoryDetails}
        successStorySitutation={successStorySitutation}
        successStoryObjectives={successStoryObjectives}
        successStoryProcess={successStoryProcess}
        successStoryResults={successStoryResults}
        // uploadedFiles={uploadedFiles}
      />

      {/* <section className="video-wrapper">
          <div className="container">
            <h2 className="mb-4">Videos</h2>
            <div className="video-carousel">
              <div className="video">
                <div className="video-content">
                  <div
                    className="video-container ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-10 mb-4"
                    style={{
                      backgroundImage:
                        "url('/assets/img/video-placeholder-1.jpg')",
                    }}
                  ></div>
                  <div className="desc">
                    <h6>Please check this dummy video title</h6>
                    <p>02:56</p>
                  </div>
                </div>
              </div>
              <div className="video">
                <div className="video-content">
                  <div
                    className="video-container ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-10 mb-4"
                    style={{
                      backgroundImage:
                        "url('/assets/img/video-placeholder-2.jpg')",
                    }}
                  ></div>
                  <div className="desc">
                    <h6>Please check this dummy video title</h6>
                    <p>02:56</p>
                  </div>
                </div>
              </div>
              <div className="video">
                <div className="video-content">
                  <div
                    className="video-container ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-10 mb-4"
                    style={{
                      backgroundImage:
                        "url('/assets/img/video-placeholder-3.jpg')",
                    }}
                  ></div>
                  <div className="desc">
                    <h6>Please check this dummy video title</h6>
                    <p>02:56</p>
                  </div>
                </div>
              </div>
              <div className="video">
                <div className="video-content">
                  <div
                    className="video-container ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-10 mb-4"
                    style={{
                      backgroundImage:
                        "url('/assets/img/video-placeholder-1.jpg')",
                    }}
                  ></div>
                  <div className="desc">
                    <h6>Please check this dummy video title</h6>
                    <p>02:56</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      <SuccessStoriesDetailsReviews reviewsList={reviewsList} />
    </div>
  );
};

export default SuccessStoriesDetailsBody;
