/* eslint-disable */

import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const HomeTeachers = () => {
  const token = localStorage.getItem('token');
  const { t } = useTranslation(); // for translations
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [appUserList, setAppUserList] = useState([]); // for user lists

  const getAllAppUsers = async (start, limit) => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_APP_USERS +
        `?token=${token}&start=${start}&limit=${limit}`;

      // console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in app user list------>", response);

      if (response.status) {
        setAppUserList(response.data);
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllAppUsers('0', '5');
  }, []);

  return (
    <section className="best-teachers py-50 py-md-70 border-bottom border-light border-opacity-2">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Best Teacher')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="/accounts" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {appUserList?.map((userData, index) => (
            <div className="teacher" key={index}>
              <div className="teacher-content">
                <div className="img-container rounded-10 overflow-hidden mb-3 pe-2 ps-2">
                  <Link to="#">
                    <img
                      src={
                        userData.userimage === ''
                          ? assetImages.defaultUser
                          : url.SERVER_URL + userData.userimage
                      }
                      alt={userData.username}
                      className="img-fluid w-100 object-center object-cover"
                      style={{ height: '310px' }}
                    />
                  </Link>
                </div>
                <div className="desc text-center">
                  <h3>
                    <Link to="#" className="text-black">
                      {userData.username}
                    </Link>
                  </h3>
                  <p className="designation">{userData?.position}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeTeachers;
