import React from 'react';
// import { Link } from 'react-router-dom';

import * as url from '../../../../helper/UrlHelper';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SuccessStoriesDetailsReviews = ({ reviewsList }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section className="review-wrapper py-50">
      <div className="container">
        <h2 className="mb-4">Reviews</h2>

        <Carousel responsive={responsive}>
          {reviewsList.map((reviewData, index) => {
            return (
              <div className="review px-2" key={index}>
                <div className="review-content bg-white p-3 rounded-10 shadow-sm">
                  <div className="profile d-flex align-items-center gap-2 mb-3">
                    <div className="avatar rounded-circle overflow-hidden">
                      <img
                        // src={reviewData.userimage}
                        src={
                          /*  storyData.companylogo == ''
                              ? assetImages.defaultUser
                              :  */ url.SERVER_URL + reviewData.userimage
                        }
                        alt="Gordon Ramsay"
                        className="w-100 h-100 object-cover object-center"
                      />
                    </div>
                    <div className="name font-secondary fw-semibold text-primary">
                      <h6 className="mb-1">{reviewData.username}</h6>
                      <p className="date">{reviewData.userposition}</p>
                    </div>
                  </div>
                  <p>
                    {reviewData.comment}
                    {/* <Link to="#">Read More</Link> */}
                  </p>
                  {/* <ul className="action d-flex gap-2 mt-4">
                    <li className="flex-fill">
                      <Link
                        to="#"
                        className="body-bg text-black d-flex align-items-center justify-content-center gap-1 px-3 py-2 rounded-5"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          thumb_up
                        </span>
                        <span className="d-block px-2 fs-sm text-white bg-light rounded-90">
                          2.5k
                        </span>
                      </Link>
                    </li>
                    <li className="flex-fill">
                      <Link
                        to="#"
                        className="body-bg text-black d-flex align-items-center justify-content-center gap-1 px-3 py-2 rounded-5"
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          chat
                        </span>
                        <span className="d-block px-2 fs-sm text-white bg-light rounded-90">
                          3
                        </span>
                      </Link>
                    </li>
                    <li className="flex-fill">
                      <Link
                        to="#"
                        className="body-bg text-black d-flex align-items-center justify-content-center gap-1 px-3 py-2 rounded-5"
                      >
                        <span className="d-block material-symbols-outlined icon-md icon-fill">
                          share
                        </span>
                        <span className="d-block px-2 fs-sm text-white bg-light rounded-90">
                          3
                        </span>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default SuccessStoriesDetailsReviews;
