import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer
      id="footer"
      className="fs-sm text-white bg-black position-fixed start-0 end-0 bottom-0"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-auto">
            <Link to="/" className="logo bg-secondary p-2 me-5">
              <img src="assets/img/logo.png" alt="IEB" className="img-fluid" />
            </Link>
          </div>
          <div className="col-auto">
            <ul className="nav fw-semibold">
              <li>
                <Link to="#">Enaire</Link>
              </li>
              <li>
                <Link to="#">CRIDA</Link>
              </li>
              <li>
                <Link to="#">Enfemenino</Link>
              </li>
              <li>
                <Link to="#">ITEC</Link>
              </li>
              <li>
                <Link to="#">HABITAT</Link>
              </li>
            </ul>
          </div>
          <div className="col-auto ms-auto">
            <p className="">Copyright © 2023 IEB</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
