import React from 'react';
import CommonHeader from '../../../Components/Common/Headers/CommonHeader';
import Footer from '../../../Components/Common/Footer/Footer';
import ProjectDetailsComponent from '../../../Components/ProjectComponents/ProjectDetailsComponents/ProjectDetailsComponent';

const ProjectsDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="projects" />

      <div id="main_content" className="position-relative">
        <ProjectDetailsComponent />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default ProjectsDetails;
