import React from 'react';

const ServicesTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="services-tab-pane"
      role="tabpanel"
      aria-labelledby="services-tab"
      tabIndex="0"
    >
      <div className="services-container">
        <div className="row gx-3">
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Website</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Template</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Website</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Template</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Design</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Marketing</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="service bg-white p-4 d-flex align-items-center gap-2 rounded-10 shadow-sm">
              <div className="desc flex-fill">
                <p className="fs-sm fw-semibold mb-1">Website</p>
                <p className="mb-2">Earn a Certificate upon completion</p>
                <p className="rating fs-sm fw-semibold text-primary d-inline-flex gap-1 align-items-center body-bg rounded-90 px-2 py-2">
                  <span className="d-block material-symbols-outlined icon-sm icon-fill text-orange">
                    star
                  </span>
                  <span className="d-block">4.8</span>
                  <span className="d-block">(2,589)</span>
                </p>
              </div>
              <div className="icon">
                <span className="d-block material-symbols-outlined text-success">
                  task_alt
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesTab;
