import React from 'react';

const AboutTab = () => {
  return (
    <div
      className="tab-pane fade show active"
      id="about-tab-pane"
      role="tabpanel"
      aria-labelledby="about-tab"
      tabIndex="0"
    >
      <div className="description bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          Description
        </p>
        <p>
          Santander is listed on the Bolsa de Madrid and is a constituent of the
          IBEX 35 index. Indra is organized around three business areas:
          information technologies, simulation & automatic test equipment, and
          defense electronic equipment. Indra's portfolio ranges from
          consultancy, project development, and systems and applications
          integration to outsourcing of IT systems and business processes. This
          offer is structured into two primary segments: solutions and services
          (including outsourcing and application maintenance), and business
          processes where technology is a strategic and differentiating element
          (BPO). Approximately a third of the company's annual revenues come
          from international markets. By geographical areas, Europe and the
          United States are the two international markets with the greatest
          weight and growth for Santander.
        </p>
      </div>
      <div className="faq bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          Frequently Asked Questions
        </p>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How can you put question here?
              </button>
            </div>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Expedita libero illum ut id porro animi sequi consequatur
                  nobis tempora similique, voluptatem, quibusdam quod natus aut
                  enim fugiat, repudiandae quo aspernatur.lorem Lorem ipsum
                  dolor sit, amet consectetur adipisicing elit. Pariatur est
                  neque dicta vitae fuga nam aspernatur, ea totam at quae
                  praesentium voluptatum eum debitis iste commodi autem, culpa
                  voluptatem ipsum!
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How can you put question here by today?
              </button>
            </div>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero laudantium perferendis provident quis facere impedit
                  voluptatem suscipit corrupti error, earum, quidem illo. Earum
                  quas dolorum possimus vitae nesciunt quasi unde. Lorem ipsum
                  dolor sit amet consectetur, adipisicing elit. Ratione saepe
                  dolorum accusantium doloribus. Laboriosam, ipsam perspiciatis.
                  Laboriosam quidem, rerum accusamus reiciendis, praesentium
                  aliquam laborum a debitis odit labore sapiente provident!
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How can you put question here?
              </button>
            </div>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Praesentium similique, blanditiis excepturi dicta asperiores
                  reiciendis optio commodi assumenda vitae, amet inventore
                  eligendi quae delectus ipsum aperiam illum, obcaecati
                  architecto odio! Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Voluptatum ad nam molestiae? Ut dolores
                  nihil provident temporibus unde labore dolore neque eaque,
                  doloremque culpa rem hic quas impedit tempora? Blanditiis.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How can you put question here by today?
              </button>
            </div>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Praesentium similique, blanditiis excepturi dicta asperiores
                  reiciendis optio commodi assumenda vitae, amet inventore
                  eligendi quae delectus ipsum aperiam illum, obcaecati
                  architecto odio! Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Voluptatum ad nam molestiae? Ut dolores
                  nihil provident temporibus unde labore dolore neque eaque,
                  doloremque culpa rem hic quas impedit tempora? Blanditiis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="docs bg-white p-4 rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">Docs</p>
        <ul className="documents d-flex flex-wrap gap-3">
          <li className="document d-flex flex-column justify-content-center fs-sm text-center p-3 border border-gray rounded-8">
            <div className="file-icon mx-auto mb-3">
              <img
                src="assets/img/pdf-icon.png"
                alt="EventReg.pdf"
                className="img-fluid"
              />
            </div>
            <p className="mb-1">EventReg.pdf</p>
            <p className="size fs-xs text-gray">1.5mb</p>
          </li>
          <li className="document d-flex flex-column justify-content-center fs-sm text-center p-3 border border-gray rounded-8">
            <div className="file-icon mx-auto mb-3">
              <img
                src="assets/img/pdf-icon.png"
                alt="Managing.pdf"
                className="img-fluid"
              />
            </div>
            <p className="mb-1">Managing.pdf</p>
            <p className="size fs-xs text-gray">1.5mb</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutTab;
