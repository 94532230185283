/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { useParams } from 'react-router-dom';
import ChallengesDetailsLeftComponent from '../ChallengesDetailsLeftComponent/ChallengesDetailsLeftComponent';
import ChallengesDetailsRightComponent from '../ChallengesDetailsRightComponent/ChallengesDetailsRightComponent';

const ChallengesDetailsBodyComponent = () => {
  const token = localStorage.getItem('token');
  // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');

  const params = useParams();

  // const [isLoading, setisLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [companylogopath, setCompanylogopath] = useState('');
  // const [code, setcode] = useState('');
  const [summary, setsummary] = useState('');
  const [details, setdetails] = useState('');
  const [aboutCompany, setaboutCompany] = useState('');
  const [aboutPosition, setaboutPosition] = useState('');
  // const [requiredSkills, setrequiredSkills] = useState([]);
  // const [optionalSkills, setoptionalSkills] = useState([]);
  // const [remoteJobValue, setremoteJobValue] = useState(null);
  const [jobTypeValue, setjobTypeValue] = useState('');
  // const [experienceAsValue, setexperienceAsValue] = useState(null);
  // const [experienceYear, setexperienceYear] = useState('');
  // const [experienceMonth, setexperienceMonth] = useState('');
  // const [currencyValue, setcurrencyValue] = useState(null);
  // const [paymentDurationValue, setpaymentDurationValue] = useState(null);
  const [minSalary, setminSalary] = useState('');
  const [maxSalary, setmaxSalary] = useState('');
  // const [companyValue, setcompanyValue] = useState(null);
  const [companyName, setcompanyName] = useState('');
  // const [companyAdress, setcompanyAdress] = useState('');
  // const [stateName, setstateName] = useState('');
  const [cityName, setcityName] = useState('');
  const [country, setcountry] = useState('');
  // const [zipcode, setzipcode] = useState('');
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  // const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [tagList, settagList] = useState([]);
  const [createdDate, setCreatedDate] = useState('');

  const [memberList, setMemberList] = useState([]);

  const [processList, setprocessList] = useState([]);
  const [isApplied, setIsApplied] = useState(false);

  //function for get project details
  const getJobDetails = async () => {
    // setisLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);
      // setisLoading(false);
      console.log('getJobDetails==============>>>', response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
        setCompanylogopath(data.companylogopath);
        // setcode(data.jobcode);
        setsummary(data.summary);
        setdetails(data.description);
        setaboutCompany(data.aboutcompany);
        setaboutPosition(data.aboutposition);
        // setrequiredSkills(data.requiredskillsdata);
        // setoptionalSkills(data.optionalskillsdata);
        // setexperienceYear(data.experienceyear);
        // setexperienceMonth(data.experienceMonth);
        settagList(data.tagsbyuser);
        setminSalary(data.minsalary);
        setmaxSalary(data.maxsalary);
        setjobTypeValue(data.jobtype);
        setcompanyName(data.companynamestring);
        // setcompanyAdress(data.location);
        // setzipcode(data.zipcode);
        setcityName(data.city);
        // setstateName(data.state);
        setcountry(data.country);
        setCreatedDate(data.createddate);
        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
        // setUploadedDocFileIds(response?.data?.uploadedfileids);
        setIsApplied(response.data.isappiled);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllChallengeMembers = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_JOB_MEMBER +
        `?token=${token}&challenge=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllJobProcess = async () => {
    try {
      //  setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_JOB_PROCESS +
        `?token=${token}&challengeid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      //  setisLoading(false);

      if (response.status) {
        setprocessList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getJobDetails();
      getAllChallengeMembers();
      getAllJobProcess();
    }
  }, [params.id]);
  return (
    <section className="challenge-details-wrapper header-spacing py-5">
      <div className="container">
        <div className="row">
          {/* ------- left componet ------------ */}
          <ChallengesDetailsLeftComponent
            title={title}
            companyName={companyName}
            companyLogoPath={companylogopath}
            maxSalary={maxSalary}
            minSalary={minSalary}
            cityName={cityName}
            country={country}
            tagList={tagList}
            jobType={jobTypeValue}
            isApplied={isApplied}
            createdDate={createdDate}
          />

          {/* ------- right componet ------------ */}
          <ChallengesDetailsRightComponent
            summary={summary}
            details={details}
            aboutPosition={aboutPosition}
            aboutCompany={aboutCompany}
            minSalary={minSalary}
            maxSalary={maxSalary}
            uploadedDocFiles={uploadedDocFiles}
            memberList={memberList}
            processList={processList}
            isApplied={isApplied}
          />
        </div>
      </div>
    </section>
  );
};

export default ChallengesDetailsBodyComponent;
