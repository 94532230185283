import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import carousel styles
import { useTranslation } from 'react-i18next';

const ecosystems = [
  {
    id: 1,
    name: 'BBVA',
    logo: 'assets/img/bbva.png',
    details: ['Companies', 'Challenges', 'Projects', 'Success Stories'],
    more: 5,
  },
  {
    id: 2,
    name: 'Santander',
    logo: 'assets/img/santander.png',
    details: ['Companies', 'Challenges', 'Projects', 'Success Stories'],
    more: 5,
  },
  {
    id: 3,
    name: 'FindelDron',
    logo: 'assets/img/findel-dron.png',
    details: ['Companies', 'Challenges', 'Projects', 'Success Stories'],
    more: 5,
  },
  {
    id: 4,
    name: 'Inviertan',
    logo: 'assets/img/inviertan.png',
    details: ['Companies', 'Challenges', 'Projects', 'Success Stories'],
    more: 5,
  },
  {
    id: 5,
    name: 'BBVA',
    logo: 'assets/img/bbva.png',
    details: ['Companies', 'Challenges', 'Projects', 'Success Stories'],
    more: 5,
  },
];

const HomeEcosystem = () => {
  const { t } = useTranslation(); // for translations

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="best-ecosystems bg-primary py-50 py-md-70">
      <div className="container">
        <div className="sec-title text-white mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2 className="text-white">{t('Best Ecosystem')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="#" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {ecosystems.map(ecosystem => (
            <div
              key={ecosystem.id}
              className="best-ecosystem"
              style={{ padding: '0 10px' }}
            >
              <div className="ecosystem-content p-3 bg-white rounded-10 shadow-md">
                <div className="logo-container d-flex align-items-center justify-content-center bg-gray-200 rounded-8 p-3 mb-3">
                  <Link to="#">
                    <img
                      src={ecosystem.logo}
                      alt={ecosystem.name}
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="desc">
                  <h3 className="mb-3">
                    <Link to="#" className="text-primary">
                      {ecosystem.name}
                    </Link>
                  </h3>
                  <ul className="check-circle check-border mb-2">
                    {ecosystem.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                  <Link to="#" className="fs-sm fw-semibold text-primary">
                    + {ecosystem.more} more
                  </Link>
                  <Link to="#" className="btn btn-secondary w-100 mt-4">
                    {t('View Details')}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeEcosystem;
