import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import AboutTab from '../Tabs/AboutTab/AboutTab';
import LessonsTab from '../Tabs/LessonsTab/LessonsTab';
import TeamTab from '../Tabs/TeamTab/TeamTab';
import EventsTab from '../Tabs/EventsTab/EventsTab';
import ForumTab from '../Tabs/ForumTab/ForumTab';
import DocumentTab from '../Tabs/DocumentTab/DocumentTab';
import { useTranslation } from 'react-i18next';
import ReviewTab from 'Components/Common/Tabs/ReviewTab';

const CourseDetailsBottomComponent = ({
  courseDetails = {},
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  const { t } = useTranslation(); // for translations
  const [showFirstLessonInitital, setshowFirstLessonInitital] = useState(false);
  const initialShowFirstLessonHandler = () => {
    setshowFirstLessonInitital(true);
  };

  console.log(`courseDetails`, courseDetails);

  return (
    <div className="course-details py-5">
      <div className="container">
        {/* ----------- tab lists ------------ */}
        <ul
          className="nav nav-tabs gap-2 border-0 mb-3"
          id="courseTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <Link
              to="#"
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90 active"
              id="about-tab"
              data-bs-toggle="tab"
              data-bs-target="#about-tab-pane"
              type="button"
              role="tab"
              aria-controls="about-tab-pane"
              aria-selected="true"
            >
              {t('About')}
            </Link>
          </li>

          <li className="nav-item" role="presentation">
            <Link
              to="#"
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="team-tab"
              data-bs-toggle="tab"
              data-bs-target="#team-tab-pane"
              type="button"
              role="tab"
              aria-controls="team-tab-pane"
              aria-selected="false"
            >
              {t('Team')}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              to="#"
              onClick={initialShowFirstLessonHandler}
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="lessons-tab"
              data-bs-toggle="tab"
              data-bs-target="#lessons-tab-pane"
              type="button"
              role="tab"
              aria-controls="lessons-tab-pane"
              aria-selected="false"
            >
              {t('Lessons')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
              <Link
                className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                id="events-tab"
                data-bs-toggle="tab"
                data-bs-target="#events-tab-pane"
                type="button"
                role="tab"
                aria-controls="events-tab-pane"
                aria-selected="false"
              >
                Events
              </Link>
            </li> */}
          {/* <li className="nav-item" role="presentation">
              <Link
                className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                id="forum-tab"
                data-bs-toggle="tab"
                data-bs-target="#forum-tab-pane"
                type="button"
                role="tab"
                aria-controls="forum-tab-pane"
                aria-selected="false"
              >
                Forum
              </Link>
            </li> */}
          {/* <li className="nav-item" role="presentation">
              <Link
                className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
                id="documents-tab"
                data-bs-toggle="tab"
                data-bs-target="#documents-tab-pane"
                type="button"
                role="tab"
                aria-controls="documents-tab-pane"
                aria-selected="false"
              >
                Documents
              </Link>
            </li> */}
          <li className="nav-item" role="presentation">
            <Link
              to="#"
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="reviews-tab"
              data-bs-toggle="tab"
              data-bs-target="#reviews-tab-pane"
              type="button"
              role="tab"
              aria-controls="reviews-tab-pane"
              aria-selected="false"
            >
              {t('Reviews')}
            </Link>
          </li>
          {/* <li className="nav-item" role="presentation">
            <Link
              to="#"
              className="bg-white px-3 py-2 fw-medium text-black border border-gray-500 rounded-90"
              id="reviews-tab"
              data-bs-toggle="tab"
              data-bs-target="#reviews-tab-pane"
              type="button"
              role="tab"
              aria-controls="reviews-tab-pane"
              aria-selected="false"
            >
              {t('Scores')}
            </Link>
          </li> */}
        </ul>
        {/* ---------- tab content ------------- */}
        <div className="tab-content" id="courseTabContent">
          {/* ------------ about tab ------------- */}
          <AboutTab courseDetails={courseDetails} />

          {/* ------------ lessons tab ------------- */}
          <LessonsTab
            courseDetails={courseDetails}
            showFirstLessonInitital={showFirstLessonInitital}
            setshowFirstLessonInitital={setshowFirstLessonInitital}
          />

          {/* ------------ team tab ------------- */}
          <TeamTab courseDetails={courseDetails} />

          {/* ------------ events tab ------------- */}
          <EventsTab />

          {/* ------------ forum tab ------------- */}
          <ForumTab />

          {/* ------------ documents tab ------------- */}
          <DocumentTab />

          {/* ------------ review tab ------------- */}
          <ReviewTab
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsBottomComponent;
