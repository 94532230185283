/* eslint-disable */
import React, { useEffect, useState } from 'react';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData } from 'utils/Gateway';

import { Link } from 'react-router-dom';

import Select from 'react-select';

import sorting from 'Data/Sorting.json';

import { useTranslation } from 'react-i18next';
import EventListFilterPopup from '../Popup/EventListFilterPopup';
import { assetImages } from 'constants';

const EventsList = () => {
  const { t } = useTranslation(); // for translations\

  const memberLimit = 5; //for displaying the limit number of members

  const token = localStorage.getItem('token');
  // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const moduleAccess = localStorage.getItem('moduleaccess');

  // const placeHolderCount = [1, 2];

  const [eventTypeList, seteventTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [eventList, seteventList] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState(null); // for url params

  const [filterRating, setFilterRating] = useState('');
  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterEventTitle, setFilterEventTitle] = useState('');
  const [filterEventType, setFilterEventType] = useState([]);
  const [filterEventCategories, setfilterEventCategories] = useState([]);
  const [filterEventInterests, setfilterEventInterests] = useState([]);
  const [filterEventSearchings, setfilterEventSearchings] = useState([]);
  const [filterEventTags, setFilterEventTags] = useState([]);
  const [filterEventCity, setfilterEventCity] = useState('');
  const [filterEventCountry, setfilterEventCountry] = useState('');

  //function for get all event type
  const getEventTypes = async () => {
    try {
      let requestUrl = url.API_BASE_URL + url.API_GET_ALL_EVENT_TYPES;

      const response = await getData(requestUrl);

      if (response.status) {
        seteventTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all category
  const getAllCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=EVENT`;

      const response = await getData(requestUrl);

      if (response.status) {
        setcategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSearchingInterest = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills`;

      const response = await getData(requestUrl);

      if (response.status) {
        setsearchingList(response.data);
        setinterestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get eventlist function
  const getAllEvent = async (start, limit, sortingBy) => {
    try {
      // setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterEventTitle !== '') {
        requestUrl = requestUrl + `&filtertitle=${filterEventTitle}`;
      }
      if (filterEventCity !== '') {
        requestUrl = requestUrl + `&filtercity=${filterEventCity}`;
      }
      if (filterEventCountry !== '') {
        requestUrl = requestUrl + `&filtercountry=${filterEventCountry}`;
      }
      if (filterDateRange !== '') {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }
      if (filterRating !== '') {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }
      if (filterEventTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterEventTags}`;
      }
      if (filterEventType.length > 0) {
        requestUrl = requestUrl + `&filtertype=${filterEventType}`;
      }
      if (filterEventCategories.length > 0) {
        requestUrl = requestUrl + `&filtercategory=${filterEventCategories}`;
      }

      console.log(`filterEventInterests`, filterEventInterests);

      if (filterEventInterests.length > 0) {
        requestUrl = requestUrl + `&filterinterest=${filterEventInterests}`;
      }
      if (filterEventSearchings.length > 0) {
        requestUrl = requestUrl + `&filtersearchings=${filterEventSearchings}`;
      }

      console.log('request url for event list----->', requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      // setIsLoading(false);
      if (response.status) {
        seteventList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterRating('');
    setFilterDateRange('');
    setFilterEventTitle('');
    setfilterEventCity('');
    setfilterEventCountry('');
    setFilterEventTags([]);
    setFilterEventType([]);
    setfilterEventCategories([]);
    setfilterEventInterests([]);
    setfilterEventSearchings([]);
  };

  const resetList = () => {
    getAllEvent('0', '10', null);
  };

  //use effect for filter search
  useEffect(() => {
    if (
      filterDateRange !== '' ||
      filterEventTitle !== '' ||
      filterEventCity !== '' ||
      filterEventCountry !== '' ||
      filterRating !== '' ||
      filterEventType.length > 0 ||
      filterEventCategories.length > 0 ||
      filterEventInterests.length > 0 ||
      filterEventSearchings.length > 0 ||
      filterEventTags.length > 0
    ) {
      getAllEvent('0', '10', null);
    }
  }, [
    filterDateRange,
    filterEventTitle,
    filterEventType,
    filterRating,
    filterEventCategories,
    filterEventInterests,
    filterEventSearchings,
    filterEventTags,
    filterEventCity,
    filterEventCountry,
  ]);

  //use effect for params
  useEffect(() => {
    if (params?.value) {
      getAllEvent('0', '10', params?.value);
    }
  }, [params]);

  useEffect(() => {
    getEventTypes();
    getAllTags();
    getAllCategories();
    getAllSearchingInterest();
    getAllEvent('0', '10', null);
  }, []);
  return (
    <section className="events-wrapper header-spacing">
      <div className="search-container bg-white py-3 shadow-sm">
        <form action="">
          <div className="container">
            <div className="d-flex">
              <div className="pe-4 border-end border-gray-500">
                <select
                  name=""
                  id=""
                  className="form-select bg-transparent text-primary border-0"
                >
                  <option value="accounts" selected>
                    Accounts
                  </option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                  <option value="option5">Option 5</option>
                </select>
              </div>
              <div className="ps-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="text-light p-0 bg-transparent border-0"
                  >
                    <span className="d-block material-symbols-outlined">
                      search
                    </span>
                  </button>
                  <input
                    type="text"
                    className="form-control text-primary fw-normal bg-transparent border-0"
                    placeholder="Search Object"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="events-container py-5">
        <div className="container">
          <div className="filter-container d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between gap-3 mb-4">
            <p className="fs-sm fw-semibold text-primary">
              {t('Found')} <span>{eventList.length}</span> {t('result')}
            </p>
            <div className="d-flex gap-3">
              <Select
                placeholder={t('Select Position')}
                options={sorting}
                value={params}
                onChange={val => {
                  setParams(val);
                }}
              />
              <Link
                to="#offcanvasFilter"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasFilter"
                className="filter-btn d-flex align-items-center justify-content-center bg-white p-2 text-primary rounded-5 shadow-md"
              >
                <span className="d-block material-symbols-outlined">
                  filter_list
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            {eventList.map((eventData, index) => {
              return (
                <div className="event col-md-6 col-lg-4 mb-4" key={index}>
                  <div className="event-content p-3 bg-white rounded-10 overflow-hidden shadow-sm">
                    <h3>
                      <Link
                        to={`/events/details/${eventData._id}`}
                        className="text-black"
                      >
                        {eventData.title}
                      </Link>
                    </h3>
                    <p className="date d-flex gap-1 align-items-center fs-sm text-gray fw-semibold">
                      <span className="d-block material-symbols-outlined icon-sm">
                        event_note
                      </span>
                      <span className="d-block">
                        {eventData.eventstartdate}
                      </span>
                    </p>
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm mb-3">
                      <li className="tag">
                        <Link
                          to="#"
                          className="d-flex gap-1 align-items-center text-gray bg-gray-100 rounded-90"
                        >
                          <span className="d-block material-symbols-outlined icon-fill rating-star-gradient icon-sm">
                            star
                          </span>
                          <span className="d-block fs-sm fw-bold">
                            {eventData.ratingtotal}
                          </span>
                          <span className="d-block fs-xs">
                            ({eventData.reviewtotal})
                          </span>
                        </Link>
                      </li>
                      <li className="tag">
                        <Link
                          to="#"
                          className="d-flex align-items-center gap-1 text-gray bg-gray-100 rounded-90"
                        >
                          <span className="d-block material-symbols-outlined icon-md">
                            location_on
                          </span>
                          <span className="d-block">
                            {eventData.eventlocation}
                          </span>
                        </Link>
                      </li>
                    </ul>
                    <p className="info text-dark mb-4">{eventData.summary}</p>
                    <ul className="event-members d-flex align-items-center mb-3">
                      {eventData.eventmemberlist
                        .slice(0, memberLimit)
                        .map((member, index) => {
                          return (
                            <li
                              className="border-1 border-white rounded-circle overflow-hidden"
                              key={index}
                            >
                              <img
                                src={
                                  member.eventmemberimage === ''
                                    ? assetImages.defaultUser
                                    : url.SERVER_URL + member.eventmemberimage
                                }
                                alt=""
                                className="rounded-circle"
                                height={40}
                                width={40}
                              />
                            </li>
                          );
                        })}

                      {eventData.eventmembercount ? (
                        <li className="more ms-2">
                          {eventData.eventmembercount > memberLimit ? (
                            <Link
                              to="#"
                              className="fs-sm text-gray fw-semibold"
                            >
                              +{eventData.eventmembercount - memberLimit} more
                            </Link>
                          ) : null}
                        </li>
                      ) : null}
                    </ul>
                    <Link
                      to={`/events/details/${eventData._id}`}
                      className="btn btn-secondary w-100"
                    >
                      {t('View Details')}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <ol className="pagination align-items-center gap-2 fw-semibold justify-content-center mt-4">
            <li className="prev me-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_back
                </span>
              </Link>
            </li>
            <li className="page active">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                1
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                2
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                3
              </Link>
            </li>
            <li className="page">
              <span className="d-flex align-items-center justify-content-center rounded-circle">
                ...
              </span>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="page">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center rounded-circle"
              >
                26
              </Link>
            </li>
            <li className="next ms-3">
              <Link to="#" className="d-block text-black">
                <span className="d-block material-symbols-outlined">
                  arrow_forward
                </span>
              </Link>
            </li>
          </ol> */}
        </div>
      </div>
      {/* --------- filter popup -------- */}

      <EventListFilterPopup
        eventTypeList={eventTypeList}
        tagList={tagList}
        categoryList={categoryList}
        searchingList={searchingList}
        interestList={interestList}
        setfilterEventCategories={setfilterEventCategories}
        setfilterEventInterests={setfilterEventInterests}
        setfilterEventSearchings={setfilterEventSearchings}
        setfilterEventCity={setfilterEventCity}
        setfilterEventCountry={setfilterEventCountry}
        setFilterDateRange={setFilterDateRange}
        setFilterEventTags={setFilterEventTags}
        setFilterEventType={setFilterEventType}
        setFilterEventTitle={setFilterEventTitle}
        setFilterRating={setFilterRating}
        resetList={resetList}
      />
    </section>
  );
};

export default EventsList;
