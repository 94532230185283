import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as url from 'helper/UrlHelper';

const AboutTab = ({ courseDetails }) => {
  const { t } = useTranslation(); // for translations
  return (
    <div
      className="tab-pane fade show active"
      id="about-tab-pane"
      role="tabpanel"
      aria-labelledby="about-tab"
      tabIndex="0"
    >
      <div className="row">
        <div className="col-lg-8">
          {/* <div className="what-you-will-learn bg-white p-4 rounded-10 shadow-sm mb-3">
            <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
              You Will Learn
            </p>
            <ul>
              <li>
                Follow the design process: empathize with users, define pain
                points, ideate solutions, create wireframes and prototypes, test
                and iterate on designs
              </li>
              <li>
                Understand the basics of UX research, like planning research
                studies, conducting interviews and usability studies, and
                synthesizing research results
              </li>
              <li>
                Apply foundational UX concepts, like user-centered design,
                accessibility, and equity-focused design
              </li>
              <li>
                Create a professional UX portfolio that includes 3 end-to-end
                projects: a mobile app, a responsive website, and a
                cross-platform experience
              </li>
            </ul>
          </div> */}
          <div className="skill-you-gain bg-white p-4 rounded-10 shadow-sm mb-3">
            <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
              {t('Skills You Gain')}
            </p>
            {courseDetails.skillvalues && (
              <ul className="tags d-flex flex-wrap gap-2 fs-sm">
                {courseDetails?.skillvalues?.map((skill, index) => {
                  return (
                    <li className="tag" key={index}>
                      <Link
                        to="#"
                        className="d-block text-black border border-gray-500 rounded-90"
                      >
                        {skill.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="about-this-course bg-white p-4 rounded-10 shadow-sm3">
            <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
              {t('About This Course')}
            </p>
            <p
              dangerouslySetInnerHTML={{ __html: courseDetails?.description }}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <aside className="about-course-sidebar">
            {/* <div
              className="video-container position-relative ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-10 overflow-hidden shadow-sm mb-4"
              style={{
                backgroundImage: "url('/assets/img/video-placeholder.jpg')",
              }}
            >
              <Link
                to="#"
                className="play-video text-secondary position-absolute top-50 start-50 translate-middle"
              >
                <svg width="56" height="56" className="d-block">
                  <use
                    xlinkHref="/assets/svg/sprite.svg#playBtn"
                    href="/assets/svg/sprite.svg#playBtn"
                  ></use>
                </svg>
              </Link>
            </div> */}

            <img
              src={url.SERVER_URL + courseDetails.courselogopath}
              alt={courseDetails.courselogopath}
              className="img-fluid position-relative ratio ratio-16x9 bg-cover bg-norepeat bg-center rounded-10 overflow-hidden shadow-sm mb-4"
            />
            <div className="course-benefits bg-white px-3 py-4 rounded-10 shadow-sm">
              <ul className="benefits d-flex flex-column gap-3">
                <li className="benefit d-flex align-items-center gap-3">
                  <div className="icon body-bg rounded-circle text-light d-flex align-items-center justify-content-center">
                    <span className="b-block material-symbols-outlined">
                      fact_check
                    </span>
                  </div>
                  <div className="info fs-sm">
                    <p className="fw-semibold text-light mb-0">
                      Shareable Certificate
                    </p>
                    <p>Earn a Certificate upon completion</p>
                  </div>
                </li>
                <li className="benefit d-flex align-items-center gap-3">
                  <div className="icon body-bg rounded-circle text-light d-flex align-items-center justify-content-center">
                    <span className="b-block material-symbols-outlined">
                      language
                    </span>
                  </div>
                  <div className="info fs-sm">
                    <p className="fw-semibold text-light mb-0">
                      100% Online Courses
                    </p>
                    <p>Start instantly and learn at your own schedule.</p>
                  </div>
                </li>
                <li className="benefit d-flex align-items-center gap-3">
                  <div className="icon body-bg rounded-circle text-light d-flex align-items-center justify-content-center">
                    <span className="b-block material-symbols-outlined">
                      event_note
                    </span>
                  </div>
                  <div className="info fs-sm">
                    <p className="fw-semibold text-light mb-0">
                      Flexible Schedule
                    </p>
                    <p>Set and maintain flexible deadlines.</p>
                  </div>
                </li>
                <li className="benefit d-flex align-items-center gap-3">
                  <div className="icon body-bg rounded-circle text-light d-flex align-items-center justify-content-center">
                    <span className="b-block material-symbols-outlined">
                      school
                    </span>
                  </div>
                  <div className="info fs-sm">
                    <p className="fw-semibold text-light mb-0">
                      Beginner Level
                    </p>
                    <p>No degree or prior experience required</p>
                  </div>
                </li>
                <li className="benefit d-flex align-items-center gap-3">
                  <div className="icon body-bg rounded-circle text-light d-flex align-items-center justify-content-center">
                    <span className="b-block material-symbols-outlined">
                      history_edu
                    </span>
                  </div>
                  <div className="info fs-sm">
                    <p className="fw-semibold text-light mb-0">English</p>
                    <p>Subtitles: English</p>
                  </div>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default AboutTab;
