import React from 'react';
import { Link } from 'react-router-dom';
import { fileDownloadHandler } from '../../../../utils/DownloadFile';
import { useTranslation } from 'react-i18next';

const OverviewTab = ({
  companyDetails,
  companyServices,
  companySearchings,
  companyInterests,
  companyDocs,
}) => {
  const { t } = useTranslation(); // for translations
  return (
    <div
      className="tab-pane fade show active"
      id="about-tab-pane"
      role="tabpanel"
      aria-labelledby="about-tab"
      tabIndex="0"
    >
      <div className="description bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Description')}
        </p>
        <p dangerouslySetInnerHTML={{ __html: companyDetails }}></p>
      </div>
      <div className="faq bg-white p-4 rounded-10 shadow-sm mb-3">
        {/* <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          Frequently Asked Questions
        </p> */}
        <div className="accordion" id="accordionExample">
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                {t('Services')}
              </button>
            </div>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <ul className="tags d-flex flex-wrap gap-2">
                  {companyServices.map((service, index) => {
                    return (
                      <li className="tag" key={index}>
                        <Link
                          to="#"
                          className="d-block px-3 py-2 fs-sm fw-medium text-gray border border-1 border-gray-500 rounded-90"
                        >
                          {service.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                {t('Searchings')}
              </button>
            </div>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <ul className="tags d-flex flex-wrap gap-2">
                  {companySearchings.map((searching, index) => {
                    return (
                      <li className="tag" key={index}>
                        <Link
                          to="#"
                          className="d-block px-3 py-2 fs-sm fw-medium text-gray border border-1 border-gray-500 rounded-90"
                        >
                          {searching.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-0">
            <div className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 fs-sm text-dark fw-bold text-uppercase shadow-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {t('Interest')}
              </button>
            </div>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0 pt-3">
                <ul className="tags d-flex flex-wrap gap-2">
                  {companyInterests.map((interest, index) => {
                    return (
                      <li className="tag" key={index}>
                        <Link
                          to="#"
                          className="d-block px-3 py-2 fs-sm fw-medium text-gray border border-1 border-gray-500 rounded-90"
                        >
                          {interest.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="docs bg-white p-4 rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Docs')}
        </p>
        <ul className="documents d-flex flex-wrap gap-3">
          {companyDocs.map((file, index) => {
            return (
              <li className="document d-flex flex-column justify-content-center fs-sm text-center p-3 border border-gray rounded-8">
                <Link
                  className="file-icon mx-auto mb-3"
                  onClick={() => fileDownloadHandler(file)}
                >
                  <img
                    src="/assets/img/pdf-icon.png"
                    alt="EventReg.pdf"
                    className="img-fluid"
                  />
                </Link>
                <p className="mb-1">{file.name}</p>
                {/* <p className="size fs-xs text-gray">1.5mb</p> */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OverviewTab;
