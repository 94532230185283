import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import carousel styles
import { useTranslation } from 'react-i18next';
import kitDigitalServiceData from 'Data/KitDigitalServiceData.json';

// const services = [
//   {
//     id: 1,
//     title: 'Software factory',
//     description:
//       'Get your own website and modify it whenever necessary, without worrying about the domain, performance or backup copies.',
//     price: '€1,900',
//     icon: 'language',
//     features: [
//       'Website always updated.',
//       'Search engine on your website.',
//       'Improvement of web positioning (SEO).',
//       'Hosting, domain and backup copies included.',
//       'Automatic programming of contents.',
//     ],
//   },
//   {
//     id: 2,
//     title: 'Mentoring',
//     description:
//       'We create your online store with the possibility of integrating',
//     price: '€4,500',
//     icon: 'store',
//     features: [
//       'Website always updated.',
//       'Search engine on your website.',
//       'Improvement of web positioning (SEO).',
//       'Hosting, domain and backup copies included.',
//       'Automatic programming of contents.',
//     ],
//   },
//   {
//     id: 3,
//     title: 'Advisors',
//     description:
//       'Get your own website and modify it whenever necessary, without worrying about the domain, performance or backup copies.',
//     price: '€3,900',
//     icon: 'query_stats',
//     features: [
//       'Website always updated.',
//       'Search engine on your website.',
//       'Improvement of web positioning (SEO).',
//       'Hosting, domain and backup copies included.',
//       'Automatic programming of contents.',
//     ],
//   },
//   {
//     id: 4,
//     title: 'Software factory',
//     description:
//       'Get your own website and modify it whenever necessary, without worrying about the domain, performance or backup copies.',
//     price: '€1,900',
//     icon: 'language',
//     features: [
//       'Website always updated.',
//       'Search engine on your website.',
//       'Improvement of web positioning (SEO).',
//       'Hosting, domain and backup copies included.',
//       'Automatic programming of contents.',
//     ],
//   },
// ];

const HomeServices = () => {
  const { t } = useTranslation(); // for translations

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="best-services bg-light bg-opacity-2 py-50 py-md-70">
      <div className="container">
        <div className="sec-title text-primary mb-5">
          <div className="row align-items-end">
            <div className="col-auto">
              <h2>{t('Best Services')}</h2>
              <p>
                {t(
                  'Level of knowledge that a person possesses about a particular subject.'
                )}
              </p>
            </div>
            <div className="col-auto ms-auto">
              <Link to="#" className="btn btn-secondary">
                {t('View All')}
              </Link>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {kitDigitalServiceData.map((service, index) => (
            <div key={index} className="service px-2">
              <div className="service-content p-4 bg-white rounded-10 ">
                {/* <div className="icon d-flex align-items-center justify-content-center bg-light bg-opacity-2 text-primary rounded-circle mb-3">
                  <span className="d-block material-symbols-outlined">
                    {service.icon}
                  </span>
                </div> */}
                <h3 className="mb-3">
                  <Link to="#" className="text-black">
                    {service.kitname}
                  </Link>
                </h3>
                <p>{service.kitdetails}</p>
                <ul className="check-circle mt-3">
                  {service.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div className="action d-flex flex-wrap gap-3 align-items-end justify-content-between mt-5">
                  <div className="price">
                    <p className="fs-lg fw-semibold">From</p>
                    <h3 className="mb-0">{service.price}</h3>
                  </div>
                  <Link to="#" className="btn btn-secondary">
                    {t('Extensive Information')}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeServices;
