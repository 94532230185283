/* eslint-disable */
import React from 'react';

import CommonHeader from 'Components/Common/Headers/CommonHeader';
import Footer from 'Components/Common/Footer/Footer';
import ChallengesDetailsBodyComponent from 'Components/ChallengesComponents/ChallengesDetailsComponents/ChallengesDetailsBodyComponent/ChallengesDetailsBodyComponent';

const ChallengesDetails = () => {
  return (
    <main id="app">
      {/* ----- common header ------------- */}
      <CommonHeader moduleName="challenges" />

      <div id="main_content" className="position-relative">
        <ChallengesDetailsBodyComponent />
      </div>

      {/* ----------- footer --------- */}
      <Footer />
    </main>
  );
};

export default ChallengesDetails;
