import React from 'react';
import { Link } from 'react-router-dom';

const GalleryTab = ({ galleryImages }) => {
  return (
    <div
      className="tab-pane fade"
      id="gallery-tab-pane"
      role="tabpanel"
      aria-labelledby="gallery-tab"
      tabIndex="0"
    >
      <div className="portfolio container">
        <div className="row">
          {galleryImages?.map((image, index) => {
            return (
              <div className="col-md-6 col-lg-4 mb-4" key={index}>
                <div className="img-container rounded-5 overflow-hidden">
                  <Link to="#">
                    <img
                      src={image.path}
                      alt="Portfolio 1"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GalleryTab;
