import React from 'react';
import { Link } from 'react-router-dom';

const SurveyTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="survey-tab-pane"
      role="tabpanel"
      aria-labelledby="survey-tab"
      tabIndex="0"
    >
      <div className="survey-container">
        <div className="survey bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium mb-0">CRM Expertise</p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-sm text-gray bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-sm">
                schedule
              </span>
              <span className="d-block">2min 30sec</span>
            </p>
          </div>
          <p className="mb-3">
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
          <Link
            to="#"
            className="btn btn-outline-success btn-sm d-inline-flex gap-2 align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-sm icon-fill">
              check_circle
            </span>
            <span className="d-block">Completed</span>
          </Link>
        </div>
        <div className="survey bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium mb-0">CRM Expertise</p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-sm text-gray bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-sm">
                schedule
              </span>
              <span className="d-block">2min 30sec</span>
            </p>
          </div>
          <p className="mb-3">
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
          <Link
            to="#"
            className="btn btn-outline-secondary btn-sm d-inline-flex gap-2 align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-sm icon-fill">
              add
            </span>
            <span className="d-block">Start Now</span>
          </Link>
        </div>
        <div className="survey bg-white p-4 rounded-10 shadow-sm mb-3">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium mb-0">CRM Expertise</p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-sm text-gray bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-sm">
                schedule
              </span>
              <span className="d-block">2min 30sec</span>
            </p>
          </div>
          <p className="mb-3">
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
          <Link
            to="#"
            className="btn btn-outline-secondary btn-sm d-inline-flex gap-2 align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-sm icon-fill">
              add
            </span>
            <span className="d-block">Start Now</span>
          </Link>
        </div>
        <div className="survey bg-white p-4 rounded-10 shadow-sm">
          <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
            <p className="title fw-medium mb-0">CRM Expertise</p>
            <p className="d-inline-flex gap-1 align-items-center px-2 py-1 fs-sm text-gray bg-gray-100 rounded-90">
              <span className="d-block material-symbols-outlined icon-sm">
                schedule
              </span>
              <span className="d-block">2min 30sec</span>
            </p>
          </div>
          <p className="mb-3">
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
          <Link
            to="#"
            className="btn btn-outline-secondary btn-sm d-inline-flex gap-2 align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-sm icon-fill">
              add
            </span>
            <span className="d-block">Start Now</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SurveyTab;
