import React from 'react';

const DocumentTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="documents-tab-pane"
      role="tabpanel"
      aria-labelledby="documents-tab"
      tabIndex="0"
    >
      <div className="document-container p-4 bg-white rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-3">Docs</p>
        <ul className="documents d-flex flex-wrap gap-3">
          <li className="document d-flex flex-column justify-content-center fs-sm text-center p-3 border border-gray rounded-8">
            <div className="file-icon mx-auto mb-3">
              <img
                src="assets/img/pdf-icon.png"
                alt="EventReg.pdf"
                className="img-fluid"
              />
            </div>
            <p className="mb-1">EventReg.pdf</p>
            <p className="size fs-xs text-gray">1.5mb</p>
          </li>
          <li className="document d-flex flex-column justify-content-center fs-sm text-center p-3 border border-gray rounded-8">
            <div className="file-icon mx-auto mb-3">
              <img
                src="assets/img/pdf-icon.png"
                alt="Managing.pdf"
                className="img-fluid"
              />
            </div>
            <p className="mb-1">Managing.pdf</p>
            <p className="size fs-xs text-gray">1.5mb</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DocumentTab;
