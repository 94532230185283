import React from 'react';

const InvestmentsTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="investments-tab-pane"
      role="tabpanel"
      aria-labelledby="investments-tab"
      tabIndex="0"
    >
      <div className="bg-white p-4 rounded-10 shadow-sm mb-3">
        <h3 className="mb-4">Description</h3>
        <div className="table-responsive">
          <table className="table fs-xs fw-medium mb-0">
            <tbody>
              <tr>
                <td className="w-50 py-3">Price of 1%</td>
                <td className="w-50 py-3">$6000.00</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Start Date</td>
                <td className="w-50 py-3">09-03-2021</td>
              </tr>
              <tr>
                <td className="w-50 py-3">End Date</td>
                <td className="w-50 py-3">07-12-2022</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Max Investment of this Round</td>
                <td className="w-50 py-3">$1,00,000.00</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Max Investment for Investor</td>
                <td className="w-50 py-3">$10,000.00</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Minimum Investment</td>
                <td className="w-50 py-3">$1,000.00</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Fund Raised</td>
                <td className="w-50 py-3">$2,000.00</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Investment Available</td>
                <td className="w-50 py-3">$98,000.00</td>
              </tr>
              <tr>
                <td className="w-50 py-3">Comments</td>
                <td className="w-50 py-3">
                  This offer is structured into two primary segments: solutions
                  and services (including outsourcing and application
                  maintenance), and business processes where technology is a
                  strategic and differentiating element (BPO). Approximately a
                  third of the company's annual revenues come from international
                  markets
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="bg-white p-4 rounded-10 shadow-sm">
        <div className="row">
          <div className="col-md-8">
            <p>
              This offer is structured into two primary segments: solutions and
              services (including outsourcing and application maintenance), and
              business processes where technology is a strategic and
              differentiating element (BPO). Approximately a third of the
              company's annual revenues come from international markets
            </p>
          </div>
          <div className="col-md-4">
            <form action="">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control fw-normal py-2 rounded-10"
                  placeholder="Pogo"
                />
              </div>
              <div className="action">
                <button type="submit" className="btn btn-secondary btn-sm">
                  Investment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentsTab;
