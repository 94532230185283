import React from 'react';

const KPIsTab = () => {
  return (
    <div
      className="tab-pane fade"
      id="kpis-tab-pane"
      role="tabpanel"
      aria-labelledby="kpis-tab"
      tabIndex="0"
    >
      <div className="kpis-container rounded-5 overflow-hidden">
        <div className="table-responsive">
          <table className="table align-middle fs-xs fw-medium mb-0">
            <thead>
              <tr>
                <th className="bg-white align-middle py-3">ÁMBITO FUNCIONAL</th>
                <th className="bg-white align-middle py-3">Indicador</th>
                <th className="bg-white align-middle py-3">Cierres 2019</th>
                <th className="bg-white align-middle py-3">Cierres 2020</th>
                <th className="bg-white align-middle py-3">Meta 2021</th>
                <th className="bg-white align-middle py-3">Resultado 2021</th>
                <th className="bg-white align-middle py-3">Cum - plimiento</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-3 align-top" rowspan="3">
                  Seguridad
                </td>
                <td className="py-3">
                  Nivel Ponderado de Seguridad (NPS Ground)
                </td>
                <td className="py-3">17,57</td>
                <td className="py-3">10,86</td>
                <td className="py-3"> ≤ 13,50</td>
                <td className="py-3">12,16</td>
                <td className="py-2">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3">Eficiencia Gestión de Seguridad (EoSM)</td>
                <td className="py-3">C</td>
                <td className="py-3">C</td>
                <td className="py-3">C</td>
                <td className="py-3">C</td>
                <td className="py-3">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3">
                  Índice de Madurez de Ciberseguridad (IMC)
                </td>
                <td className="py-3">84,70%</td>
                <td className="py-3">87,10%</td>
                <td className="py-3">≥ 87,0%</td>
                <td className="py-3">87,6%</td>
                <td className="py-3">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 align-top" rowspan="3">
                  Calidad, escalabilidad y resiliencia del servicio
                </td>
                <td className="py-3">Nivel de Calidad Percibid</td>
                <td className="py-3">70,5</td>
                <td className="py-3">72,7</td>
                <td className="py-3">≥ 72,0</td>
                <td className="py-3">75,4</td>
                <td className="py-2">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3">Demora de ruta (min/vuelo)</td>
                <td className="py-3">0,47</td>
                <td className="py-3">0,4</td>
                <td className="py-3">≤ 0,12</td>
                <td className="py-3">0,09</td>
                <td className="py-3">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3">Demora de llegada (min/llegada)*</td>
                <td className="py-3">1,02</td>
                <td className="py-3">0,3</td>
                <td className="py-3">≤ 0,44</td>
                <td className="py-3">0,19</td>
                <td className="py-3">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-2">Sostenibilidad</td>
                <td className="py-2">Ineficiencia red de rutas (KEA)**</td>
                <td className="py-2">3,67%</td>
                <td className="py-2">3,11%</td>
                <td className="py-2">≤ 3,51%</td>
                <td className="py-2">3,30%</td>
                <td className="py-2">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 align-top border-bottom-0" rowspan="3">
                  Eficiencia y Competitividad
                </td>
                <td className="py-3">Costes Unitarios Ruta Global (€2017)</td>
                <td className="py-3">43,5</td>
                <td className="py-3">108</td>
                <td className="py-3">≤ 86,6</td>
                <td className="py-3">75,6</td>
                <td className="py-2">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3">Solvencia financiera</td>
                <td className="py-3">1,5</td>
                <td className="py-3">1,5</td>
                <td className="py-3">≥ 1,40</td>
                <td className="py-3">1,44</td>
                <td className="py-3">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3">Madurez Digital</td>
                <td className="py-3">41,3</td>
                <td className="py-3">.</td>
                <td className="py-3">≥ 45,0</td>
                <td className="py-3">64,7</td>
                <td className="py-3">
                  <span className="d-inline-block material-symbols-outlined icon-md text-success">
                    done
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KPIsTab;
