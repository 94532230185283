import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const OverviewTab = ({
  expertDetails,
  expertSkills,
  expertEmail,
  expertLanguage,
  expertEducations,
  expertExperiences,
}) => {
  const { t } = useTranslation(); // for translations
  return (
    <div
      className="tab-pane fade show active"
      id="overview-tab-pane"
      role="tabpanel"
      aria-labelledby="overview-tab"
      tabIndex="0"
    >
      <div className="details bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Details')}
        </p>
        <p dangerouslySetInnerHTML={{ __html: expertDetails }} />
      </div>

      {/* ------------- education section ------------ */}
      <div className="experience-container bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Education')}
        </p>
        <ul className="experiences">
          {expertEducations.map((education, index) => {
            return (
              <li className="experience position-relative pb-4" key={index}>
                <div className="d-flex gap-3">
                  <div className="icon bg-white p-2 border border-gray-500 rounded-8">
                    <img
                      src="/assets/img/airbnb.png"
                      alt="Airbnb"
                      className="img-fluid"
                    />
                  </div>
                  <div className="desc">
                    <p className="d-flex align-items-center gap-1 mb-2">
                      <span className="fw-semibold">{education.degree}</span>
                      <span className="fs-sm text-gray">
                        {t('at')} {education.institute}.
                      </span>
                    </p>
                    <p className="date fs-sm text-gray mb-1">
                      {education.year}
                    </p>
                    {/* <span className="d-inline-block px-2 py-1 bg-gray-100 fs-sm text-gray rounded-90">
                      San Franchisco, CA
                    </span> */}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="experience-container bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Experience')}
        </p>
        <ul className="experiences">
          {expertExperiences.map((experience, index) => {
            return (
              <li className="experience position-relative pb-4" key={index}>
                <div className="d-flex gap-3">
                  <div className="icon bg-white p-2 border border-gray-500 rounded-8">
                    <img
                      src="/assets/img/airbnb.png"
                      alt="Airbnb"
                      className="img-fluid"
                    />
                  </div>
                  <div className="desc">
                    <p className="d-flex align-items-center gap-1 mb-2">
                      <span className="fw-semibold">{experience.position}</span>
                      <span className="fs-sm text-gray">
                        {experience.companyid
                          ? experience.companyid.label
                          : experience.company !== ''
                          ? experience.company
                          : ''}
                        .
                      </span>
                    </p>
                    <p className="date fs-sm text-gray mb-1">
                      {experience.start} to {experience.end}
                    </p>
                    {/* <span className="d-inline-block px-2 py-1 bg-gray-100 fs-sm text-gray rounded-90">
                      San Franchisco, CA
                    </span> */}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {/* <div className="recommended bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          Recommended
        </p>
        <div className="person d-flex flex-wrap flex-sm-nowrap gap-3 mb-4">
          <div className="avatar rounded-circle overflow-hidden">
            <Link to="#">
              <img
                src="assets/img/roma-keller.jpg"
                alt="Roma Keller"
                className="w-100 h-100 object-cover object-center"
              />
            </Link>
          </div>
          <div className="info">
            <div className="d-flex gap-3 align-items-end justify-content-between mb-3">
              <div className="title">
                <p className="name fw-medium mb-0">
                  <Link to="#" className="text-black">
                    Roma Keller
                  </Link>
                </p>
                <p className="designation fs-sm text-gray">Sales Manager</p>
              </div>
              <p className="date fs-sm text-gray">2 months ago</p>
            </div>
            <p>
              I first met Pierre in 1988. On my first day at Dell Computer he
              jumped on the elevator with me to say he was excited I joined the
              company. Over the next 6 years he occasionally popped into my
              office to politely suggest what he thought my priorities should be
              for the business.{' '}
            </p>
          </div>
        </div>
        <div className="person d-flex flex-wrap flex-sm-nowrap gap-3 mb-4">
          <div className="avatar rounded-circle overflow-hidden">
            <Link to="#">
              <img
                src="assets/img/gordon-ramsay.jpg"
                alt="Gordon Ramsay"
                className="w-100 h-100 object-cover object-center"
              />
            </Link>
          </div>
          <div className="info">
            <div className="d-flex gap-3 align-items-end justify-content-between mb-3">
              <div className="title">
                <p className="name fw-medium mb-0">
                  <Link to="#" className="text-black">
                    Gordon Ramsay
                  </Link>
                </p>
                <p className="designation fs-sm text-gray">Sales Manager</p>
              </div>
              <p className="date fs-sm text-gray">2 months ago</p>
            </div>
            <p>
              Over the next 6 years he occasionally popped into my office to
              politely suggest what he thought my priorities should be for the
              business.
            </p>
          </div>
        </div>
      </div> */}
      <div className="skills bg-white p-4 rounded-10 shadow-sm mb-3">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Skills')}
        </p>
        <ul className="tags d-flex flex-wrap gap-2 fs-sm">
          {expertSkills.map((skill, index) => {
            return (
              <li className="tag" key={index}>
                <Link
                  to="#"
                  className="d-block text-black border border-gray-500 rounded-90"
                >
                  {skill.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="basic-details bg-white p-4 rounded-10 shadow-sm">
        <p className="fs-sm fw-bold text-uppercase text-gray mb-4">
          {t('Additional Information')}
        </p>
        <div className="row">
          <div className="col-lg-6">
            <div className="email mb-3">
              <label className="d-flex gap-1 align-items-center fs-sm text-gray mb-1">
                <span className="d-block material-symbols-outlined icon-sm">
                  mail
                </span>
                <span>{t('Email')}</span>
              </label>
              <p className="fw-medium">
                <Link
                  href="mailto:Pierre.gagnaire@examaile.com"
                  className="text-light"
                >
                  {expertEmail}
                </Link>
              </p>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="dob mb-3">
              <label className="d-flex gap-1 align-items-center fs-sm text-gray mb-1">
                <span className="d-block material-symbols-outlined icon-sm">
                  event_note
                </span>
                <span>15th January, 1986</span>
              </label>
              <p className="fw-medium text-black">English, Polish, Spanish</p>
            </div>
          </div> */}
          <div className="col-lg-6">
            <div className="language mb-3">
              <label className="d-flex gap-1 align-items-center fs-sm text-gray mb-1">
                <span className="d-block material-symbols-outlined icon-sm">
                  language
                </span>
                <span>{t('Language')}</span>
              </label>
              <p className="fw-medium text-black">{expertLanguage}</p>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="education mb-3">
              <label className="d-flex gap-1 align-items-center fs-sm text-gray mb-1">
                <span className="d-block material-symbols-outlined icon-sm">
                  school
                </span>
                <span>Education</span>
              </label>
              <p className="fw-medium text-black">
                Bsc from Stanford University
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
