import React from 'react';
import { Link } from 'react-router-dom';
import * as url from 'helper/UrlHelper';
import { useTranslation } from 'react-i18next';
import { assetImages } from 'constants';

const ReviewTab = ({
  ratingCount,
  reviewsList,
  ratingPercentArr,
  moduleName,
}) => {
  const { t } = useTranslation(); // for translations

  const starCount = [1, 2, 3, 4, 5];
  const floatValue = parseFloat(ratingCount); // Parse the string as a floating-point number
  const intValue = Math.ceil(floatValue); // Round up to the nearest integer
  return (
    <div
      className="tab-pane fade"
      id="reviews-tab-pane"
      role="tabpanel"
      aria-labelledby="reviews-tab"
      tabIndex="0"
    >
      <div className="review-container bg-white p-3 rounded-10 shadow-sm">
        <div className="review-head p-4 body-bg rounded-8 mb-4">
          <div className="row">
            <div className="col-lg-4">
              <div className="review-count d-flex align-items-center gap-2 mb-2">
                <h3 className="text-dark mb-0"> {ratingCount}</h3>
                <p className="fs-xs fw-medium text-gray">
                  ({reviewsList.length})
                </p>
              </div>
              <ul className="starts d-flex mb-4">
                {starCount.map((_, index) => {
                  return (
                    <li key={index}>
                      <span
                        className={`d-block material-symbols-outlined icon-fill ${
                          index < intValue
                            ? 'rating-star-gradient'
                            : 'text-white'
                        } `}
                      >
                        star
                      </span>
                    </li>
                  );
                })}
              </ul>
              {moduleName !== 'accounts' && (
                <Link
                  to="#"
                  className="btn btn-outline-light btn-sm rounded-90"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback_mdl"
                >
                  {t('Add Review')}
                </Link>
              )}
            </div>
            <div className="col-lg-8">
              <ul className="review-bars fs-sm">
                {ratingPercentArr.map((ratingBar, index) => {
                  return (
                    <li
                      className="d-flex align-items-center gap-3 mb-2"
                      key={index}
                    >
                      <span className="d-block text-black">
                        {ratingBar.ratingname} Star
                      </span>
                      <div
                        className="progress bg-white"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuenow="63"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ height: '8px' }}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: `${ratingBar.ratingpercent}%` }}
                        ></div>
                      </div>
                      <span className="d-block text-primary">
                        {ratingBar.ratingpercent}%
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="reviews">
          {reviewsList.map((reviewData, index) => {
            return (
              <div className="review d-flex gap-3 mb-4" key={index}>
                <div className="avatar rounded-circle overflow-hidden">
                  <img
                    src={
                      reviewData.userimage === ''
                        ? assetImages.defaultUser
                        : url.SERVER_URL + reviewData.userimage
                    }
                    alt="Gordon Ramsay"
                    className="w-100 h-100 object-cover object-center"
                  />
                </div>
                <div className="review-content">
                  <div className="d-flex align-items-start justify-content-between gap-2 flex-wrap mb-3">
                    <div className="name">
                      <p className="name fw-semibold text-primary mb-0">
                        {reviewData.username}
                      </p>
                      <p className="date fs-sm text-gray">
                        {' '}
                        {reviewData.reviewdate} {reviewData.reviewtime}
                      </p>
                      <ul className="stars d-flex mt-1">
                        {starCount.map((_, index) => {
                          return (
                            <li key={index}>
                              <span
                                className={`d-block material-symbols-outlined icon-fill ${
                                  index < parseInt(reviewData.rating)
                                    ? 'rating-star-gradient'
                                    : 'text-gray'
                                } `}
                              >
                                star
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <Link
                      to="#"
                      className="share d-flex align-items-center justify-content-center body-bg rounded-circle"
                    >
                      <span className="d-block material-symbols-outlined icon-sm">
                        share
                      </span>
                    </Link> */}
                  </div>
                  <p className="mb-3">{reviewData.comment}</p>
                  {/* <Link
                    to="#"
                    className="like-btn btn btn-sm body-bg text-primary d-inline-flex align-items-center gap-2 rounded-90"
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      thumb_up
                    </span>
                    <span className="d-block">Likes</span>
                    <span className="count d-block bg-secondary text-white rounded-circle">
                      2
                    </span>
                  </Link> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReviewTab;
